// 
// Tables
//
// <figure>
//   <table class="table table-striped table-condensed"><tbody>
//   <tr>
//     <td>&nbsp;</td>
//     <td colspan="4" class="text-center"><strong>In the past 12 months, have you set up online notifications or alerts with your electricity or natural gas service provider?</strong></td>
//   </tr>
//   <tr>
//     <td><strong>PRIZM Premier Social group</strong></td>
//       <td class="text-center"><strong>No (n = 23,852)</strong></td>
//       <td class="text-center"><strong>Yes, via a computer, mobile phone, or tablet (n = 8,970)</strong></td>
//       <td class="text-center"><strong>Overall (n = 32,822) </strong></td>
//       <td class="text-center"><strong>Index score </strong></td>
//     </tr>
//     <tr>
//       <td>Y1 Midlife Success</td>
//       <td class="text-center">8%</td>
//       <td class="text-center">17%</td>
//       <td class="text-center">10%</td>
//       <td class="text-center">170</td>
//     </tr>
//     <tr>
//       <td>F4 Sustaining Families</td>
//       <td class="text-center">7%</td>
//       <td class="text-center">11%</td>
//       <td class="text-center">8%</td>
//       <td class="text-center">133</td>
//     </tr>
//   </tbody>
// </table>
// </figure>
//
// Style guide: Tables.table

figure {
  table {
    padding: 1em 0;
    margin: 0 auto;
    text-align: left;
  }
  thead > tr > th, th{
    color: white;
    vertical-align: bottom;
    background-color: #79ac42;
    font-weight: 500;
  }
  .table > tbody > tr > td{
    vertical-align: middle;
  }
  .table.table-align-top > tbody > tr > td{
    vertical-align: top;
  }
  .table.table-align-mid > tbody > tr > td{
    vertical-align: middle;
  }
  .table.table-align-bottom > tbody > tr > td{
    vertical-align: bottom;
  }
  .table.table-text-sm tbody td {
    font-size: .8em;
  }
  .table-vertical-borders > tbody > tr > td {
    border-right: solid 1px #ccc;
    border-left: solid 1px #ccc;
    &:first-child{
      border-left: none;
    }
    &:last-child{
      border-right: none;
    }
  }

  .table-hover > tbody > tr:hover, .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #deecce;
  }
  .table-hover > tbody > tr:hover, .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fff;
  }
  thead ~ tbody > tr:nth-of-type(odd) {
    background-color: white !important;
  }
  thead ~ tbody > tr:nth-of-type(even){
    background-color: #deecce;
  }
  tfoot {
    font-size: 12px;
    background-color: rgb(241, 241, 241);
  }
  tfoot > tr > td:first-child{
    text-align: left;
    width: 155px;
    word-wrap: break-word;
  }
  tfoot > tr > td:nth-child(2){
    text-align: right;
    width: 155px;
    word-wrap: break-word;
  }
  // TABLE SPANNER
  .table > .table-spanner > tr:first-child > th{
    background-color: #f1f1f1;
    color: black;
    font-weight: 400;
    text-align: center;
  }
  .table-spanner .table-span div{
    border-bottom: 1px solid black;
    width: 75%;
    text-align: center;
    margin: 0 auto;
    padding: 1em 0 .5em 0;
  }
  .table-spanner:first-child .table-span div{
    border-bottom: 1px solid black;
    width: 75%;
    text-align: center;
    margin: 0 auto;
    padding: 0;
  }
  .table-spanner > tr > td{
    text-align: center;
  }
  .table-spanner > tr > th {
    border-bottom: none;
  }
  .table > thead > tr > td{
    border-top: none;
    vertical-align: bottom;
  }
  .multi-row-white-stripe{
    background-color: white;
  }
  .multi-row-green-stripe{
    background-color: #deecce;
  }
  .table-spanner > tr > .multi-subspan-header-left{
    text-align: left;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  iframe {
    margin: 1em auto;

    @media(max-width: 600px) {
      width: 100%;
    }
  }
  .table-container-outer {
   position: relative;
  }
  .table-container {
     width: 100%;
     overflow-y: auto;
  }
}

// Compare Table Styles
.compare-table {
  height: 320px;
	width: 100%;
	max-width: 768px;
  margin: auto;
  padding: 5px;
	border-radius: 2px;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
	th,td {
		text-align: left;
		// transition: all .4s ease-out;
		// -webkit-transition: all .4s ease-out;
		// -moz-transition: all .4s ease-out;
		// -o-transition: all .4s ease-out;
		// -ms-transition: all .4s ease-out;
	}
	th {
		color: #fff;
		background-color: #545f64;
		border-bottom: 4px solid $es-green;
		border-right: 1px solid #c6c6c5;
		font-size: 23px;
		font-weight: lighter;
		padding: 24px;
		vertical-align: middle;
	}
	th:first-child {
  	border-top-left-radius: 3px;
	}
	th:last-child {
		border-top-right-radius: 3px;
		border-right: none;
	}
	tr > td:first-child{
		background-color: #ffffff;
    padding: 20px;
		width: 55%;
	}
	tr > td:nth-child(2){
		background-color: #fff;
		width: 45%;
	}
	tr {
		border-top: 1px solid #c6c6c5;
		border-bottom: 1px solid #c6c6c5;
		color: #545f64;
  	font-size: 16px;
		a {
			color: $es-green;
      display: table;
      height: 100%;
      width: 100%;

      p{
        display: table-cell;
        margin: 20px 0;
        padding: 0 20px;
        vertical-align: middle;
      }
		}
	}
  tr {
  	td:hover:nth-child(2) {
      background-color: $es-green;
      background-image: url('/sites/all/themes/es_bootstrap/img/share.png');
      background-position: 95% 85%;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      border-bottom: hidden;
      color: #fff;
  		position: relative;
  		z-index: 100;

  		// &:after{
      //   bottom: 5px;
  		// 	content: "\f08e";
      //   color: #fff;
      //   font-family: FontAwesome;
  		// 	font-size: 14px;
      //   font-weight: 200;
      //   position: absolute;
  		// 	right: 5px;
  		// }
      a:hover{
        color: #fff;
        text-decoration: none;
      }
  	}
  }
	a{
		color: #fff;
	}
	tr:first-child {
  	border-top: none;
	}
	tr:last-child {
		border-bottom: none;
	}
	tr:last-child td:first-child {
		border-bottom-left-radius: 3px;
	}
	tr:last-child td:last-child {
		border-bottom-right-radius: 3px;
	}
	td {
		background: #fff;
    border-right: 1px solid #c6c6c5;
    font-size: 18px;
		text-align: left;
		vertical-align: middle;
	}
	td:last-child {
  	border-right: 0px;
	}
}
