/*
* Custom Overrides
*/
@import url(https://fonts.googleapis.com/css?family=Domine);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400);


*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  height: 100%;
  box-sizing: border-box;
}
html,
body {
  margin:0;
  padding:0;
  height:100%;
}

body {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;
  margin: 0;
  min-height: 100%;
}

/* Headings */

// Heading 1
//
// Markup:
//  <h1>This is a heading one</h1>
//
// Style guide: Headings.heading_1
h1 {
  color: $es-dk-gray;
  margin: 1em 0;
}
.page-blog,
.node-type-blog,
.node-type-deliverable,
.node-type-vault,
.page-questions,
.node-type-file,
.node-type-event
{
  #page-header {
    padding-left: 0;

    h1 {
      margin-bottom: 0;
      padding: 0;
    }
  }
}
.node-type-blog,
.node-type-deliverable,
.node-type-event {
  #page-header {
    margin-bottom: 1em;
    border-bottom: 1px solid $es-off-white;
    padding-bottom: 1em;
  }
}
// Heading 2
//
// Markup:
//  <h2>This is a heading two</h2>
//
// Style guide: Headings.heading_2
h2 {
  margin-top: 1.3em;
}
// Heading 3
//
// Markup:
//  <h3>This is a heading three</h3>
//
// Style guide: Headings.heading_3
h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
  font-weight: 600;
}
.label {
  border-radius: 0;
}
h3 {
  a{
    font-family: $font-family-sans-serif;
  }
}
// Paragraph
//
// Markup:
//  <p>This is a paragraph</p>
//
// Style guide: Paragraph.ptag
p {
  margin: 0 0 1em 0;
}
.main-wrapper {
  //min-height:100%;
  position:relative;
}
.wrapper {
  padding-top: 80px;
}
.container{
  //width: calc( 100% - 90px);
}
//search bar

.region-search-bar {
  @extend .row;
}
.lead{
  font-size: 1.3em;
  line-height: 1.5em;
}

.display-print-only{
  display: none;
}
@media print{
  .display-print-only{
    display: inline;
  }
}

h2.ribbon {
  margin: 1em auto;
  width: 65%;
  font-family: $font-family-sans-serif;
  font-size: 28px;
  height: 42px;
  padding: 6px 0;
  position: relative;
  background: $es-dk-gray;
  color: $white;
  font-weight: 700;
  border-bottom: none;
  text-align:center;

  &:before {
    content: "";
    position: absolute;
    top: -0px;
    left: -40px;
    border-top: 22px solid $es-dk-gray;
    border-left: 20px solid transparent;
    border-bottom: 20px solid $es-dk-gray;
    border-right: 20px solid $es-dk-gray;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0px;
    right: -40px;
    border-top: 22px solid $es-dk-gray;
    border-left: 20px solid $es-dk-gray;
    border-bottom: 20px solid $es-dk-gray;
    border-right: 20px solid transparent;
    z-index: 0;
  }
}
.region-content {
  .sidebar {
    padding: .25em 1em;
    margin: 1em 0;
    border-top: 4px solid $es-green;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    &.key-takeaways{
      background-color: #f1f1f1;
    }
  }
  .sidebar-float{
    padding: .25em 1em;
    margin: 1em 0;
    border-top: 4px solid $es-green;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    clear: right;
    margin-top: 33px;
    margin-bottom: 33px;
    float: right;
    display: block;
    margin-left: 6%;
    width: 40%;
    margin-right: 0;
    position: relative;
    &:after {
      clear: both;
      height: 0;
      content: ' ';
    }
    @media(max-width: 600px) {
      width: 100%;
    }
  }
  .sidebar-expand{
    border-top: 4px solid $es-green;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    clear: right;
    display: block;
    float: right;
    margin: 2em 0 2em 1em;
    max-width: 40%;
    padding: .5em .5em .25em 1em;
    position: relative;
    width: 40%;
    &:after {
      clear: both;
      height: 0;
      content: ' ';
    }
    h3{
      font-size: 20px;
      line-height: 1.4;
    }
    .sidebar-expand-body{
       display: none;
       opacity: 0;
      &.expand {
        display: block;
        opacity: 1;
      }
    }
    .btn.expand, .btn.collapse {
      float: right;
      position: relative;
      text-align: center;
      background-color: transparent;
      color: #fff;
      border-radius: 50px !important;
      border: none;
      font-size: 15px;
      background: #4a77bb;
      line-height: 25px;
      .fa {
        margin: 0;
        padding: 0;
      }
    }
    .btn.expand {
      display: block;
    }
    .btn.collapse{
      display: none;
    }
    &.expand {
      max-width: 2000px;
      margin-left: 0;
      width: calc(100%);
      .btn.expand {
        display: none;
      }
      .btn.collapse{
        display: block;
      }
    }
    @media(max-width: 600px) {
      width: 100%;
    }
  }
  h3.field-label {
    margin-top: 1em;
    display: block;
  }
}

.view-larger-btn{
  margin-top: 1em;
}
#pageModal {
  .modal-header{
    padding: 15px 15px 30px 15px;
  }
  .modal-dialog {
    text-align: center;
  }
}
.bw-img {
  -webkit-filter: grayscale(100%);
   filter: grayscale(100%);
   opacity: .8;
}
//svg fixes for IE
.svg-ie-img {
  img {
    width: 80%;
  }
}
.svg-ie-imgLg{
  img {
    width: 100%;
  }
}
.svg-ieLg{
  width: 80%;
  height: 0;
  padding-top: 68%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.svg-ie{
  width: 70%;
  height: 0;
  padding-top: 68%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1em;
  }
}
//bg colors
.bg-gray {
  @extend .bg-info;
  background-color: #f1f1f1;
  text-align: left;
}
h3.bg-info {
  padding: .5em .75em;
}
p.bg-primary, p.bg-success, p.bg-info, p.bg-warning, p.bg-danger {
  padding: 1em;
}

/* Background colors */

// Dark gray background
//
// Markup:
// <div class="bg-dk-gray text-white text-center"><p>This is dark gray background</p></div>
//
// Style guide: Background-color.bg_dk_gray

/* Background colors */

// Light gray background
//
// Markup:
// <div class="bg-lt-gray text-white text-center"><p>This is dark gray background</p></div>
//
// Style guide: Background-color.bg_lt_gray

/* Background colors */

// Blue background
//
// Markup:
// <div class="bg-blue text-white text-center"><p>This is blue background</p></div>
//
// Style guide: Background-color.bg_blue

/* Background colors */

// Orange background
//
// Markup:
// <div class="bg-orange text-white text-center"><p>This is orange background</p></div>
//
// Style guide: Background-color.bg_orange

/* Background colors */

// Light blue background
//
// Markup:
// <div class="bg-lt-blue text-white text-center"><p>This is light blue background</p></div>
//
// Style guide: Background-color.bg_lt_blue


.bg-orange,
.bg-blue,
.bg-lt-blue,
.bg-green,
.bg-dk-gray {
  h2 {
    color: #fff;
  }
  a.btn-outline {
    border: 3px solid #fff;
    color: #fff;
  }
}
.bg-orange {
  background: $es-orange;
  color: #fff;
}
.bg-blue {
  background: $es-blue;
  color: #fff;
}
.bg-lt-blue {
  background: $es-lt-blue;
  color: #fff
}
.bg-green {
  background: $es-green;
  color: #fff
}
.bg-dk-gray {
  background: $es-dk-gray;
  color: #fff;
}
.bg-black {
  background-color: #000000;
  h2, h3, h4, label, a, p {
    color: #eee;
  }
}
.bg-lt-green {
  background-color: rgb(211, 223, 189);
}
.bg-dk-orange {
  background-color: #b56c10;
}
.bg-lt-gray{
  background-color: rgb(198, 198, 197);
}
.bg-dk-gray {
  background-color: rgb(84, 95, 100);
}
.bg-white-gray {
  background-color: #f1f1f1;
}
.bg-white{
  background-color: #fff;
}
// Gradient backgrounds
.bg-dk-gradient {
  background: rgb(14,15,16);
  background: linear-gradient(43deg, rgba(14,15,16,1) 45%, rgba(68,74,80,1) 100%);

  h2, h3, h4, label, a, p, ul li {
    color: #fff;
  }
  a:not(.content-card):not(.btn){
    text-decoration: underline;
  }
  &.vertical {
    background: linear-gradient(0deg, rgba(14,15,16,1) 45%, rgba(68,74,80,1) 100%);
  }
}
.bg-lt-gradient {
  background: rgb(241,241,241);
  background: linear-gradient(43deg, rgba(241,241,241,1) 45%, rgba(255,255,255,1) 100%);
}
.bg-teal-gradient{
  background: $es-lt-blue;
  background: linear-gradient(43deg, #528fab 40%, #7db2cb 100%);
  h2, h3, h4, label, a, p{
    color: #fff;
    text-shadow: 0 0 120px rgba(0, 0, 0, 0.8);
  }
}
.bg-orange-gradient{
  background: $es-orange;
  background: linear-gradient(43deg, #f79015 40%, #fdad4e 100%);
  h2, h3, h4, label, a, p{
    color: #fff;
    text-shadow: 0 0 120px rgba(0, 0, 0, 0.8);
  }
}
.bg-dk-silver-gradient{
  background: #444a50;
  background: linear-gradient(43deg, #444a50 40%, #545f64 100%);
  h2, h3, h4, label, a, p{
    color: #fff;
    text-shadow: 0 0 120px rgba(0, 0, 0, 0.8);
  }
}

.front.logged-in{
  .alert {
    h3, h4 {
      margin-top: 0;
    }
    h4{
      color: #545f64;
    }
  }
}
.front.logged-in {
  .announcement-container{
    position: absolute;
    right: 0;
    @media (max-width: 1360px){
      position: relative;
      right: auto;
      margin-top: 1.5em;
    }
    .announcement {
      @media (max-width: 768px){
        margin-top: 1em;
      }
      background-color: #353E40;
      border-radius: 5px;
      color: #fff;
      line-height: 1.5em;
      padding: 1em 0 1em 1em;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: nowrap;
      p{
        font-size: 17px;
        margin-top: 0;
        margin-left: 0;
      }
      a {
        color: $es-orange;
        text-decoration: underline;
      }
      .fa {
        color: $es-orange;
        padding-right: .5em;
      }
      .icon-center {
        @media(max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
.display-inline-block {
  display: inline-block;
}
//calendar
.calendar {
  padding-left: 0;
  padding-right: 0;

  .calendar-month, .calendar-day{
    font-family: $font-family-sans-serif;
  }

  .calendar-day {
    font-size: 24px;
  }
}
// Pagination
//
// Markup:
//<div class="pagination-container text-center">
//  <ul class="pagination">
//    <li class="active"><span>1</span></li>
//    <li><a href="#" class="pagination-link active">2</a></li>
//    <li><a href="#" class="pagination-link active">3</a></li>
//    <li><a href="/search/site?page=4" class="pagination-link active">4</a></li>
//    <li><a href="/search/site?page=2" class="active">next ›</a></li>
//    <li><a href="/search/site?page=339" class="active">last »</a></li>
//  </ul>
//</div>
//
// Style guide: Pagination.pager

.pagination-container{
  .pagination {
     li > a {
      border: none;
      font-size: 18px;
      font-weight: 400;
    }
    .active > span{
      background-color: transparent;
      border-bottom: 4px solid $es-green;
      border-top: none;
      border-right: none;
      border-left: none;
      color: $es-blue;
      font-weight: bold;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
.list-inline {
  text-align: center;
  & > li.comment_forbidden {
    border: 1px solid #dadada;
    border-radius: 5px;
    font-weight: bold;
    padding: 1em;
  }
}
.center-vertically{
  display: table;

  div{
    vertical-align: middle;
    display: table-cell;
  }
}

/* The element to be centered, can also be of any width and height */
.centered {
  display: inline-block;
  vertical-align: middle;
  width: 300px;
}
//legal
ol.license {
  margin-left: 0;
  padding-left: 0;

  tr {
    border: 1px solid #C0C0C0;
  }
  td {
    padding: 0.5em;
  }
  li {
    padding:5px 0;
  }
  li.heading {
    display: inline-block;
    margin: 0px;
    padding: 0px;
  }
  div {
    padding-right:20px;
    overflow:hidden;
  }

  > li > ol {
    padding:0;
  }

  > li > ol > li > ol {
    list-style-type: lower-alpha;
  }

  > li > ol > li > ol > li > ol {
    list-style-type: lower-roman;
  }

  > li {
    counter-increment: root;
  }

  > li > ol {
    counter-reset: subsection;
    list-style-type: none;
  }

  > li > ol > li {
    counter-increment: subsection;
  }

  > li > ol > li:before {
    content: counter(root) "." counter(subsection) " ";
  }
}
.no-padding{
  padding: 0;
}
.no-horz-padding {
  img {
    //padding: 1em 0;
  }
}
.full-margin-bottom{
  margin-bottom: 1em;
}
.half-margin-bottom {
  margin-bottom: .5em;
}
.img-responsive {
  margin: 0 auto;
}

/* Blockquote */

// Blockquote
//
// Markup:
// <blockquote><p>This is a blockquote</p></blockquote>
//
// Style guide: Blockquote.blockquote-1

//blockquote
blockquote {
  box-shadow: 0px -2px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-left: 5px solid $es-green;
  padding: 1em 1em 1em 2.5em;
  margin-bottom: 15px;
  margin: 2em;
  font-size: 16px;
  color: $es-dk-gray;
  position: relative;

  &:before {
    content: "\201C";
    display: inline-block;
    position: absolute;
    margin-left: -.65em;
    font-size: 3em;
    font-family: $font-family-sans-serif;
    color: $es-green;
  }
}
.wrap-word{
  white-space: pre-line;
}
//pullquotes

// Pullquote right
//
// Markup:
//<p>We calculated the likelihood for Nielsen’s 66 PRIZM Premier segments to receive their monthly bill online. Any segment that achieved an index score of 120 or higher is considered to have a high propensity for participation. Index scores are calculated by dividing a segment’s participation rate by its share of the overall population, which determines the segment’s likelihood to carry out an action.</p>
//<p class="pullquote-right">Boosting participation in online payment services reduces postage costs and can be more convenient for customers.</p>
//<p>We calculated the likelihood for Nielsen’s 66 PRIZM Premier segments to receive their monthly bill online. Any segment that achieved an index score of 120 or higher is considered to have a high propensity for participation. Index scores are calculated by dividing a segment’s participation rate by its share of the overall population, which determines the segment’s likelihood to carry out an action.</p>
//
// Style guide: Pullquotes.pullquote-right


.pullquote-right{
  float: right;
  font-family: $font-family-sans-serif;
  font-weight: bold;
  padding: .25em 0 .25em .5em;
  margin: .5em 0 .5em 2em;
  width: 40%;
  font-size: 1.25em;
  line-height: 1.6em;
  text-align: left;
  color: $es-green;
  border-left: 3px solid $es-lt-gray;
  @media (max-width: 768px){
    border-left: 0;
    padding: 0;
    width: 100%;
  }
}
// Pullquote left
//
// Markup:
//<p>We calculated the likelihood for Nielsen’s 66 PRIZM Premier segments to receive their monthly bill online. Any segment that achieved an index score of 120 or higher is considered to have a high propensity for participation. Index scores are calculated by dividing a segment’s participation rate by its share of the overall population, which determines the segment’s likelihood to carry out an action.</p>
//<p class="pullquote-left">Boosting participation in online payment services reduces postage costs and can be more convenient for customers.</p>
//<p>We calculated the likelihood for Nielsen’s 66 PRIZM Premier segments to receive their monthly bill online. Any segment that achieved an index score of 120 or higher is considered to have a high propensity for participation. Index scores are calculated by dividing a segment’s participation rate by its share of the overall population, which determines the segment’s likelihood to carry out an action.</p>
//
// Style guide: Pullquotes.pullquote-left

.pullquote-left{
  float: left;
  font-family: $font-family-sans-serif;
  font-weight: bold;
  padding: .25em 1em .25em 0;
  margin: .5em 2em 1em 0;
  width: 40%;
  font-size: 1.25em;
  line-height: 1.6em;
  text-align: left;
  color: $es-green;
  border-right: 3px solid $es-lt-gray;
  @media (max-width: 1350px){
    margin: .5em 2em 1em 0;
  }
  @media (max-width: 768px){
    border-right: 0;
    padding: 0;
    width: 100%;
  }
}
.pullquote-full{
  @extend .pullquote-left;
  border-top: 3px #dadada solid;
  border-right: 0;
  padding: .75em 1em .75em 0;
  width: 100%;
  .fa-mobile {
    font-size: 1.7em;
  }
}

//byline
.byline-container {
  margin: 1em 0;
}
.author, .contributor{
  color: $es-dk-gray;
  font-family: $font-family-sans-serif;
}
.field-esource-pubauthor, .author{
  font-size: 1.3em;
  margin: .5em 0;
}
.field-esource-sort-date{
  font-family: $font-family-sans-serif;
  font-size: 16px;
    margin-bottom: 25px;
}
.contributor {
  font-size: 1em;
  opacity: .7;
}
.pub-date, .pub-id, .field-label {
  color: $es-dk-gray;
  display: inline;
  font-weight: 500;
}
.pub-date {
  display: block;
}
.subtitle {
  @extend h2;
  color: $es-blue;
  margin: .25em 0 0 0;
  padding: 0;
  opacity: .8;
}
.subhead {
  font-weight: 600;
  color: $es-dk-gray;
}
.summary {
  color: $es-dk-gray;
  font-weight: bold;
  font-style: italic;
  font-family: $font-family-sans-serif;
  margin: 2em 0;
}
.initial{
  color: $es-green;
  float: left;
  font-family: $font-family-sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 28px;
  padding-right: 8px;
  padding-left: 2px;
}
.drop-cap{
  color: $es-lt-blue;
  float: left;
  font-size: 80px;
  line-height: 64px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  font-family: $font-family-sans-serif;
}
.well {
  border: 0;
  box-shadow: none;
  background-color: #f1f1f1;

  &.downloadable-content{
    margin-top: .5em;

    h2{
      margin-top: .25em;
    }
  }
  // .downloadable-content {
  //   h3{
  //     color: $gray-darker;
  //   }
  // }
  &.border {
    border-top: #dadada 2px solid;
    border-bottom: #dadada 2px solid;
    background: transparent;
    border-radius: 0;

    h3{
      margin-top: .25em;
    }
  }
  &-warn {
    border-top: 4px solid $es-orange;
  }
  &-success {
    border-top: 4px solid $es-green;
  }
}
.border-well {
  h2{
    color: $es-blue;
    text-align: center;
  }
  .row {
    margin: 0;
  }
  &.well {
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #c6c6c5;
    h2{
      margin-top: .25em;
    }
  }
}
.downloadable-content-title {
  margin-left: .5em;
  margin-top: .5em;
}
.downloadable-content {
  .header {
    text-align: right;
  }
  // h3 {
  //   color: $gray-base;
  // }
}
.bold {
  font-weight: bold;
}
.italic {
  font-weight: italic;
}
.indent{
  padding-left: 1.5em;
}
.big-btn {
  white-space: normal;
}
.text-white {
  color: #fff;

  &.dropshadow{
    text-shadow: 0 0 2px rgba(0,0,0,0.25);
  }
}
.small{
  line-height: 1.4em;
}
.big-number {
  font-weight: 300;
  font-size: 40px;
  margin-top: .5em;
}
.header-white-border {
  border: 4px solid #fff;
}
.collapsible-wrapper{
  position: relative;
}
.collapsible-wrapper a:focus {
  background-color: $es-blue;
  border-color: #406bab;
}
.collapsible-wrapper a:focus:hover{
  background-color: #395f99;
  border-color: #1d314f;
}
//square bullets
.field-body, .field-esource-preview {
  ul {
    list-style-type: square;
  }
}
.fa {
  margin-right: .25em;
}
.es-initial-hidden{
  display:none;
}
.view-press-releases {
  .panel {
    a {
      display: inline;
    }
  }
}
//audio
.audio-title {
  padding-top: 1em;
}
.field-ivr-recordings {
  margin-left: auto;
  margin-right:auto;
  margin-bottom: 1em;
  position: relative;
  height: 50px;
}
.mejs-container {
  margin: 1em auto;
  width: 100% !important;
}
.mejs-playlist {
  position: relative !important;
  height: 50px !important;
  opacity: 1;
  width: 100% !important;

  ul {
    padding: 0;

    li{
      line-height: 25px;
      height: 25px;
      margin: 0;
      padding-left: .5em;
      overflow: visible;

      &.current {
        background-color: #333;
        font-weight: normal;
      }
    }
  }
}
.mejs-controls {
  .mejs-time-rail {
    .mejs-time-loaded {
      background: linear-gradient(rgba(200,200,200,.8),rgba(255,255,255,.9))
    }
    .mejs-time-current {
      background: linear-gradient(rgba(121,172,66,.8),rgba(82,117,45,.8));
    }
  }
}
//responsive video
.ext-video-container{
  padding: 1em;

  .ext-video{
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 0;
    }
  }
}
.fixed-bg-image {
  background-attachment: fixed;
  background-size: cover;
  height: 300px;
}



/* Icons */

// Circle icon with texture
//
// Markup:
//  <div class="circle-icon-texture-sm">
//   <a href="#"><i class="fa fa-file-text-o fa-2x"></i></a>
//  </div>
//
// Style guide: Icons.circle-icon-texture

//circles
.circle-icon-texture {
  background-position: center;
  background-size: cover;
  background-position: top center;
  border-radius: 150px;
  width: 150px;
  height: 150px;
  padding: 0;

  a {
    background-color: rgba(108, 156, 178, 0.8);
    border: 0;
    border-radius: 150px;
    display: block;
    white-space: normal;
    color: #ffffff;
    border-radius: 150px;
    width: 150px;
    height: 150px;
    text-align: center;
    line-height: 160px;
  }
  .fa {
    margin: 0;
  }
}
.circle-icon-texture-sm {
    background-image: url(/sites/all/themes/es_bootstrap/img/sm-icon-texture.jpg);
    background-size: cover;
    background-position: top center;
    border-radius: 100px;
    width: 100px;
    height: 100px;
    margin: 1.5em auto;
    padding: 0;
    a {
      background-color: rgba(108, 156, 178, 0.8);
      border: 0;
      border-radius: 100px;
      display: block;
      white-space: normal;
      color: #ffffff;
      cursor: default;
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 110px;
    }
    .fa {
      margin: 0;
    }
}
.circle-icon-texture-xs {
    background-position: center;
    background-size: cover;
    background-position: top center;
    border-radius: 60px;
    width: 60px;
    height: 60px;
    margin: 1.5em auto;
    padding: 0;
    a {
      background-color: rgba(108, 156, 178, 0.8);
      border: 0;
      border-radius: 60px;
      display: block;
      white-space: normal;
      color: #ffffff;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 70px;
    }
    .fa {
      margin: 0;
    }
}
.icon-center {
  padding-left: 1.25em;
  height: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
//Parallax
.deliverable-parallax{
  height: 275px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 1em;
}
#embedded-data-centers-para{
  background-image: url("https://www.esource.com/sites/default/files/data-center-server-para.jpg");
  @media only screen and (min-width : 1200px) {
    background-size: 55% 100%;
  }
  @media only screen and (max-width : 979px) {
    background-size: cover;
  }
}
#midsize-nonhospital-healthcare-para{
  background-image: url("https://www.esource.com/sites/default/files/midsize-nonhospital-healthcare-parallax_6.jpg");
  @media only screen and (min-width: 1200px){
    background-size: 60% 100%;
  }
  @media only screen and (max-width: 979px){
    background-size: cover;
  }
}
// Image overlay used on BCRP17
.img-overlay-container{
  img{
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-width: none;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }
  img:hover{
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    filter: blur(5px);
    -moz-filter: blur(5px);
    -webkit-filter: blur(5px);
  }
  .col-sm-6{
    margin: 1em 0 0 0;
  }
}
.img-overlay-description {
  position: absolute;
  text-align: center;
  color: #545f64;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  margin: 0 auto;
  padding: 1em;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-overlay:hover .img-overlay-description {
  visibility: visible;
  opacity: 1;
  color: $white;
}
.img-overlay {
  position: relative;
  max-width: 375px;
  max-height: 325px;
  margin: 0 auto;
  overflow: hidden;
  &:before {
    transition: all .25s ease-out;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    opacity: 0;
  }
  .img-overlay-coming-soon, .img-overlay-see-more, .gray-img-overlay{
    z-index: 2;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    img{
      transform: scale(1.1);
      filter: blur(5px);
      transition: all .25s ease-out;
    }
    .gray-img-overlay > div{
      transform: scale(1.5);
      transform: rotate(360deg);
    }
    .img-overlay-coming-soon, .img-overlay-see-more{
      visibility: visible;
      opacity: 1;
    }
  }
}
.gray-img-overlay {
  transition: transform .25s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(199, 198, 197, 0.85);
  color: #fff;
  padding: 35% 41%;
}
.gray-img-overlay > div {
  transition: transform .25s ease-in;
}

.img-overlay-coming-soon, .img-overlay-see-more{
  position: absolute;
  top: 85%;
  left: 5%;
  color: $white;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  margin: 0 auto;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

.gray-img-overlay:hover{
  visibility: visible;
  opacity: 1;
  cursor: default;
}
.img-overlay-see-more:hover{
  text-decoration: underline;
}
.img-overlay-label {
  max-width: 375px;
  text-align: center;
  margin: 0 auto;
  h3{
    margin: .5em auto 1.5em auto;
  }
}
.img-overlay-icon {
  max-width: 300px;
  max-height: 200px;
  padding: 0;
  text-align: center;
  margin-bottom: 1em;
  background-color: #f1f1f1;
  height: 270px;
  display: block;
  position: relative;
  overflow: hidden;

  a {
    display: block;

    &:hover {
      text-decoration: none;
    }
  }
  .overlay{
    position:absolute;
    top:0px;
    left:0px;
    bottom:0px;
    right:0px;
    background-color:rgba(0, 0, 0, 0.15);
    height: 100%;
    width: 100%;
    .fa {
      display: none;
    }
    h3{
      padding-top: 25%;
      padding-bottom: 25%;
      a{
        color: #fff;
      }
    }
    &:hover{
      .fa {
        display: block;
        padding: 25%;
        color: #ffffff;
      }
    }
  }
  img {
    transition: all 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    max-width: none;
  }
  &:hover {
    //border: 3px solid #f1f1f1;
    img {
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      filter: blur(5px);
    }
  }
}
.gray-border-card.box-height-300{
  height: 460px;
  @media (max-width: 1024px) {
    height: 300px;
  }
  @media (max-width: 500px) {
    height: 500px;
  }
}
.box-height-300 {
  height: 350px;
  margin-right: .5em;
  margin-bottom: .5em;
  width: calc(33.3% - .5em);
}
.circle-icon-img {
  border-radius: 90px;
  display: inline-block;
  height: 90px;
  overflow: hidden;
  margin: 12px;
  width: 90px;
  img {
    padding: 1em 0;
  }
}
.circle-icon, .circle-icon-md, .circle-icon-sm {
  i{
    background: #f1f1f1;
    color: $es-blue;
  }
}
.circle-icon-md-blue {
  @extend .circle-icon-md;
  margin-top: .5em;
  i{
    background: $es-lt-blue;
    color: $white;
  }
}
.circle-icon, .circle-icon-md, .circle-icon-sm , .circle-icon-blue {
  margin-bottom: 1em;
  h3{
    color: #fff;
  }
  i {
    margin: .25em;
    border-radius: 100px;
    width: 100px;
    height: 100px;
    text-align: center;

    &:before {
      line-height: 90px;
    }
  }
}
.circle-icon-md {
  i {
    width: 60px;
    height: 60px;

    &:before {
      font-size: 25px;
      line-height: 60px;
    }
  }
}
.circle-icon-sm {
  i {
    width: 40px;
    height: 40px;
    &:before {
      line-height: 40px;
    }
  }
}
a.circle-icon-sm {
  i{
    color: $es-green;
  }
}
.circle-icon-dk-gray {
  line-height: 45px;
  border-radius: 100px;
  color: #545f64;
  border: 3px solid #545f64;
  padding: .5em;
  width: 80px;
  height: 80px;
}
.well {
  .circle-icon-sm {
    i {
      background: #fff;
    }
  }
}
.circle-icon {
  &-warn {
    vertical-align: text-top;
    margin-right: 10px;
    .fa-lock {
      margin: 0;
      height: 45px;
      width: 45px;
      line-height: 45px;
      background-color: $es-orange;
      color: #fff;
      vertical-align: middle;
      font-size: 3rem;
      &:before {
        line-height: 0;
      }
    }
  }
}
.circle-sm {
  border-radius: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}
.circle-big {
  border-radius: 100px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  width: 75px;
  margin: 0 auto;
}
p.label {
  display: inline-block;
  margin-top: -1em;
}
//inforgraphic for homepage and solutions for utilities pages
.homepage-map {
  background: #4a77bb center center;
  text-align: center;
}
.clip-circle {
  clip-path: circle(60px at center);
}
li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: $es-lt-gray;
}
//NEW WEBSITE COMPONENTS START HERE
body{
  background-color: #fff;
  color: $es-dk-gray;
  font-weight: 400;
  font-size: 1.75em;
  line-height: 1.6667em;
  font-family: $font-family-sans-serif;

  h1{
    font-family: $font-family-sans-serif;
    color: $es-dk-gray;
    font-weight: 600;
  }
  h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-style: normal;
    color: $es-dk-gray;
  }
  h1 {
    font-size: 50px;
  }
  h2{
    font-size: 35px;
  }
  .subtitle {
    font-size: 30px;
    border-top: 1px solid #fff;
  }
  h3{
    font-size: 25px;
  }
  h4 {
    font-size: 20px;
  }
  /* Buttons */

  // Green button
  //
  // Markup:
  // <a href="#" class="btn btn-success">Green button</a>
  //
  // Style guide: Buttons.button_green

  /* Buttons */

  // Blue button
  //
  // Markup:
  // <a href="#" class="btn btn-primary">Blue button</a>
  //
  // Style guide: Buttons.button_blue

  /* Buttons */

  // Orange button
  //
  // Markup:
  // <a href="#" class="btn btn-warning">Orange button</a>
  //
  // Style guide: Buttons.button_orange

  /* Buttons */

  // Light blue button
  //
  // Markup:
  // <a href="#" class="btn btn-info">Light blue button</a>
  //
  // Style guide: Buttons.button_ltblue

  .btn {
    border-radius: 30px;
  }
  .blue-icon-btn {
    position: relative;
    a {
      background-color: #f1f1f1;
      border-radius: 80px;
      color: $es-blue;
      display: inline-block;
      padding: 0 .5em .25em 0;
      margin-bottom: .5em;
      width: 100%;
      height: 45px;

      &:hover {
        background-color: #dadada;
        text-decoration: none;
      }
    }
    .fa, svg{
      background-color: $es-blue;
      color: #fff;
      font-size: .75em;
      height: 40px;
      line-height: 40px;
      width: 40px;
      margin-top: .25em;
      margin-right: .5em;
      border-radius: 100px;
      text-align: center;

      &:before {
        font-size: 20px;
        padding-top: 1em;
      }
    }
    svg {
      padding: .5em;
    }
    span {
      position:absolute;
      margin-top: .5em;
    }
  }
  .bg-blue {
    background: $es-blue;
    color: #fff;

    h2 {
      color: #fff;
    }
    .btn{
      color: $es-blue;
      background-color: $white;
      border: 3px $es-blue solid;

      &:hover {
        color: $white;
        background-color: $es-blue;
        border: 3px $white solid;
      }
    }
  }
  h2.text-white {
    color: #fff;
  }
  .bg-lt-blue {
    h2 {
      color: #fff;
    }
  }
  .bg-gray {
    .btn-block {
      color: $white;
      background-color: $es-green;
      border: 3px $es-dk-gray solid;

      &:hover {
        color: $es-green;
        background-color: $es-dk-gray;
        border: 3px $es-green solid;
      }
    }
  }
  .text-white:hover {
    text-decoration: none;
    h4 {
      opacity: .8;
    }
  }
  .icon-circle {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: $es-green;
    color: #fff;
    line-height: 100px;
    text-align: center;
    cursor: pointer;
    opacity: .9;
    padding: 1em;

    &:hover {
      background-color: $es-blue;
    }
  }
  .icon-circle-gray {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: $es-blue;
    color: #fff;
    line-height: 100px;
    text-align: center;
    opacity: .9;
  }
  .remove-margin{
    margin: 0;
  }
  .black-white-filter {
    filter: none;

    &:hover {
      filter: grayscale(100%);
    }
  }

  //gradient overlays for photos
  .gradient-container{
    bottom: 0;
    height: 400px;
    position: absolute;
    width: 100%;
    background-color: rgba(0,0,0,0.9);
    background: -moz-linear-gradient(8deg, rgba(0,0,0,0.9), transparent, transparent) !important;
    background: linear-gradient(8deg, rgba(0,0,0,0.9), transparent, transparent) !important;
  }
  .gradient-container-big {
    position: relative;

    &:after {
      background-color: rgba(108, 156, 178, 0.9);
      background: -moz-linear-gradient(-180deg, rgba(0, 0, 0, 0.9), transparent, transparent) !important;
      background: linear-gradient(-180deg, rgba(0, 0, 0, 0.9), transparent, transparent) !important;
      height: 400px;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  //viewport heights
  .full-viewport-height  {
    height: 90vh;
    min-height: 90vh;
    margin: 0 -15px;
    position: relative;
    overflow: hidden;

    @media (max-width: 992px) {
      height: 100%;
      position: relative;
    }
  }
  .relative {
    position: relative;
  }
  //vertical and horizontal centering
  .vertical-center-container {
    position: relative;
  }
  .not-logged-in {
    .vertical-center {
      top: 60%;
    }
  }

  .vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1024px) {
      position: relative;
      top: inherit;
      transform: none;
      padding: 10% 0;
    }
    @media (max-width: 425px) {
      position: relative;
      top: inherit;
      transform: none;
      padding: 30% 0;
    }
  }
  .horizontal-center {
    left: 0;
    margin: 0 auto;
    right: 0;
    text-align: center;
  }
  //Centering both vertical and horizontal; .center-all-container needs an inline height style attribute in the HTML
  .center-all-container {
    position: relative;
    overflow: auto;
  }
  .center-all {
    width: auto;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: auto;
  }
  .inline-nav {
    background-color: #f1f1f1;
    padding: 1em 0;
  }
  .util-logo {
    width: 150px;
  }
  //white tooltips
  .white-tooltip{
    margin-bottom: 3.5em;

    h4{
      font-size: 18px;
    }

    a[data-toggle="tooltip"] {
      display: block;
      height: 100px;

      &:hover{
        text-decoration: none;
      }
    }
    .tooltip {
      .tooltip-inner {
        background-color: $white;
        border-color: #fff;
        color: $es-dk-gray;
        opacity: .9;
      }
      .tooltip-arrow{
        border-bottom-color: #fff !important;
        opacity: .9;
      }
    }
  }
}
section.col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}
.node-type-event, .page-blog {
  section.col-sm-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-blog {
  #page-header {
    h1 {
      padding-left: 15px;
    }
  }
}
.node-type-marketing-pages {
  .intro {
    padding-top: 100px;
    padding-bottom: 20px;

    h1, h2 {
      color: $white;
    }
    h1{
      margin-bottom: .5em;
    }
  }
  .well {
    background-color: #d1d1d1;
    border-radius: 0;
  }
  .testimonial-container {
    background-color: $white;
  }
}
.front {
  .testimonial-container{
    background-color: $es-dk-gray;
  }
}
//media queries

@media (max-width: 767px) {
  body{
    padding-bottom: 18em;
  }
  h2.ribbon {
    padding: .4em 0;
    width: 100%;
    height: inherit;
    font-family: $font-family-sans-serif;

    &:before, &:after {
      border: 0;
    }
  }
  .header-white-border {
    border: 0;
  }
  .node-type-marketing-pages.logged-in:not(.page-es-user-regwiz) .wrapper, .node-type-marketing-pages.logged-in:not(.page-es-user-regwiz) .wrapper.active {
    // padding: 1em;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .footer {
    .container {
      width: 350px
    }
  }
}
@media (max-width: 425px) {
  .node-type-marketing-pages .main-content {
    margin: 0;
  }
  .footer {
    .container {
      width: 300px
    }
  }
}
@media (min-width: 768px) {
  .node-type-marketing-pages .container {
    width: 550px;
  }
  .node-type-deliverable {
    .main-content.container {
      width: 100%;
    }
  }

  #pageModal{
    .modal-dialog {
      width: 600px;
    }
  }
  .node-type-marketing-pages .main-content > .row > .col-sm-12 {
    width: calc(100%);
  }
}
@media (max-width: 768px){
  .container-fluid {
    .container {
      width: 100%;
    }
  }
}
@media (min-width: 1024px){
  .node-type-marketing-pages {
    .container {
      width: 1000px;
    }
  }
  .logged-in.node-type-marketing-pages {
    .container {
      width: 770px;
    }
  }
}
@media (min-width: 992px) {
  .container {
    width: 900px;
  }
  .node-type-deliverable {
    .main-content.container {
      width: 100%;
    }
  }
  .node-type-marketing-pages {
    .container {
      width: 900px;
    }
  }
  .logged-in.node-type-marketing-pages {
    .container {
      width: 700px;
    }
  }
  #pageModal{
    .modal-dialog {
      width: 900px;
    }
  }
}
@media (min-width: 1200px) {
  #pageModal{
    .modal-dialog {
      width: 1100px;
    }
  }
  .container {
    width: 1150px;
  }
  .node-type-marketing-pages {
    .container {
      width: 1100px;
    }
  }
  .logged-in.node-type-marketing-pages {
    .container {
      width: 970px;
    }
  }
  .node-type-deliverable, .page-blog, .page-search, .node-type-iir, .node-type-blog, .node-type-event, .node-type-vault, .node-type-marketing-pages{
    .main-content.container {
      width: 70%;
    }
  }
}
@media (min-width: 1400px) {
  #pageModal{
    .modal-dialog {
      width: 1400px;
    }
  }
  .page-questions- {
    #pageModal {
      .modal-dialog {
        width: 1100px;
      }
    }
  }
  .node-type-marketing-pages {
    .container {
      width: 1170px;
    }
  }
  .logged-in.node-type-marketing-pages {
    .container {
      width: 1170px;
    }
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .search-bar {
    .jumbotron {
      margin-top: -30px;
    }
  }
}
//Social Embeds
.twitter-tweet {
  margin: 0 auto;

  @media(max-width: 600px) {
    width: 100% !important;
  }
}
.instagram-media{
  margin: 15px auto !important;
}

//Interactive EV Map
.svg-ev-road-wrapper{
  width: 100%;
  padding-top: 40%;
  svg{
    a:hover{
      text-decoration: none;
    }
    text{
      font-family: $font-family-sans-serif;
    }
  }
  .svg-link-text{
    fill: $es-dk-gray;
  }
  .svg-link:hover > rect,
  .svg-link:hover > circle,
  .svg-link:hover > path{
    opacity: .3;
    cursor:pointer;
  }
}

//Interactive 9-Region Map
.svg-regional-map-wrapper{
  svg{
    a:hover{
      text-decoration: none;
    }
  }
  .svg-link-text{
    display: none;
    font-size: 14px;
    fill: #545f64;
    font-weight: 200;
    cursor: pointer;
  }
  .svg-link-text-overlay{
    display: none;
    font-size: 12px;
    font-weight: 200;
    fill: #545f64;
    cursor: default;
  }
  .svg-link:hover > g > path,
  .svg-link:hover > g > polygon{
    opacity: .5;
    cursor:pointer;
  }
  .svg-link:hover{
    .svg-link-text,.svg-link-text-overlay{
      display: block;
    }
  }
  .svg-link-overlay > g > path,.svg-link-overlay > g > polygon{
    fill: #c6c6c5;
    opacity: .65;
  }
  .svg-link-overlay > g:hover{
    opacity: .65;
    .svg-link-text,.svg-link-text-overlay{
      display: block;
    }
  }
}
//Interactive 9-Region Map
.svg-regional-map-wrapper{
 svg{
   a:hover{
      text-decoration: none;
    }
  }
  .svg-link-text{
    display: none;
    font-size: 14px;
    fill: #545f64;
    font-weight: 200;
    cursor: pointer;
  }
  .svg-link-text-overlay{
    display: none;
    font-size: 12px;
    font-weight: 200;
    fill: #545f64;
    cursor: default;
  }
  .svg-link:hover > g > path, .svg-link:hover > g > polygon{
    opacity: .5;
    cursor:pointer;
  }
  .svg-link:hover{
    .svg-link-text,.svg-link-text-overlay{
      display: block;
    }
  }
  .svg-link-overlay > g > path,.svg-link-overlay > g > polygon{
    fill: #c6c6c5;
    opacity: .65;
  }
  .svg-link-overlay > g:hover{
    opacity: .65;
    .svg-link-text,.svg-link-text-overlay{
      display: block;
    }
  }
}

// Positioning Helper Classes
.-mb-5 {
  margin-bottom: -5em;
}

// Grid arrangements
.grid-box{
  &.two-by-two{
    display: -ms-grid;
    display: grid;
    grid-template-areas:
        "item1 item3"
        "item2 item4";
    .grid-item1{
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      grid-area: item1;
    }
    .grid-item2{
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      grid-area: item2;
    }
    .grid-item3{
      -ms-grid-row: 1;
      -ms-grid-column: 2;
      grid-area: item3;
    }
    .grid-item4{
      -ms-grid-row: 2;
      -ms-grid-column: 2;
      grid-area: item4;
    }
    @media(max-width: 991px){
      grid-template-areas:
        "item1"
        "item2"
        "item3"
        "item4";
      .grid-item1{
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      .grid-item2{
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
      .grid-item3{
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }
      .grid-item4{
        -ms-grid-row: 4;
        -ms-grid-column: 1;
      }
    }
    @supports not (display: grid) {
      .grid-item1, .grid-item2, .grid-item3, .grid-item4{
        height: 50%;
        width: 50%;
        float: left;
        @media(max-width: 991px){
          width: 100%;
        }
      }
    }
  }
}

a.locked-content-inline{
  border-bottom: 2.5px dotted $es-orange;
  position: relative;
  &:hover{
    background-color: #ffe6c8;
    border-bottom-width: 2px;
    border-bottom: 2px solid $es-orange;
    color: #000;
    text-decoration: none !important;
    transition: all 0.1s cubic-bezier(0.000, 0.590, 1.000, 0.260);
  }
  &:focus{
    border-bottom: none;
    outline: 2px dotted $es-orange;
  }
  .locked-content-tooltip[role="tooltip"] {
    display: none;
  }
  &:hover > .locked-content-tooltip[role="tooltip"] {
    display: flex;
    background: #f1f1f1;
    border-left: 4px solid $es-orange;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: $es-dk-gray;
    padding: 10px;
    position: absolute;
    pointer-events: none;
    width: 300px;
    z-index: 100;
    left: 0;
    top: 30px;
    i{
      color: $es-orange;
      margin: 5px 10px auto 5px;
    }
  }
}

.btn.locked-content-inline{
  border-bottom: none;
  &:hover{
    background-color: $es-dk-blue;
    border-bottom: 1px;
    color: #fff;
  }
  &:focus{
    outline: auto;
  }
  &:hover > .locked-content-tooltip[role="tooltip"] {

    width: auto;
    top: 35px;
  }
}

// admin styles
.admin-fields{
  margin: 10px 0;
}

.alert.alert-block{
  .alert-success{
    background-color: #f1f7e9;
    color: $es-dk-gray;
  }
  a.close{
    opacity: .5;
    text-decoration: none;
  }
}
