#comparatives.active {
  overflow: visible;
}
#block-es-omni-es-omni-use-of-channels,
#block-es-omni-es-omni-bar,
#block-es-omni-es-omni-expected-response,
#block-es-omni-es-omni-first-attempt-res,
.page-node-207568,
#block-es-omni-es-omni-hours-op
/* note: make sure to change body class and this to omnichannel once done */ {
  position: relative;
  svg {
    position: relative;
  }
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }
  .x.axis.vert {
    font-size: 14px;
    .tick line {
    stroke: transparent;
    }
  }
  .y.axis.horiz {
    .tick line {
    stroke: transparent;
    }
  }
  .channels ul, .voices ul{
    text-align: center;
    li {
      display: inline;
      float: none;
      margin: 0 5px;
      a {
        display: inline-block;
      }
      .active {
        background: $btn-warning-bg;
        border-color: darken($btn-warning-bg, 5%);
      }
    }
  }
  .ageUse ul {
    text-align: center;
    li {
      display: inline;
      float: none;
      margin: 0 5px;
      a {
        display: inline-block;
        &.use{
          &.active {
            background: $btn-success-bg;
            border-color: darken($btn-success-bg, 5%);
          }
        }
        &.age {
          &.active {
            background: $btn-warning-bg;
            border-color: darken($btn-warning-bg, 5%);
          }
        }
      }
    }
  }
  .channels ul {
    margin-top: 10px;
  }
  .voices ul {
    a.voc.active {
      background: $btn-success-bg;
      border-color: darken($btn-success-bg, 5%);
    }
    a.vou.active {
      background: $btn-primary-bg;
      border-color: darken($btn-primary-bg, 5%);
    }
  }
  .VOU_Importance, .VOU {
    fill: $es-blue;
  }
  .VOU_Quality {
    fill: $es-lt-blue;
  }
  .VOC_Importance, .Any_Company, .VOC {
    fill: $es-green;
  }
  .VOC_Quality, .Utility {
    fill: $es-lt-green;
  }
  .Over35 {
    fill: $es-orange;
  }
  .Under35 {
    fill: $es-dk-orange;
  }
  .small-n {
      stroke-dasharray: 5;
      stroke: #50545f;
      stroke-width: 2px;
    }

  .bar-tooltip, .bar-tooltip-uoc {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    margin: 0;
    padding: 10px;
    width: 220px;
    height: 220px;
    color: white;
    font-size: 12px;
    background-color: rgba(0,0,0,0.75);
    opacity:0;
    pointer-events: none;

    ul {
      list-style-type: none;
      margin: 0;
      padding:0;
    }
    li {
      margin: 1px 0;
      padding: 0;
    }
    .arc1 text {
      fill: transparent;
    }
  }
  .color-legend {
    .color-block {
      width: 1.5em;
      height: 1.5em;
      margin-bottom: .25em
    }
  }
  .legend-bot-right{
    position: absolute;
    right: 0;
    top: 25em;
  }
  .png {
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  #block-es-omni-es-omni-hours-op, #block-es-omni-es-omni-use-of-channels {
    .legend-bot-right {
      position: relative;
      top: 3em;
    }
  }
}
.uoc {
   .bar-tooltip-uoc {
     svg {
       padding: 0;
     }
   }
 }

//omnichannel page
.node-type-marketing-pages {
  a.btn.disabled, fieldset[disabled] a.btn {
   pointer-events: auto;
 }
  .omnichannel-image {
    height: 500px;
    background-image: url('../img/Omnichannel-Survey.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
  }
  .key-findings {
    position: relative;
  }
}
.view-omnichannel-queue {
  .img-responsive {
    padding: 0;
  }
  h3{
    .fa {
      display: inline;
    }
  }
}
