

#es-alerts-subscribe {
  .category-head-wrapper {
    border-bottom: 1px #a7a7a7 solid;
    padding-bottom: .25em;
    padding-left: 0;
  }
  .category-head {
    font-weight: 500;
    font-size: 18px;
    padding-left: 0;
  }
  .form-submit {
    margin-left: -1em;
  }
  .alerts-category-wrapper {
    margin-right: .25em;
  }
}
@media (max-width: 992px) {
  #es-alerts-subscribe {
    h4 {
      font-size: 16px;
    }
  }
}
@media (max-width: 768px) {
  .select-all-wrapper {
    margin-top: 0em;
  }
  #es-alerts-subscribe {
    .category-head-wrapper {
      border-top: 1px #a7a7a7 solid;
      padding-top: 1em;
      border-bottom: 0;
    }
    .alerts-wrapper-account-management{
      .category-head-wrapper {
        border-top: 0;
        padding-top: 0;
      }
    }
  }
}
input[type="checkbox"] {
  margin-top: 6px;
}
