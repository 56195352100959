form {
  .panel {
    border: 0;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
  .panel-body {
    padding: 15px 0;
  }
}
.panel-group .panel, .panel, .panel-group, .panel-heading{
  //border-radius: 0;
}
.front.logged-in .form-panel, .form-panel {
  margin: 1em 0;

  h4.panel-title {
    margin: 0;
  }
  .panel-default {
    border: #ddd 1px solid;
    margin: .25em 0;
  }
  .form-panel-body{
    padding: 0 1em;
  }
  .btn-success {
    margin-top: 1em;
    margin-left: -14px;
  }
}
.panel-group{
  .panel-title {
    a {
      color: $es-blue;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .well {
    margin-top: 1em;
    margin-bottom: 0;
    background-color: #ededed;
  }
}
.panel {
  [aria-expanded='true'] {
    .fa-minus {
      display: inline;
    }
   .fa-plus {
      display: none;
    }
  }
  [aria-expanded='false'] {
  .fa-minus {
      display: none;
    }
    .fa-plus {
      display: inline;
    }
  }
}

.captcha.panel {
  width: 100%;
  background-color: transparent;
  padding: 0;
  margin-bottom: 2em;
  .panel-body {
    padding: 0;
  }
}