/* Forms */

// Forms
//
// Markup:
//  <form>
//   <div class="form-group">
//     <label for="exampleInputEmail1">Email address</label>
//     <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" autocomplete="off" cursor: auto;">
//     <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
//   </div>
//   <div class="form-group">
//     <label for="exampleInputPassword1">Password</label>
//     <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" autocomplete="off">
//     </div>
//   <div class="form-group">
//     <label for="exampleSelect1">Example select</label>
//     <select class="form-control" id="exampleSelect1">
//       <option>1</option>
//       <option>2</option>
//       <option>3</option>
//       <option>4</option>
//       <option>5</option>
//     </select>
//   </div>
//   <div class="form-group">
//     <label for="exampleSelect2">Example multiple select</label>
//     <select multiple="" class="form-control" id="exampleSelect2">
//       <option>1</option>
//       <option>2</option>
//       <option>3</option>
//       <option>4</option>
//       <option>5</option>
//     </select>
//   </div>
//   <div class="form-group">
//     <label for="exampleTextarea">Example textarea</label>
//     <textarea class="form-control" id="exampleTextarea" rows="3"></textarea>
//   </div>
//   <div class="form-group">
//     <label for="exampleInputFile">File input</label>
//     <input type="file" class="form-control-file" id="exampleInputFile" aria-describedby="fileHelp">
//     <small id="fileHelp" class="form-text text-muted">This is some placeholder block-level help text for the above input. It's a bit lighter and easily wraps to a new line.</small>
//   </div>
//   <fieldset class="form-group">
//     <legend>Radio buttons</legend>
//     <div class="form-check">
//       <label class="form-check-label">
//         <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked="">
//         Option one is this and that—be sure to include why it's great
//       </label>
//     </div>
//     <div class="form-check">
//     <label class="form-check-label">
//         <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2">
//         Option two can be something else and selecting it will deselect option one
//       </label>
//     </div>
//     <div class="form-check disabled">
//     <label class="form-check-label">
//         <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios3" value="option3" disabled="">
//         Option three is disabled
//       </label>
//     </div>
//   </fieldset>
//   <div class="form-check">
//     <label class="form-check-label">
//       <input type="checkbox" class="form-check-input">
//       Check me out
//     </label>
//   </div>
//   <button type="submit" class="btn btn-primary">Submit</button>
// </form>
//
// Style guide: Forms.forms

form {
  font-size: .9em;
  color: $es-dk-gray;
}
legend, .legend {
  font-family: $font-family-sans-serif;
  color: $es-dk-gray;
  font-size: 28px;
  padding: 0;
  border: 0;
  margin-top: 1em;

  span {
    display: block;
  }
  a{
    color: $es-dk-gray;

    &:hover {
      text-decoration: none;
      color: $es-dk-gray
    }
  }
}
label {
  font-weight: 600;
}
.form-control {
  border: 2px solid $es-gray-03;
  height: 3em;

  &:hover, &:focus {
    border-color: $es-dk-gray;
    background-color: $white;
  }
}

//icons on form headers
fieldset  {
  .fa {
    &:before {
      background-color: $es-dk-gray;
      border-radius: 100px;
      color: #fff;
      display: inline-block;
      height: 50px;
      line-height: 40px;
      margin-right: .5em;
      padding: .25em;
      width: 50px;
      text-align: center;
    }
  }
}
.multipage-controls-list{
  .multipage-button{
    width: 100%;
    .multipage-link-previous{
      float: left;
    }
    .multipage-link-next{
      float: right;
      input[type='button'].btn.btn-danger{
        @extend .btn-danger;
      }
    }
  }
}

input[type="button"].btn {
  @extend .btn;
}

input[type='button'].btn-danger {
  @extend .btn-danger
}

.description {
  margin-bottom: 1em;
  font-size: 16px;

}
.label-description {
  margin-bottom: 1em;
}

//help block
.static-help, .help-block {
  color: $es-dk-gray;
  opacity: .8;
}
.help-block {
  display: none;
}

#edit-intro {
  font-size: 16px;
  color: #333333
}
.form-managed-file {
  @extend .col-sm-6;
}
//form errors
.has-error {
}

//specific form fields for user/register, user/login, user/password
.page-user-register, .page-user-login, .page-user-password{
  .nav-tabs {
    display: none;
  }
  h1 {
    border: 0;
    text-align: center;
  }
  #user-login{
      width: 600px;
      margin: 0 auto;
    #forgot-password-link{
      margin-top: -39.2px;
      z-index: 100;
      position: relative;
    }
    .btn{
      border-radius: 5px !important;
    }
  }
  @media (max-width: 650px){
    #user-login{
      width: auto;
      margin: 0 70px;
    }
  }
}
#user-register-form{
  background-color: #fff;

    #edit-actions {
    margin-left: 1em;
  }
}
.page-user-login {
  #edit-actions {
    margin-top: 1em;
  }
}
#user-login-form {
  ul {
    list-style: none;
    margin: 0 0 1em 0;
    padding: 0;
  }
}
#edit-field-address-und-0, .group-name, .group-contact-info, .group-account-group, .group-assemblies-group {
  legend {
    display: none;
  }
}
#edit-legal {
  margin: 1em;

  legend {
    margin-bottom: 0;
  }
}
.group-general-information, .group-credentials {
  legend {
    padding-left: .5em;
  }
}
.form-item-pass-pass1 {
  .label-danger, .progress, .glyphicon {
    display: none !important;
  }
}
button {
  .glyphicon {
    display: none;
  }
}
.password-help{
  clear: both;
}
//feedback form
#block-feedback-form{
  bottom: 0;

  label {
    font-size: .85em;
  }

  div.feedback-help {
    font-size: .85em;
  }
  span.feedback-link{
    @extend .btn;
    @extend .btn-warning;
    color: #fff;
    opacity: .9;
    padding: .5em;
    font-family: $font-family-sans-serif;
  }
}
#backtotop {
  bottom: 95px;
  z-index: 100;
}

//Marketo Form for Downloadable Content
.mktoForm {
  margin-top: 15px !important;
  width: auto !important;

  .mktoFormRow .mktoOffset {
    width: 0 !important;
  }
  .mktoFormRow .mktoHasWidth, .mktoForm.mktoHasWidth.mktoLayoutLeft {
    width: 100% !important;
  }
  .mktoButtonWrap {
    margin-left: 0 !important;
  }
  .mktoRequiredField .mktoAsterix {
    float: none !important;
    display: inline-block !important;
  }

  .mktoLabel {
    font-size: 16px;
  }
}
.form-type-password{
  .control-label {
    width: 100%;
  }
  .label{
    display: block;
    float: right;
    margin-top: .5em;
  }
}

#covid19-entityform-edit-form{
  width: 60%;
  margin: 50px auto;
}

// Marketo input width fix
.sidebar .container {
  max-width: 100%
}
.mktoForm .mktoFieldWrap {
    float: none !important;
}
.mktoFieldDescriptor.mktoFormCol, .mktoFieldWrap.mktoRequiredField {
  width: 100%;
}

// To remove
.entityform.entitytype-contact_us-form {
  .form-actions {
    padding: 0;

    .btn {
      border-radius: 5px;
    }
  }
}

// Green line basic form
.green-line-form-container{
  form{
    border-top: 10px solid $es-green;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  fieldset{
    background: transparent;
  }
}

// textarea resize override
textarea.form-control {
  resize: vertical;
}

// Marketo input styling fix
.mktoForm input[type=text], .mktoForm input[type=url], .mktoForm input[type=email], .mktoForm input[type=tel], .mktoForm input[type=number], .mktoForm input[type=date], .mktoForm select.mktoField, .mktoForm textarea.mktoField {
  border: 2px solid #ccc;
  height: 3em;
}

// Marketo button styling override
.mktoForm .mktoButtonWrap.mktoRound .mktoButton, .mktoForm .mktoButtonWrap.mktoSimple .mktoButton, .mktoForm .mktoButtonWrap.mktoShadow .mktoButton {
  border: 1px solid #406bab !important;
  border-radius: 5px !important;
  background-image: none !important;
  background-color: $es-blue !important;
  padding: 6px 12px !important;
  font-size: 16px !important;
  color: #fff !important;
}
.mktoForm .mktoButtonWrap.mktoRound .mktoButton:hover, .mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover, .mktoForm .mktoButtonWrap.mktoShadow .mktoButton:hover {
  background-color: #395f99 !important;
  border-color: #375b91 !important;
}
.mktoButtonWrap.mktoRound:focus, .mktoForm .mktoButtonWrap.mktoRound .mktoButton:focus, , .mktoForm .mktoButtonWrap.mktoSimple .mktoButton:focus, .mktoForm .mktoButtonWrap.mktoShadow .mktoButton:focus {
  outline: 5px auto #4a77bb !important;
  border-color: #243d61 !important;
}

// Date picker overrides
fieldset.date-combo .container-inline-date .date-padding {
  padding: 0px;
}
