aside.sb-right {
  padding-top: 1.75em;
}
.region-sidebar-second {
  .block {
    background-color: #fff;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid #dadada;

    .fa.block-head {
      background-color: $es-lt-blue;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      font-size: 1.3em;
      display: block;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
    }
    h3, h2 {
      margin: 1em 0;
    }
    h2 {
      line-height: 1.5;
      text-align: left;
      font-size: 19px;
      font-weight: 600;
      font-family: $font-family-sans-serif;
      margin-top: .5em;
    }
    .aske-suggestion {
       h3 {
         font-size: 22px;
         margin-top: 1.5em;
         text-align: left;
         a {
           font-family: $font-family-sans-serif;
         }
       }
    }
    .btn{
      white-space: pre-line;
    }
  }
  ul.icon {
    list-style-type: none;
  }
  li {
    text-indent: 1.4em;
    margin: 0.5em 2em;

    &:before {
      font-family: FontAwesome;
      content: "\e800";
      float: left;
      width: 1.4em;
      color: #8c8;
    }
  }
  .mlt-block{
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        text-indent: 0;
        padding: 0;
        margin: 0 0 1em 0;
        &:before {
          display: none;
        }
      }
    }
  }
}
.region-content, .region-sidebar-second {
  .icon-block{
    margin: 3em auto 1em auto;
    position: relative;
    text-align: center;
    background-color: #fff;
    margin-bottom: 1em;
    padding: 1em;
    border: 1px solid #dadada;
    i{
      background-color: #fff;
      border: $es-green solid 2px;
      border-radius: 50px;
      color: rgba(68, 74, 80, .7);
      height: 50px;
      line-height: 47px;
      position: absolute;
      text-align: center;
      width: 50px;
      &.fa-2x{
        margin: -1.5em 0 1.5em -.75em;
      }
      &.fa-1x{
        margin: -2.75em 0 1.5em -1.5em;
      }
    }
  }
}
#block-multiblock-2 {
   background-color: #f1f1f1;
   border: 1px solid #dadada;
   padding: .5em;
   .fa {
    margin: -1.25em 0 1em -.75em;
   }
}
section#block-es-iir-es-iir-sticky-nav.affix {
    top: 125px;
}
// EDesign 2020 Homepage Banners
.progress-tracker-block{
  margin: 1em;
  h2{
    color: #545f64;
  }
  h3{
    color: #545f64;
    font-weight: 200;
    margin-bottom: 1em;
  }
}

.content-block{
  //General styles
  padding: 45px 0;
  overflow: hidden;
  position: relative;
  h2, h3, h4, p, a, .subheading{
    line-height: 1.618;
  }
  h2, h3, a.link-title{
    padding-bottom: 10px;
  }
  h2{
    margin-top: 0px;
    &:after {
      content: '';
      display: block;
      margin-top: 15px;
      width: 85px;
      height: 4px;
      background-color: $es-green;
    }
  }
  h3{
    font-size: 3rem;
    margin-top: 0px;
  }
  h4{
    font-size: 2.5rem;
  }
  p.subheading{
    font-size: 2.1rem;
  }
  p{
    font-size: 1.8rem;
  }
  a.link-title{
    display: block;
    font-size: 2rem;
    font-weight: bold;
  }
  .btn-square{
    border-radius: 5px;
  }
  .vertical-line{
    border-left: solid $es-green;
  }
  ul.green-square-bullets{
    list-style-type: none;
    line-height: 1.9;
    margin-bottom: 15px;
    li{
      text-indent: -0.9em;
      &:before {
        content: "\25A0";
        color: $es-green;
        display: inline-block;
        width: 1em;
      }
    }
  }
  .testimonial-content{
    padding: 1.5em 1em 1.5em 2.5em;
      &:before{
        content: "\201C";
        display: inline-block;
        position: absolute;
        margin-left: -.65em;
        font-size: 3em;
        font-family: $font-family-sans-serif;
        color: $es-green;
      }
    }
  .circle-icon-border{
    border: solid 3px $es-green;
    border-radius: 50px;
    font-size: 10px;
    margin-left: auto;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    &.light{
      background-color: #fff;
      color: $es-dk-gray;
    }
    &.dark{
      background-color: #000;
      color: #fff;
    }
    i{
      margin: 0px;
    }
    p{
      line-height: 2.3;
      font-family: $font-family-sans-serif;
      font-size: 20px;
    }
    @media(max-width: 766px){
      margin-right: auto;
      margin-left: 0;
    }
  }
  .body-group{
    margin: 10px 0;
  }
  .description-only{
    margin: 10px 0 0 10px;
  }
  .img-corner{
    position: absolute;
    width: 500px;
    height: auto;
    z-index: -1;
    &.top-left{
      top: 0;
      left: 0;
    }
    &.top-right{
      top: 0;
      right: 0;
    }
    &.bottom-right{
      bottom: 0;
      right: 0;
    }
    &.bottom-left{
      bottom: 0;
      left: 0;
    }
    @media (max-width: 850px) {
      width: 300px;
    }
  }
  .triangle-container {
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 600px;
    height: 600px;
    .triangle {
      overflow: hidden;
      transform: skewX(-55.98deg);
      transform-origin: 0 100%;
      height: 100%;
      img {
        transition: .5s;
        transform: skewX(55.98deg);
        transform-origin: inherit;
        height: 30%;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  .card-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 30px;
    a.content-card{
      width: 340px;
      margin: 10px;
    }
  }
  a.content-card {
    .lead-image-thumb {
      position: relative;
      width: 100%;
      height: 300px;
      padding: 1em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      overflow: hidden;
      .article-info {
        z-index: 2;
        h3, h4, p, a {
          color: #fff;
          text-decoration: none;
        }
        h3 {
          font-size: 25px;
          line-height: 40px;
          &:after{
            display: none;
          }
        }
        .card-title{
          font-size: 20px;
          line-height: 30px;
        }
      }
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 0;
        transition: all 0.5s ease-out;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  @supports ( object-fit: cover ) {
    .lead-image-thumb img {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transform: none;
    }
    &:hover, &:focus {
      img{
        -ms-transform: scale(2);
      }
    }
  }
  &:hover, &:focus {
      text-decoration: none;
      img {
        transform: scale(1.05);
      }
    }
  }
  //Component specific styles
  &.large-body{
    .vertical-line{
      .title{
        &:after{
          display: none;
        }
      }
    }
  }
  &.small-body{
    .title{
      &:after{
        display: none;
      }
    }
    .body-group{
      padding-left: 0px;
      padding-right: 70px;
    }
  }
  &.icon-body{
    .row{
      margin-bottom: 15px;
    }
  }

  .body-group {
    div:first-child {
      padding-left: 0;
      .circle-icon-border {
        margin-left: 0;
        @media (max-width: 990px) {
          margin-bottom: 15px;
        }
      }
    }
    div:last-child {
      padding-left: 0;
      margin-left: -15px;
      @media (max-width: 990px) {
        margin-left: 0;
      }
    }
  }

  &.icon-list {
    .col-md-4 {
      > .body-group {
        div:not(.circle-icon-border):last-child {
          padding-left: 15px;
          margin-left: auto;
          @media (max-width: 990px) {
            padding-left: 0;
          }
        }
      }
    }
  }

  &.icon-link{
    .icon-link-item{
      margin-top: 15px;
      .circle-icon-border{
        background-color: #fff;
        border-color: $es-blue;
      }
      a{
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
      }
    }
  }
  &.icon-body-links{
    li{
      list-style: none;
      margin: 15px 0;
      a{
        font-weight: bold;
        .circle-icon-border{
          display: inline-block;
          &.light{
            border-color: $es-blue;
          }
        }
      }
    }
  }
  &.video-full{
    padding: 0px;
    .body-content{
      margin-top: 45px;
    }
  }
  .services-card.card{
    min-height: 400px;
    cursor: default;
    position: relative;
    .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      @media (max-width: 991px) {
        flex-direction: column;
      }
      .frame {
        position: relative;
        min-height: 400px;
        overflow: hidden;
        align-self: stretch;
        @media (max-width: 991px) {
          min-height: auto;
          padding: 0;
        }
      }
    }
    @media (max-width: 990px) {
      display: block;
    }
    &:hover{
      box-shadow: 0px 0px 10px 0px rgba(50,50,50,0.1);
    }
    .card-content{
      padding: 25px;
    }
    img{
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      @media (max-width: 990px) {
        top: auto;
        position: relative;
      }
    }
    a {
      width: auto;
    }
  }
  .callout-grid {
    .lead-image-thumb {
      margin-bottom: 2em;
      min-height: 400px;
    }
  }
}

// DSMi landing page
.dsm-launcher-page {
  a.card-wrapper {
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  .horizontal-card {
    border-bottom: 1px solid #dadada;
    display: flex;
    align-items: center;
    padding: 2em 1em;
    color: #444A50;
    &:hover, &:focus {
      text-decoration: none;
    }
    .img-wrap {
      .field-feature-image {
        max-width: 150px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .title {
      font-family: $font-family-sans-serif;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
