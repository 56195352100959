.front.logged-in {
  h1 {
    border: 0;
    padding: 0;
  }
  //filter buttons
  .form-item-field-front-page-filters {
    width: 100%;
    position: absolute;
    top: 0;
    margin-top: -9em;
    text-align: center;

    label {
      text-align: center;
    }
    select {
      width: 60%;
      margin: 0 auto;
    }
  }
  .fav-add-msg, .fav-remove-msg {
    display: none;
  }
  // Filter buttons ONLY for favorites view
  .view-id-content_feeds.view-display-id-my_favorites {
    .views-widget-filter-my_favorites_sort_button {
      float: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 50%;
      text-align: center;
      .button-group.open {
        position: relative;
        display: inline-block;
      }
      label.control-label {
        display: block;
      }
      ul.dropdown-menu {
        float: none;
        left: auto;
      }
    }
  }
  h2.ribbon {
    margin-top: 5em;
  }
  h4 {
    margin-top: 1.5em;
    .fa {
      margin-right: .5em;
    }
  }
  .region-sidebar-second {
    .block {
      background-color: transparent;
      border: 0;
      margin: 0;
    }
    h2.block-title {
      @extend h3;
    }
    .block-training-page {
      background-color: #f4f4f4;
      border-top: 3px solid #6c9cb2;
      margin-bottom: 1em;
      padding: 1em;
    }
  }
}
//user welcome block
#block-es-utilities-es-user-welcome {
  margin-top: 1.5em;
  margin-left: 1em;

  h1 {
    margin-bottom: 0;
  }
}
//main content feed
.view-content-feeds, .main-content-feed  {
  .row {
    margin: 1em 0;
  }
}
.view-content-feeds {
  position: relative;

  .row {
    border-bottom: 1px $es-lt-gray solid;
    padding-bottom: 1em;
  }
  h3 {
    margin-bottom: 0;
  }
  .subtitle {
    font-size: 1.2em;
    color: $es-green;
  }
  .author {
    font-size: 1.1em;
    font-weight: normal;
    margin: .5em 0 0 0;
  }
  .pub-date {
    color: #9c9c9c;
    margin-bottom: .5em;
    display: block;
  }
  .text-muted {
    color: #9c9c9c;
  }
  .feature-image-wrapper {
    max-height: 200px;
    overflow: hidden;
    padding: 0;
    text-align: center;
  }
  a.fa:hover, a.fa:focus {
    text-decoration: none;
  }
  a.fa.flag-action {
    color: lighten($es-green, 30%)
  }
}
//essential content styling
.es-essential-view-row {
  padding: .25em 1em;
  margin: 1em 0;
  border-top: 3px solid $es-blue;
  background: #e7ebf2;

  .row {
    border: 0;
    padding: 0;
    margin: 1em 0 0 0;
  }
}
//featured content block
.view-featured-content-plain {
  .views-row {
    padding-top: .5em;
    //border-top: 1px #c6c6c5 solid;
    margin-top: .75em;
  }
  .fa {
    background-color: #6c9cb2;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    font-size: 1.3em;
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
  }
}
#es-newsletters-form {
  fieldset{
    margin-bottom: 1em;
  }
  .help-block {
    display: block !important;
    margin-bottom: 1em;
    margin-top: 0;
    font-size: .95em;
    color: #545f64;
    font-style: italic;
  }
}
//ask e in the member portal
#es-ajax-main-container {
  h3 {
    margin-top: 0;
  }
  .views-field-title {
    display: inline;
    h3 {
      display: inline;
    }
  }
  .aske-status {
    .fa {
      font-size: 1.5em;
    }
  }
}
.view-user-ask-e {
  h2 {
    border-bottom: 1px $es-lt-gray solid;
    padding-bottom: .5em;
    margin-bottom: .5em;
  }
}
.front.logged-in {
  #es-ajax-main-container, .view-display-id-newsletters {
    .ribbon {
      margin-top: 2em;
    }
  }
}
