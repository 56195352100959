//Your Results Tab Section
.cx-maturity-intro-paragraph {
   margin: 2em;
}

.cx-maturity-assessment-summary-table {
   .panel-body {
      a:first-of-type {
         margin-top: 1em;
      }
   }
}

.cx-maturity-industry-scores {
   border-bottom: solid 3px black;
}

.cx-maturity-user-result {
   cursor: pointer;
}

.cx-maturity-comparison-options {
   text-align: center;
}

.cx-maturity-toggle {
   margin-right: .5em;
   margin-bottom: 1em;
}

//Take Assessment Tab Section
#block-entityform-block-cx-maturity-assessment {
   .form-actions {
      float: right;
      margin-top: 1em;
   }
}

#cx-maturity-clear-form {
   margin-top: .5em;
}

//Recommended Content Tab Section
.view-cx-maturity-assessment-recommended-content {
   .row {
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 1em;
      margin-top: 1em;
      .subtitle {
         margin: 0;
         border-bottom: none;
         font-size: 1.2em;
         a {
            color: #79ac42;
            text-decoration: none;
         }
         a:hover,
         a:focus {
            color: #52752d;
            text-decoration: underline;
         }
      }
   }
   .author {
      margin: 0;
      font-size: 1.1em;
   }
}

//Past Assessments Tab Section
.field-group-div {
   h3:first-child {
      color: #4a77bb;
      border-bottom: solid 1px #d1d1d1;
      padding-bottom: .25em;
   }
}

.cx-maturity-field {
   margin-bottom: 1em;
   p {
      margin: 0;
      font-size: 1.25em;
   }
   h3:first-child {
      margin: 0;
   }
   div:first-of-type {
      color: #79ac42;
      margin-top: .25em;
      margin-left: 1em;
      font-style: italic;
      font-size: 1.15em;
   }
}
