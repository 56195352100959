
.navbar.navbar-default.website-design-center-wrap {
  background-color: $es-blue;
  -webkit-box-shadow: -3px -3px 3px 0 rgba(0, 0, 0, 0.19), 3px 0 3px 0 rgba(0, 0, 0, 0.19), 0 3px 3px 0 rgba(0, 0, 0, 0.23);
  box-shadow: -3px -3px 3px 0 rgba(0, 0, 0, 0.19), 3px 0 3px 0 rgba(0, 0, 0, 0.19), 0 3px 3px 0 rgba(0, 0, 0, 0.23);
  height: inherit;
  margin-top: 5px;
  padding: 0;
  .navbar-nav {
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 90px;
    justify-content: flex-end;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
    width: 100%;
    li.active{
      font-weight: bold;
      text-decoration: underline;
    }
    a {
      color: #fff;
      font-size: 18px;
      &:hover {
        color: #fff;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
a[href="#public-content"], a[href="#secure-content"] {
  line-height: 2em;
}
#design-archive.well.well-lg {
  h2{
    text-align: center;
    margin-top: .25em;
  }
}
#public-content {
  .well-sm {
    padding: 1em;
  }
}
.view-id-subsite_menu_item {
  .well-sm {
    padding: 1em;
  }
  .row {
    border-bottom: 1px solid #dadada;
    padding: 1em 0 0 0;
    &:first-child {
      padding: 0;
    }
    &:last-child {
      border: 0;
    }
    .img-overlay-icon {
      background-color: #fff;
      margin-bottom: 1em;
      // max-width: 0;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .field-abstract {
      padding-bottom: 1em;
    }
    @media (max-width: 767px) {
      .img-overlay-icon {
        height: 175px;
        max-width: 100%;
        .overlay {
          &:hover {
            margin: 0 auto;
            .fa {
              padding: 10% 45%;
            }
          }
        }
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
#website-design-center-nav{
   > .active > a:focus {
    background-color: transparent;
  }

  li {

  }
  a {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.website-design-center-logo {
  margin-top: 13px;
  margin-bottom: .5em;
  margin-left: 0;
  padding: 0;
  position: absolute;
  width: 260px;
  z-index: 99;
}

.es-tool-logo {
  margin-left: 5px;
  margin-right: 30px;
  display: inline-block;
}
.es-tool-logo .logo {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 115px;
}
.es-tool-logo .logo-text {
  border-top: 2px solid #79ac42;
  padding-top: 5px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #ffffff;
} 

.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
@media (max-width: 900px) {
  .website-design-center-logo {
    display: none;
  }
  .navbar.navbar-default.website-design-center-wrap {
    .navbar-nav {
      justify-content: space-evenly;
    }
  }
}
@media (max-width: 767px) {
  #website-design-center-nav{
    margin: 0;
  }
}
@media (max-width: 668px) {
  .navbar.navbar-default.website-design-center-wrap {
    .navbar-nav {
      a {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
