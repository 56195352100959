 //overall layout of the dashboard
.react-app-wrapper {
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  .Toastify__toast-body{
    color: $es-blue;
  }
  .react-app-body, .sidebar{
    // NOTE: Commenting out Float for temporary fix and will find other solutions for browser compatability
    // float: left;
  }
  .react-app-body {
    width: 100%;
    @media (max-width: 667px) {
      width: 100%;
    }
  }
  .sidebar {
    width: 25%;
    @media (max-width: 667px) {
      width: 100%;
    }
  }
  .header {
    background-image: url('/sites/all/themes/es_bootstrap/img/flatirons_bg_light_blue.jpg');
    background-position: right bottom 70px;
    background-attachment: fixed;
    background-size: cover;
    color: #888;
    display: block;
    line-height: .5em;
    margin: 0;
    min-height: 200px;
    padding: 2.5em 0 0 0;
    width: 100%;
    @media(max-width: 812px) and (min-width: 811px){
      background-attachment: inherit;
      background-position: center center;
    }
    h1 {
      color: #f8f7f7;
      font-family: $font-family-sans-serif;
      font-size: 40px;
      font-weight: 450;
      letter-spacing: .02em;
      margin: 0;
      }
      p {
        color: #f8f7f7;
        font-size: 20px;
        margin: 1em auto 0 .25em;
        letter-spacing: .02em;
        @media(max-width: 767px){
          line-height: 27px;
          margin-right: 0.5em;
        }
    }
    ul {
      padding-left: 25px;
    }
    .nav-disabled {
      pointer-events: none;
      cursor: default;
    }
  }
  .bar {
    background-color: #f1f1f1;
  }
  .sidebar {
    background-color: #fff;
    border-left: 1px #dadada solid;
    display: inline-block;
    text-align: center;
  }
  .nav {
    display: block;
    width: 100%;
  }
  .selector {
    background-color: transparent;
  }
  .flat-tabs {
    .fa {
      padding-right: .5em;
      display: inline-block;
    }
  }
  .btn {
    margin-bottom: .5em;
  }
  .content-loader {
    height: 0;
    padding-top: 100%;
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
    .select-drop-wrapper {
      line-height: 30px;
      position: relative;
      z-index: 1000;
    }
  }
  .error-body {
    height: 100%;
    display: grid;
    h3{
      line-height: 1.6;
      margin-bottom: 1em;
    }
    a{
      margin-top: 1em;
    }
    span{
      font-weight: 300;
    }
    .error-img{
      vertical-align: middle;
      width: 30%;
      margin-bottom: 1em;
    }
    .error-content-contact-block{
      margin: auto;
      text-align: center;
      width: 40%;
      margin-top: -8px;
      h3{
        margin: 0;
        padding-top: 0;
      }
    }
    .error-content-contact-block-slp{
      text-align: center;
      width: 40%;
      margin: -8px auto 2em auto;
      h3{
        margin: 0;
        padding-top: 0;
      }
    }
    .error-content{
      margin: auto;
      text-align: center;
      width: 40%;
    }
  }
  .blank-state-img {
    vertical-align: middle;
    margin: 1.5em 0 0 0;
    width: 75%;
    &.aske-img{
      width: 45%;
    }
  }
  .blank-state-img-full {
    vertical-align: middle;
    margin: 1.5em 0 0 0;
    width: 100%;
  }
  .blank-state-body {
    margin-top: 20px;
    height: 100%;
    display: grid;
    @media screen and (max-width: 770px){
      .blank-state-title{
        font-size: 23px;
        text-align: left;
      }
      .blank-state-img{
        width: 140%;
        padding-right: 4em;
      }
    }
  }
  .blank-state-content{
    margin: auto;
    text-align: center;
    width: 40%;
    &.aske-content{
      width: 65%;
    }
    h3{
      font-weight: 600;
      line-height: 1.6;
      margin-bottom: 1em;
    }
    a{
      margin-top: 1em;
    }
    span{
      font-weight: 300;
    }
  }
  .blank-state-line{
    border-top: 1px solid #a5a5a5;
    width: 30%;
  }
  .blank-state-calltoaction{
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    @media screen and (max-width: 1075px){
      grid-template-columns: 100%;
    }
  }
  .aske-text{
    margin: 0.5em 1.5em 0 1.5em;
    text-align: left;
    @media screen and (max-width: 1075px){
      margin: 0 0 1.5em 0;
    }
  }
}
.vert-card-wrapper {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  .status-icon-card {
    display: block;
    float: left;
    margin-right: .75em;
  }
  .round-img-card, .header-image-card, .full-image-expiration-card,
  .dark-header-image-card, .header-bio-card{
    .card {
      float: left;
      margin-right: .75em;
    }
  }
  .round-img-card, .header-image-card,
  .dark-header-image-card, .header-bio-card{
    .card {
      display: block;
    }
  }
  .full-image-expiration-card{
    .card {
      display: table;
    }
  }
}
.grid-wrapper {
  float: left;
  padding: 1em 1em 0 1em;
  width: 100%;
  &:after {
    clear: both;
    content: "";
    height: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
  }
  h3{
    border-bottom: 1px solid #dadada;
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: .5em;
    padding-top: .5em;
  }
}
.grid-wrapper-2080 {
  padding: 1em;
}
.col-wrapper-3070{
  display: block;
  float: left;
  width: 100%;
  .col-30 {
    float: left;
    width: 30%;
  }
  .col-70 {
    float: left;
    width: 70%;
  }
}

.contacts{
  float: left;
  overflow: hidden;
  width: 100%;
  color: #545f64;
  h3 {
    border-bottom: 1px solid #dadada;
    color: #545f64;
    font-weight: 600;
    padding: .5em;
    margin: 0;
  }
  .contact-em, .contact-sales {
    color: #545f64;
    display: block;
    float: left;
    padding: 0;
    position: relative;
    width: 50%;
    @media (max-width: 1140px) {
      width: 100%;
    }
  }
  .contact-sales {
    border-left: 1px solid #dadada;
  }
}
.col {
  float: left;
  &.contact {
    margin-left: .5em;
    width: calc(50% - .5em);
  }
  &.cta-block {
    height: 290px;
    margin: 0 auto;
    max-width: 600px;
  }
  &:after {
    clear: both;
    content: "";
    height: 0;
    margin: 0;  
    padding: 0;
    visibility: hidden;
  }
}
.cta-block {
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: .5em;
  position: relative;
  h3 {
    background-color: transparent;
    border-bottom: 0;
    border-radius: 0px;
    color: $es-dk-gray;
    font-weight: 600;
    margin-bottom: .5em;
    padding: .5em 0;
  }
  p{
    font-weight: 300;
    line-height: 1.64;
    font-size: 15px;
  }
  img {
    width: 50%;
  }
  .handshake-icon{
    width: 70%;
  }
  .circle-icon-texture-sm {
    background-color: #f1f1f1;
    background-image: none;
    border: 4px solid $es-dk-gray;
    a {
      background-color: transparent;
    }
    .fa {
      color: $es-dk-gray;
    }
  }
}
.cta-img-block {
  .circle-icon-texture-sm {
    a {
      line-height: 90px;
      width: auto;
    }
  }
}
.row-wrapper{
  margin-top: 0.6%;
  padding: 1em 1em 2em 2em;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  &:nth-child(even) {
    background-color: #f8f7f7;
    border-bottom: 1px solid #dadada;
    border-top: 1px solid #dadada;
  }
  &.no-carousel {
    background-color: #fff;
    margin-bottom: 1em;
    overflow: hidden;
    padding: 1em 1.75em 0 0;
    &:after {
      clear: both;
      content: "";
      height: 0;
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }
  &:nth-child(even) {
    background-color: #f8f7f7;
    border-bottom: 1px solid #dadada;
    border-top: 1px solid #dadada;
  }
  .row-count{
    color: #999999;
    font-size: 55%;
    margin-left: 0.3%;
  }
  .horz-wrapper {
    display: grid;
    float: none;
    grid-gap: 45px;
    grid-template-columns: 50%;
    width: inherit;
    justify-content: center;
    padding: 2em 1em 2em 1em;
  }
}

.row-title{
  margin: .75em 0 1em 0;
  font-size: 1.5em;
  font-weight: 600;
  @media(max-width: 600px){
    margin-bottom: 2em;
  }
}
.more-link{
  display: block;
}
.tab-link-container{
  display: block;
  .more-link,
  .more-link-stacked-cards{
    width: fit-content;
  }
}

@supports (display: grid) {
  .browse-grid-container{
    display: grid;
    justify-content: center;
  }
  .react-app-wrapper {
    grid-template-areas: "reactappbody reactappbody reactappbody reactappbody";
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    @media (max-width: 610px) {
      grid-template-areas: "reactappbody";
      display: grid;
      grid-template-columns: 100%;
    }
    .react-app-body {
      grid-area: reactappbody;
    }
    .header {
      display: grid;
      padding: 0;
      grid-template-columns: 25% 25% 25% 25%;
      grid-row: auto;
      grid-template-areas:
      "greeting greeting greeting greeting" "navigation navigation navigation navigation";
      grid-area: header;
      @media (max-width: 1400px) {
        grid-template-areas:"greeting greeting greeting"  "navigation navigation navigation";
        grid-template-columns: 33.3% 33.3% 33.3%;
      }
      @media (max-width: 1140px) {
        grid-template-areas:"greeting greeting"  "navigation navigation";
        grid-template-columns: 50% 50%;
      }
      @media (max-width: 1080px) {
        grid-template-areas: "greeting" "greeting" "navigation";
        grid-template-columns: 100%;
      }
    }
    .sidebar, .react-app-body {
      width: auto;
    }
    .header {
      padding-bottom: 0;
      .greeting {
        grid-area: greeting;
        display: inherit;
        padding-left: 2em;
        margin-bottom: 4em;
        margin-top: 3em;
      }
      .navigation{
        align-self: end;
        grid-area: navigation;
      }
    }
    h2{
      color: #fff;
      padding-left: 1em;
      font-weight: 600;
    }
    .selector{
      background-color: transparent;
    }
  }
  //contact in sidebar
  .contacts {
    grid-area: contacts;
    .contact-group {
      grid-template-areas: "contact-em" "contact-sales";
      grid-template-columns: 50% 50%;
      display: grid;
      @media (min-width: 1141px) {
        grid-template-areas:
        "contact-em contact-sales";
        grid-template-columns: 50% 50%;
      }
      @media (max-width: 1140px) {
        grid-template-areas:
        "contact-em contact-sales";
        grid-template-columns: 50% 50%;
      }
      @media (max-width: $screen-sm) {
        grid-template-areas:
        "contact-em" "contact-sales";
        grid-template-columns: 100%;
      }
      @media (max-width: $screen-xs) {
        grid-template-areas:
        "contact-em" "contact-sales";
        grid-template-columns: 100%;
      }
    }
    .contact-em {
      grid-area: contact-em;
    }
    .contact-sales {
      grid-area: contact-sales;
    }
    .contact-em, .contact-sales {
      display: grid;
      float: none;
      width: inherit;
    }
  }
  @media (max-width: $screen-sm) {
    .react-app-wrapper .card-container {
       grid-template-columns: repeat(1, 1fr);
       grid-template-rows: repeat(2, 100%);
     }
  }
  @media (max-width: $screen-xs) {
     .react-app-wrapper .card-container {
       grid-template-columns: repeat(1);
       grid-template-rows: repeat(2, 100%);
    }
  }
  .grid-wrapper {
    display: grid;
    float: none;
    grid-gap: 10px;
    grid-template-columns: 33.3% 33.3% 33.3%;
    width: inherit;
    @media (min-width: 993px) {
      grid-template-columns: 33.3% 33.3% 33.3%;
    }
    @media (max-width: 992px) {
      grid-template-columns: 100%;

    }
    @media (max-width: 768px) {
      grid-template-columns: 100%;
    }
    @media (max-width: 480px) {
      grid-template-columns: 100%;
    }
  }
  .horz-wrapper {
    display: grid;
    float: none;
    grid-gap: 35px;
    grid-template-columns: 50%;
    width: inherit;
    justify-content: center;
    padding: 2em 1em 2em 1em;
  }
  .vert-card-wrapper {
      position: relative;
      display: grid;
      float: none !important;
      grid-template-columns: 16.4% 16.4% 16.4% 16.4% 16.4% 16.4%;
      grid-gap: 4px;
      margin: 0 auto;
      overflow: inherit;
      .status-icon-card {
        max-width: 330px;
      }
      .round-img-card, .header-image-card, .full-image-expiration-card,
      .dark-header-image-card, .status-icon-card, .header-bio-card{
        .card {
          display: grid;
          float: none;
          max-width: 330px;
        }
     }
  }
  .round-img-card, .header-image-card, .full-image-expiration-card,
  .dark-header-image-card, .status-icon-card, .header-bio-card{
    .card {
      display: grid;
      float: none;
      max-width: 330px;
    }
  }
  .grid-wrapper-2080 {
    display: grid;
    float: none;
    grid-gap: 10px;
    grid-template-columns: 20% 80%;
  }
  .contacts {
    float: none;
  }
  .col {
    float: none;
    &.contact {
      width: inherit;
      height: inherit;
    }
    &.cta-block {
      width: inherit;
      height: inherit;
    }
    &:after {
      clear: both;
      content: "";
      height: 0;
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }
  .col-wrapper-5050{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .col-wrapper-4060{
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .col-40 {
    padding-right: 1em;
  }
  .col-wrapper-3070{
    display: grid;
    grid-template-columns: 30% 70%;
    @media (max-width: 1305px) {
      grid-template-columns: 40% 60%;
    }
    @media (max-width: 480px) {
      grid-template-columns: 100%;
    }
    .col-30 {
      width: 100%;
    }
    .col-70 {
      width: 100%;
    }
  }
  .col-wrapper-2080{
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .col-wrapper-1575{
    display: grid;
    grid-template-columns: 15% 75%;
  }
}
