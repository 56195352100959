.careers-hero{
	h1{
		margin-bottom: .5em;
		color: #ffffff;
	}
	h2{
		color: #ffffff;
		border-top: 1px solid #fff;
		padding-top: 1em;
	}
	background:linear-gradient(
									rgba(0, 0, 0, 0.15),
									rgba(0, 0, 0, 0.15)
	),url('/sites/all/themes/es_bootstrap/img/careers/ESource_Team_Photo.jpg');
	background-attachment: fixed;
	background-size:cover;
	height:500px;
}
@media screen and (max-width: 667px){
	.careers-hero{
		background-attachment: scroll;
		background-position: center;
	}
}
.careers-container{

		//Possibly delete after merge. Copying Amy's .intro consulting styling
		.intro{
			h1,h2{
				color: #fff;
			}
			h2{
				font-size: 28px;
				font-family: Helvetica;
			}
		}
		//End .intro Amy's styling

	.bg-lt-gray{
		background-color: #f1f1f1;
		margin-left: auto;
		margin-right: auto;
	}
	.bg-white{
		background-color: #FFFFFF;
	}

	// Why E Source
	.why-esource{
		padding: 0 0 3em 0;
		.employee-img-container{
			margin: 0 auto;
			div[class^="col-"]{
				margin: 1em 0 0 0;
				padding:0 10px;
				.img-mask{
					height: 170px;
					overflow: hidden;
				}
				@media screen and (max-width: 1399px){
					.img-mask{
						height: 140px;
					}
				}
				@media screen and (max-width: 1199px){
					.img-mask{
						height: 150px;
					}
				}
				@media screen and (max-width: 375px){
					.img-mask{
						// width: auto;
					}
				}
			}
		}
		@media screen and (max-width: 1399px){
			.employee-img-container{
				margin-top: 3em;
			}
		}
		@media screen and (max-width: 1199px){
			.employee-img-container{
				margin-top: 1.5em;
				div[class^="col-"]:nth-child(5),div[class^="col-"]:nth-child(6){
					display: none;
				}
			}
		}
		@media screen and (max-width: 1023px){
			.employee-img-container{
				margin-top: 3em;
			}
		}
		@media screen and (max-width: 992px){
			.employee-img-container{
				margin-top: 3em;
				div[class^="col-"]:nth-child(5){
					display: inline-block;
				}
			}
		}
		@media screen and (max-width: 767px){
			.employee-img-container{
				display: none;
			}
		}
	}

	// Job Postings
	.job-postings{
		padding: 0 15px 3em 15px;
		#block-aggregator-feed-1{
			margin: 0;
			padding: 0;
			.block-title, .more-link{
				display: none;
			}
			ul{
				padding: 0;
				margin-right: auto;
				margin-left: auto;
			}
			ul > li {
				text-decoration: none;
				list-style-type: none;
				a{
					@extend .horizontal-card;
					font-size: 24px;

					// importants to overule "@extend .horiztonal-card"
					display: block !important;
					padding: 1em !important;
					min-height: 100% !important;
					margin: 1em 0 0 0 !important;
					width: 75% !important;
					margin-left: auto !important;
					margin-right: auto !important;
				}
				a::after{
					font-family: FontAwesome;
					content: "\f08e";
					float: right;
				}
				a:hover{
					text-decoration: none !important;
				}
			}
			// Mobile Job Postings width
			@media screen and (max-width: 669px){
				ul > li > a {
					font-size: 20px;
					width: 100% !important;
				}
			}
		}
	}

	// Location
	.location{
		.bg-white{
			ul{
				list-style-type: none;
				font-weight: 300;
				font-size: 18px;
				margin: 1em 5em 2em 5em;
				padding: 0 1em;
				li{
					list-style-type: square;
					font-size: 20px;
					margin-left: 3em;
					margin-top: .5em;
					span{
						font-size: 14px;
						font-style: italic;
					}
				}
				li:first-child{
					list-style-type: none;
					font-weight: 500;
					margin-top: 0;
					margin-left: 0;
				}
			}
			@media screen and (max-width: 1399px){
				ul{
					margin: 1em 0 2em 0;
				}
			}
			@media screen and (max-width: 669px){
				ul{
					margin: 0;
					padding: 0 1em;
					li:first-child{
						margin: 0;
						padding: 0;
					}
					li{
						margin-left: 1.75em;
						font-size: 1em;
					}
				}
			}
			h2{
				margin-bottom: 1em;
			}
			.boulder-activities{
				margin: 1em;
				div[class^="col-"]{
					padding-bottom: 15px;
				}
				.zoom{
					a{
						font-family: Helvetica;
						text-decoration: none;
						color: #fff;
						font-weight: 200;
						img{
							height: auto;
							width: auto;
						}
					}
					.gradient-container{
						h3{
							font-size: 22px;
						}
					}
				}
				#explore-bolder-boulder:after{
					content: "Explore Bolder Boulder";
				}
				#explore-downtown:after{
					content: "Explore Pearl Street";
				}
				#explore-boulder-restaurants:after{
					content: "Explore Boulder Restaurants";
				}
				#explore-colorado-ski-resorts:after{
					content: "Explore Ski Resorts";
				}
				#explore-boulder-recreation:after{
					content: "Explore Boulder Trails";
				}
				#explore-boulder-breweries:after{
					content: "Explore Boulder Beers";
				}
				@media screen and (max-width: 1200px){
					.zoom > .gradient-container > h3{
						font-size: 18px;
					}
					div[id^="explore-"]:after{
						font-size: 14px;
					}
				}
				@media screen and (max-width: 1024px){
					.zoom > .gradient-container > h3{
						font-size: 14px;
					}
					div[id^="explore-"]:after{
						font-size: 14px;
					}
				}
				@media screen and (max-width: 667px){
					.zoom > .gradient-container > h3{
						font-size: 16px;
					}
					div[id^="explore-"]:after{
						font-size: 16px;
					}
				}
				@media screen and (max-width: 375px){
					.zoom > .gradient-container > h3{
						font-size: 12px;
					}
					div[id^="explore-"]:after{
						font-size: 14px;
					}
				}
			}
		}
	}
	// Benefits
	.careers-benefits{
		margin-bottom: 3em;
		.circle-icon-texture-sm{
			margin-top: 1em;
			a{
				cursor: default;
				pointer-events: none;
			}
		}
	}
}
