#contact-us.content-block{
  h2{
    font-weight: 500;
  }
  display: flex;
  padding: 0;
  margin-top: 0px;
  .contact-wrapper{
    padding: 0 90px;
    .contact{
      margin-bottom: 50px;
      }
    }
  .contact-us-form-container{
    overflow: hidden;
    position: relative;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      min-width: 100vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }
    @supports ( object-fit: cover ) {
      img {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transform: none;
      }
    }
    form{
      position: relative;
      z-index: 2;
      width: 99%;
      padding: 30px 50px;
      margin: 0 auto 3em;
      background-color: #fff;
    }
  }
  @media(max-width: 991px){
    display: block;
    .contact-wrapper{
      padding: 0 45px;
    }
    .contact-us-form-container{
      margin-top: 6em;
    }
  }
}

#contact-us-entityform-edit-form[action="/contact_customer_service"], .green-underline-form{
    padding: 30px 40px;
    border-top: 6px solid $es-green;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    @media (max-width: 992px){
      margin: 0px 100px;
    }
    @media (max-width: 768px){
      margin: 0px 35px;
    }
} 
