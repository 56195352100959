//svg on holiday card
.holiday-card {
  .look-back-header{
    background-image: url('../img/look-back-header.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
        background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 400px;
  }
  .snowflake-background{
    background-image: url('/sites/all/themes/es_bootstrap/img/snow-pattern.png');
  }
}
