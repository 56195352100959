// Table of Contents
//
// Markup:
//  <div class="toc">
//    <p>
//      <a href="#">TOC Item 1</a>
//    </p>
//    <p>
//      <a href="#">TOC Item 2</a>
//    </p>
//  </div>
//
// Style guide: Headings.heading_1
.toc {
  margin-bottom: 1em;

  p {
    margin: 0;
    padding: 0;
  }
  br {
    display: none;
  }
}