.accordion{
  border: .5px solid #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 30px 0;
  .collapse-accordion:not(:first-child){
    border-top: 1px solid #dadada;
  }
  .collapse-accordion{
    .accordion-header{
      border: none;
      border-right: 4px solid;
      background: none;
      color: $es-blue;
      width: 100%;
      text-align: left;
      cursor: pointer;
      display: block;
      font-weight: bold;
      padding: 1em 1.5em;
      transition: all 0.3s cubic-bezier(0.25,0.8,0.25,1);
      h2, h3{
        color: $es-blue;
        display: contents;
        font-size: 20px;
      }
      &:focus{
        outline-color: $es-blue;
      }
      &:hover{
        box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.20), 0 1px 2px rgba(0,0,0,0.60);
      }
      i{
        color: #444a50;
      }
    }
    .body-container{
      .accordion-body{
        background: #f6f6f6;
        border-top: 1px solid #dadada;
        padding: 30px;

      }
    }
  }
}
