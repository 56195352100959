
.selector {
  background-color: #fff;
}
//dashboard and slp tabs + mobile dropdown nav
.navigation {
  nav {
    ul{
      background: $white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      z-index: 7000;

      @media screen and (min-width: 768px){
        font-size: 1em;
        background: none;
        box-shadow: none;
        position: relative;
        z-index: auto;
      }
      &:focus {
        outline: none;
      }
      &.bx--tabs__nav--hidden {
        display: none;
        @media screen and (min-width: 768px) {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          flex-direction: row;
        }
      }
    }
    li {
      &:before {
        color: $white;
        font-family: 'FontAwesome';
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
      border: 0;
      display: inline-block;
      font-family: $font-family-sans-serif;
      line-height: 18px;
      list-style: none;
      outline: none;
      padding: .75em 0;
      text-align: center;
      text-rendering: inherit;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -webkit-font-smoothing: inherit;
      width: 190px;
      @media screen and (max-width: 1205px){
        font-size: 16px;
        width: 170px;
      }
      @media screen and (max-width: 1083px){
        font-size: 16px;
        width: 120px;
      }
      @media screen and (max-width: 768px){
        padding: .5em 1em;
        text-align: left;
        width: 100%;
      }
      @media screen and (min-width: 768px){
        background-color: rgba(0,0,0,0.5);
        padding: 1em .75em;
        &:hover{
          background-color: rgba(0, 0, 0, 0.7);
          border-top: 3px solid $es-blue;
        }
      }
      a {
        color: $white;
        font-size: 18px;
        border: 0;
        opacity: .8;
        padding: 1em 0 1em 0.5em;
        text-align: center;
        text-decoration: none;
        font-weight: 400;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        @media screen and (max-width: 768px){
          color: $es-dk-gray;
          font-size: 16px;
          line-height: 25px;
          opacity: 1;
          padding: .25em;
          padding: .25em;
        }
        &:focus{
          outline: none;
        }
      }
    }
    li.bx--tabs__nav-item--selected {
      &:before {
        color: $es-dk-gray;
        font-family: 'FontAwesome';
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
      background-color: $white;
      border-top: 4px $es-blue solid;
      @media screen and (max-width: 768px){
        border: 0;
      }
      a {
        color: $es-dk-gray;
        opacity: 1;
        @media screen and (max-width: 768px){
          opacity: .6;
        }
      }
    }
    div[role = 'listbox'] {
      align-items: center;
      background-color: #d9eaf1;
      border-top: 4px solid $es-blue;
      color: $es-dk-gray;
      cursor: pointer;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      font-size: 150%;
      font-weight: bold;
      height: 2.5em;
      justify-content: space-between;
      outline: none;
      padding: 0 1em;
      -webkit-box-align: center;
      -webkit-box-pack: justify;
      a {
        color: $es-dk-gray;
      }
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

  }
}

.nav-justified {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: center;
  margin: 1em 0;
  > li{
    display: inline-block;
    width: auto !important;
    background-color: $white;
    margin-right: 5px;
    transition: $float;
    border-radius: 0px;
  
    &.col-4 {
      width: 25%;
    }
    a{
      height: 100%;
      // line-height: 1.4;
      padding: 20px;
    }
    
    &:last-child {
      margin-right: 0px;
    }
    
    &:hover {
      box-shadow: 0px -10px 18px -5px rgba(0,0,0,0.25);
    }
  }
  @media (max-width: 768px) {
    > li, > li.col-4{
      margin: 5px;
      display: inline-block;
      width: 48%;
    }
  }
  @media (max-width: 600px) {
    > li, > li.col-4{
      width: 100%;
    }
  }
  > li > a{
    font-size: 18px !important;
    color: $es-blue;
    background-color: $white;
    border-radius: 0px;
    border-bottom: 6px $es-gray-04 solid;
    
    &:hover {
      border-bottom: 6px $es-dk-gray solid;
      color: $es-blue;
    }
  }
  > li.active > a, > li.active > a:hover,  > li.active > a:focus {
    background-color: $white;
    border-bottom: 6px $es-blue solid;
  }
}

#quicktabs-news_page > ul{
  margin: 1em 0;
}

.servicePanels {
  .tab-pane {
    display: block;
    height: 0;
    overflow: hidden;
  }
  .active {
     height: auto;
  }
}

.flat-tabs {
   .selectors .nav {
     margin: 0;
     padding: 0;
   }
   .selectors > .nav-pills > li.active > a, .selectors > .nav-pills > li.active > a:hover, .selectors > .nav-pills > li.active > a:focus {
     background-color: transparent;
     border-bottom: 3px solid #545f64;
     box-shadow: none;
   }
   .selectors .nav-pills a {
     width: 9%;
     line-height: 18px;
     font-size: 14px;
   }
   .fa {
     margin-right: 0;
     padding-bottom: .25em;
     display: block;
  }
}
//sub nav
.qt-subnav{
  ul.quicktabs-tabs.quicktabs-style-garland {
    background: #979797;
    height: 60px;
    padding: 18px;
    text-align: center;
    @media (max-width: 1100px){
      height: 80px;
    }
    @media (max-width: 630px){
      height: 120px;
    }

    > li {
      float: none;
      display: inline-block;
    }

    li.active {
      background-image: none;

      a{
        background-image: none;
        background-color: #fff;
        border-radius: 100px;
        color: #6c9cb2 !important;
        padding: 8px;
        text-decoration: none;
      }
    }
    a {
      font: normal 14px/19px Helvetica !important;
      padding: .5em;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .quicktabs-views-group {
    margin: 2em 0;
    border-bottom: 1px solid #dadada;
    padding-bottom: 2em;
    @media (max-width: 1200px){
      margin: 1em;
    }
    .field-content {
      h3{
        color: $es-blue;
        font-weight: 600;
      }
    }
  }
  .title-group{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    i.fa-lock.tooltip-wrapper{
      color: #fff;
      background-color: #f7931d;
      border-radius: 40px;
      opacity: .90;
      font-size: 1.25em;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      &:hover > .tooltip-content.left{
        visibility: visible;
      }
      .tooltip-content.left{
        background-color: #000;
        border-radius: 6px;
        color: #fff;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 15px;
        left: -200px;
        line-height: 20px;
        width: 200px;
        padding: 10px;
        position: absolute;
        text-align: center;
        top: -25px;
        visibility: hidden;
        z-index: 1;
        &::after{
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent #555;
          content: "";
          left: 100%;
          margin-top: -5px;
          position: absolute;
          top: 50%;
        }
      }
      @media screen and (max-width: 768px){
        .tooltip-content.left{
          left: 50px;
          &::after{
            right: 100%;
            border-color: transparent #555 transparent transparent;
            left: auto;
          }
        }
      }
    }
    a{
      width: 100%;
      h3{
        color: $es-blue;
        font-family: $font-family-sans-serif;
        margin: 0;
        line-height: 1.4;
      }
    }
    a:hover{
      h3{
        color: $es-dk-blue;
      }
    }
  }
  @media (min-width: 768px){
    .quicktabs_main {
        width: 688px;
    }
  }
  @media (min-width: 992px){
    .quicktabs_main {
        width: 880px;
    }
  }
  @media (min-width: 1200px){
    .quicktabs_main {
      width: 1000px;
      margin: 80px auto;
    }
  }
}
@media (max-width: 768px) {
  #block-es-omni-es-omni-hours-op, #block-es-omni-es-omni-use-of-channels {
    .legend-bot-right {
      position: relative;
      top: 3em;
    }
  }
  .flat-tabs {
    .selectors .nav-pills a {
      width: 100%;
      border-radius: 0;
    }
    .fa {
     display: inline;
     padding-right: .5em;
    }
  }
}
.nav-justified{
  .tab-pane {
    padding-top: 1.5em;
  }
}
