
#admin-menu,  #admin-menu .dropdown{
  z-index: 9999;
}
header {
  .search-bar {
    position: absolute;
    top: 0;
    right: 30px;
  }
  #es-search-search-block-form{
    .form-text {
      background-image: url('/sites/all/themes/es_bootstrap/img/svg/search.svg');
      background-repeat: no-repeat;
      background-position: right;
      background-color: #f1f1f1;
      border: 3px solid #9b9b9b;
      border-radius: 20px;
      box-shadow: none;
      color: black;
      cursor: text;
      height: 40px;
      opacity: .8;
      outline: none;
      padding-left: 15px;
      transition: width 0.6s cubic-bezier(0,1.22,.66,1.39), border-radius 0.6s, background 0.6s;
      width: 275px;
      @media only screen and (min-width: 768px) and (max-width: 1275px){
        opacity: 1;
        width: 40px;
      }
      &::placeholder {
        color: #858585;
        @media only screen and (min-width: 768px) and (max-width: 1275px){
          visibility: hidden;
          }
      }
      &:focus {
        background-image: none;
        border: 3px #858585 solid;
        width: 300px;
        &::placeholder {
          @media only screen and (min-width: 768px) and (max-width: 1275px){
            visibility: visible;
          }
        }
      }
      & + label .search-icon {
        color: #fff;
      }
      & + label{
        max-width: 0;
      }
      & + label .search-icon {
        color: #fff;
        transition: all 0.6s;
      }
    }
  }
}

// .front.not-logged-in{
  // .navbar {
    // background: transparent;
    // box-shadow: none;
//
    // nav > ul > li > a {
      // color: #fff;
    // }
    // nav > ul > li:hover > a {
      // color: #fff;
    // }
    // .navbar-collapse {
      // margin-left: 1em;
    // }
  // }
  // .navbar.remove-padding {
    // background: #fff;
    // -webkit-box-shadow: -3px -3px 3px 0 rgba(0,0,0,0.19), 3px 0 3px 0 rgba(0,0,0,0.19), 0 3px 3px 0 rgba(0,0,0,0.23);
    // box-shadow: -3px -3px 3px 0 rgba(0,0,0,0.19), 3px 0 3px 0 rgba(0,0,0,0.19), 0 3px 3px 0 rgba(0,0,0,0.23);
    // nav > ul > li > a {
      // color: $es-dk-gray;
    // }
    // .search-box {
      // border-color: $es-dk-gray;
      // & + label .search-icon {
        // color: $es-dk-gray;
      // }
    // }
    // .navbar-collapse {
      // margin-left: 170px;
    // }
  // }
// }
.navbar-default {
  .navbar-toggle {
    background-color: $es-green;
    .icon-bar {
      background-color: $white;
    }
    &:hover {
      background-color: $es-dk-green;
    }
  }
}

//This class contains the entire top navigation section

//.navbar {
//  background: #fff;
//  border: 0;
//  height: 80px;
//  opacity: .98;
//  font-family: Helvetica, Arial, sans-serif;
//  font-size: 14px;
//  font-weight: 400;
//  padding: 0 1.5em;
//  text-transform: uppercase;
//  -webkit-transition: background 2s;
//  transition: background 2s;
//
//  h3{
//    font-weight: 500;
//  }
//
//  //e logo styling
//  .navbar-brand {
//    height: auto;
//    width: 130px;
//    padding: 0;
//    line-height: 0;
//    margin-right: 30px;
//  }
//  .esource-logo .e{
//    fill: $white;
//  }
//
//  .navbar-header {
//    nav {
//      background: transparent;
//      font-size: 0;
//      position: relative;
//      padding-top: 1em;
//    }
//    h3{
//      font-size: 18px;
//    }
//  }
//
//  nav > ul{
//    list-style: none;
//  }
//  nav > ul > li {
//    display: inline-block;
//    font-size: 14px;
//    padding: 5px 15px 0px 15px;
//    position: relative;
//    list-style: none;
//  }
//  nav > ul > li > a {
//    display: block;
//    font-family: Helvetica, Arial, sans-serif;
//    font-weight: 400;
//    position: relative;
//    padding: 20px 0 0 0;
//    border-bottom: 3px solid transparent;
//    text-decoration: none;
//    font-size: inherit;
//    text-rendering: inherit;
//    -webkit-font-smoothing: inherit;
//
//    &:before {
//      font-family: FontAwesome;
//      font-size: inherit;
//      text-rendering: auto;
//      -webkit-font-smoothing: antialiased;
//    }
//  }
//  nav > ul > li:hover > a {
//    color: $es-dk-gray;
//    border-bottom: 3px solid $es-green;
//    text-decoration:  none;
//  }
//
//  .droppable {
//    position: static;
//  }
//
//  .droppable > a:after {
//    content: "\f107";
//    font-family: FontAwesome;
//    font-size: 12px;
//    padding-left: 6px;
//    position: relative;
//    top: -1px;
//  }
//
//  .droppable:hover .mega-menu {
//    display: block;
//  }
//
//  // ----- Mega-menu navigation styling ----
//  .mega-menu {
//    background: #fff;
//    border-left: 1px #dadada solid;
//    border-right: 1px #dadada solid;
//    border-bottom: 1px #dadada solid;
//    display: none;
//    position: absolute;
//    text-align: left;
//
//    h3 {
//      color: #444;
//      text-transform: capitalize;
//      font-size: 18px;
//      font-weight: 300;
//    }
//    ul {
//      float: left;
//      margin-bottom: 20px;
//      margin-right: 40px;
//      width: 205px;
//      list-style: none;
//      padding-left: 1em;
//      padding-right: 1em;
//      &:first-child{
//        padding-right: 0;
//      }
//      &:last-child {
//        margin-right: 0;
//      }
//    }
//    a {
//      border-top: 1px solid #ddd;
//      line-height: 1.25em;
//      text-transform: none;
//      color: $es-blue;
//      display: block;
//      padding: 10px 0;
//      &:hover {
//        color: #325486;
//        text-decoration: underline;
//      }
//    }
//  }
//
//  //The remove-padding class is added to the navigation when the user scrolls down
//  &.remove-padding {
//    background: #fff;
//    color: $es-dk-gray;
//    border-bottom: 1px solid #e2e2e2;
//
//    .navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus {
//      background-color: $es-lt-blue;
//      color: #fff;
//    }
//    .navbar-collapse{
//      margin-left: 170px;
//    }
//    nav > ul > li > a {
//      color: $es-dk-gray;
//    }
//    .dropdown-menu {
//      background: $es-lt-blue;
//      border-bottom: 4px $es-dk-gray solid;
//    }
//    .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
//      background-color: #598193;
//    }
//    a.fa:before {
//      background: $es-dk-gray;
//      border-radius: 50px;
//      color: #fff;
//      margin-right: .5em;
//      padding: 1em;
//      width: 50px;
//      height: 50px;
//    }
//
//    nav > ul > li > a:hover {
//      border-bottom: 3px solid $es-green;
//      text-decoration:  none;
//    }
//    nav{
//      padding: 0;
//    }
//  }// .remove-padding
//  .navbar-nav > li > a{
//    color: #fff;
//    padding: 17px 14px 30px 14px;
//
//    &:hover{
//      color: #f1f1f1;
//      background-color: transparent;
//    }
//  }
//  .navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .open > a:focus, .nav .open > a, .nav .open > a:hover, .nav .open > a:focus{
//    background-color: transparent;
//  }
//  .navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .active > a:focus {
//    background-color: #000;
//  }
//  .navbar-nav > .open > a, .navbar-nav > .open > a:hover, .navbar-nav > .open > a:focus, .navbar-nav > .active > a, .navbar-nav > .active > a:focus{
//    color: $es-blue;
//  }
//  .navbar-nav {
//    margin-left: 2em;
//    padding-left: 1.5em;
//    margin-top: .75em;
//    text-transform: none;
//  }
//  &.remove-padding {
//    .navbar-nav > li > a{
//      color: $es-blue;
//
//      &:hover{
//        background-color: transparent;
//        color: $es-dk-blue;
//      }
//    }
//  }
//
//  //navbar search styles
//  .block-es-search {
//    margin-left: 50px;
//    margin-top: 1.5em;
//
//    input {
//      width: 400px;
//    }
//    .form-submit {
//      display: none;
//    }
//  }
//}// .navbar
// .front.not-logged-in {
  // .navbar {
    // .navbar-brand {
      // display: none;
      // @media (max-width: 767px) {
        // display: block;
      // }
      // img {
        // background-size: 130px 100px;
        // height: 80px;
      // }
    // }
    // .navbar-collapse.collapse.in {
      // background-color: transparent;
    // }
    // &.remove-padding {
      // .navbar-brand {
        // display: block;
      // }
    // }
  // }
// }
.inline-navigation {
  a {
    padding-right: 1em;
    @media(max-width: 991px) {
      display: block;
    }
  }
  @media(max-width: 768px) {
    margin-bottom: .5em;
  }
}
.adminimal-menu.logged-in {
  .navbar {
    margin-top: 32px;
  }
}
.page-node, .logged-in, .page-user, .not-logged-in, .node-type-service-landing-pages{
  .navbar{
    background: #fff;
    -webkit-box-shadow: -3px -3px 3px 0 rgba(0,0,0,0.19), 3px 0 3px 0 rgba(0,0,0,0.19), 0 3px 3px 0 rgba(0,0,0,0.23);
    box-shadow: -3px -3px 3px 0 rgba(0,0,0,0.19), 3px 0 3px 0 rgba(0,0,0,0.19), 0 3px 3px 0 rgba(0,0,0,0.23);
    padding: 0;
    nav > ul > li > a {
      color: $es-dk-gray;
    }
    nav > ul > li:hover > a {
      color: $es-dk-gray;
    }
    .navbar-collapse {
      margin-left: 170px;
    }
    .logo-source {
      path {
        fill: #000;
      }
    }
  }
  &.remove-padding {
    padding: 0;
  }
  .navbar-nav > li > a{
    color: $es-blue;

    &:hover{
      background-color: transparent;
      color: $es-blue;
    }
  }
}
.not-logged-in.front {
   .navbar-nav > li > a {
     color: #fff;
   }
   .remove-padding {
     .navbar-nav > li > a{
       color: $es-blue;
     }
   }
}
@media (min-width: 768px) {
  .navbar{
    nav > ul > li:first-child {
      padding-left: 0;
    }
    nav > ul > li:last-child {
      padding-right: 0;
      border: 0;
    }
    .dropdown:hover {
      .dropdown-menu {
       display: block
      }
    }
  }
  .page-blog, .page-node, .page-user, .logged-in, .page-event-splash,.node-type-blog{
    .navbar > .container-fluid .navbar-brand {
      margin-left: 0px;
    }
  }
  .node-type-marketing-pages, .front.logged-in, .node-type-event, .node-type-tabbed-page, .node-type-author-bio, .logged-in.page-search, .page-event,.page-progress-tracker, .node-type-service-landing-pages{
    .navbar > .container-fluid .navbar-brand {
      margin-left: -15px;
    }
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav > li > a {
    padding: .25em .5em;
  }
  .front.not-logged-in{
    .navbar {
      nav > ul > li:hover > a {
        color: $es-dk-gray;
      }
    }
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
  }
  .node-type-marketing-pages, .node-type-event, .front.logged-in, .node-type-deliverable, .page-search-site, .page-blog, .page-user-login,
  .page-user, .front.not-logged-in, .node-type-blog, .node-type-author-bio{
    .navbar {
      .navbar-collapse {
        background-color: #fff;
        border: 1px solid #999;
        margin-left: 0px;
      }
    }
  }
  .front.not-logged-in {
    .navbar {
      background: #fff;
      &.remove-padding {
        .navbar-collapse  {
          margin-left: 0;
        }
      }
      .navbar-brand {
        svg {
          display: block;
        }
      }
      .droppable a{
        color: $es-dk-gray;
      }
      nav > ul > li > a, .navbar-nav > li > a {
        color: $es-green;
      }
      .navbar-collapse.collapse.in {
        background-color: #fff;
      }
    }
  }
  header .search-bar {
    position: relative;
    top: 0;
    left: 0;
  }
  .navbar {
    .navbar-nav {
      width: 20%;
      text-align: left;
      float: right;
      padding-left: 0;
      margin-left: 0px;
      margin-top: 1em;
    }
    &.remove-padding{
      .navbar-collapse{
        margin-left: 0px;
      }
    }
    .primary {
      width: 80%;
      float: left;
      margin-top: 0;
      text-align: left;
      h3{
        margin-left: -.5em;
      }
    }
    .mega-menu {
      position: relative;
    }
    .block-es-search {
      position: relative;
      left: 0;
      margin-left: 0;
    }
    .droppable:hover .mega-menu {
      display: none;
    }
    .nav-expander {
      display: none;
    }
    .nav-sidebar {
      padding-top: 1em;

      .fa {
        display: none;
      }
      svg {
        display: none;
      }
      a {
        color: $es-green;
        padding: .25em 0 .25em 1em;
      }
      &:hover {
        background-color: transparent;
      }
    }
    nav {
      ul {
        display: block;
        padding: 0;
        margin-left: -.5em;

        li {
          margin-left: -.5em;
          display: block;
        }
        li.droppable:after {
          content: '.';
          color: #fff;
          clear: both;
          display: block;
          height: 0;
          width: 100%;
        }
      }
    }
  }
  header {
    #es-search-search-block-form{
      .form-text {
        border: none;
        border-radius: auto;
        background: rgb(235, 235, 235);
        box-shadow: none;
        color: black;
        cursor: text;
        outline: none;
        padding-left: 15px;
        transition: width 0.6s cubic-bezier(0,1.22,.66,1.39), border-radius 0.6s, background 0.6s;
        width: 300px;
      }
      .form-text:not(:focus) {
        text-indent: 0;
      }
    }
  }
  .logged-in {
    &:not(.page-es-user-regwiz) {
      .wrapper, .wrapper.active {
        margin-left: 0;
      }
    }
  }
  .sidebar.sb-left {
    display: none;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a, .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: $white;
  }
}
@media (max-width: 870px) {
  .navbar nav > ul > li.home {
    display: none;
  }
}
@media (max-width: 1085px) and (min-width: 768px) {
  .droppable {
    position: static;
  }
  .droppable > a:after {
    content: "\f107";
    font-family: FontAwesome;
    font-size: 12px;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }
  .droppable:hover .mega-menu {
    display: block;
  }
  .not-logged-in.node-type-marketing-pages, .not-logged-in.page-blog, .node-type-events, .node-type-event, .front.logged-in, .node-type-marketing-pages, .front.not-logged-in, .page-blog, .node-type-tabbed-page, .node-type-author-bio, .logged-in.page-search,
  .page-progress-tracker, .node-type-service-landing-pages{
    .navbar > .container-fluid .navbar-brand {
      margin-left: 0px;
    }
  }
  .not-logged-in.front, .page-user, .not-logged-in.page-blog, .node-type-deliverable, .page-event-splash,
   .page-es-user{
    .navbar > .container-fluid .navbar-brand {
      margin-left: 15px;
    }
  }
  .node-type-marketing-pages, .node-type-event, .front.logged-in, .node-type-deliverable, .page-search-site, .page-blog, .page-user-login,
  .page-user, .node-type-tabbed-page, .node-type-author-bio, .page-es-user, .page-progress-tracker, .node-type-service-landing-pages{
    .navbar {
      .navbar-collapse {
        margin-left: 80px;
      }
    }
  }
  .front.not-logged-in {
    .navbar.remove-padding {
      .navbar-collapse {
        margin-left: 80px;
      }
    }
  }
  .navbar {
    padding: 0 0 0 .5em;
    nav > ul {
      padding-left: 0;
    }
    .navbar-nav {
      font-size: .9em;
      margin-left: .5em;
      margin-top: .25em;
      padding-left: .5em;
       > li > a {
        padding: 26px 14px 30px 14px;
      }
    }
    &.remove-padding{
      .navbar-collapse{
        margin-left: 80px;
      }
    }
    > .container-fluid .navbar-brand {
      margin-left: 0px;
    }
    .navbar-brand {
      width: 50px;
      margin-right: .5em;
      overflow: hidden;

      svg {
        width: 130px;
      }
    }
  }
  .navbar-nav.secondary {
    a.fa {
      text-align: center;
      overflow: hidden;
      padding: .5em .5em;

      &:before {
        display: block;
        font-size: 1em;
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 1085px){
  body.admin-menu.adminimal-menu:before {
    height: 0;
  }
  .admin-menu.logged-in {
    .es-main-nav {
      margin-top: 50px;
      &.remove-padding {
        margin-top: 0;
      }
    }
  }
}
// company announcement hero changes start -JD
.not-logged-in {
  .menu.nav.navbar-nav.secondary {
    .leaf {
      padding: 17px 14px 30px 14px;
      a {
        color: #fff;
        padding: 6px 12px;
        background-color: $es-blue;
        border-color: #406bab;
        border-radius: 30px;
        display: inline-block;

        &:hover {
          background-color: #395f99;
          border-color: #304f7f;
        }
      }
      @media (max-width: 768px) {
        padding: 10px 0;
      }
    }
    .leaf.first {
        a {
          background-color: $es-lt-blue;
          border-color: #5b91a9;
          &:hover {
            background-color: #51839a;
            border-color: #456f83;
          }
        }
    }
  }
}
// company announcement hero changes end -JD

//admin navs
.admin-fields, .nav-tabs.nav{
  background-color: #f1f7e9;
  color: $es-dk-gray;
  padding: 15px;
  border: 1px solid #d8e5bb;
  border-radius: 4px;
}

.nav-tabs.nav{
  padding: 0;
  margin-bottom: 0px;
  li{
    &.active{
      a{
        background: transparent;
        border: none;
        color: $es-dk-gray;
        font-weight: bold;
        margin: 0;
        text-decoration: none;
      }
    }
    a{
      padding: 10px;
      &:hover{
        background-color: transparent;
        border-color: transparent;
        text-decoration: revert;
      }
    }
  }
}

.node-type-service-landing-pages{
  .tabs--primary.nav.nav-tabs{
    top: 120px;
  }
}
