.node-type-vault {
  h1 {
    margin-bottom: 0;
    padding: 0;
  }
  .pub-date {
    margin-bottom: 0;
    opacity: .8;
  }
  #block-cck-blocks-field-esource-sort-date {
    display: inline-block;

    .pub-date {
      display: inline-block;
    }
  }
  #block-cck-blocks-field-inquiry-date-closed {
    margin-bottom: 1em;
    display: inline-block;

    h2 {
      display: inline;
      font-size: 16px;
      font-family: $font-family-sans-serif;
      margin-top: 0;
      margin-bottom: 0;
      opacity: .8;

      &:before {
        content: '|';
        padding-right: .25em;
      }
    }
    .pub-date {
      display: inline-block;
    }
  }
}
