// Topical Landing Page Taxonomy Styles
.page-taxonomy-term {
    // Breadcrumbs Styling
    #block-es-utilities-es-taxonomy-breadcrumb {
        margin: 1em 0;

        ul:not(.contextual-links) {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin: 0;

            li {
                list-style: none;
                margin-right: 0.5em;
                position: relative;

                a {
                    color: #4a77bb;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                &:after {
                    content: '';
                    display: inline-block;
                    background-image: url('../img/svg/chevron-right.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 17px;
                    margin-left: 0.5em;
                    width: 18px;
                    vertical-align: middle;
                }

                &:last-child {
                    margin-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    // Title and Navigation Styling
    #block-views-taxonomy-term-children-block{
        padding: 2em;
        background-color: #f1f1f1;
        h2 {
            color: #444A50;
            margin-top: 0;
        }
        ul {
            margin-bottom: 0;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .view-header {
            position: absolute;
            top: 2em;
            right: 2em;
        }
    }
    .block-title {
        color: #85888B;
        font-size: 22px;
    }
    .nav-justified {
        text-align: left;
        margin-bottom: 1em;
    }
    .nav-justified > li {
        background-color: transparent;
        display: inline-block;
        margin-right: 0.65em;
        width: auto;

        &:last-child {
            margin-right: 0;
        }
    }
    // Negative margin override
    .nav-justified > li + li, .nav-tabs.nav-justified > li + li {
        margin-left: 0;
    }
    .nav-justified > li a{
        background-color: transparent;
        border-radius: 0 !important;
        border: none !important;
        color: $es-blue;
        display: inline-block;
        font-size: 18px;
        line-height: 1.42857;
        padding: 6px 0;
        position: relative;

        &:hover {
            color: #304f7f;
            text-decoration: underline;
        }
        &:after {
            content: '|';
            color: #4D565C;
            margin-left: 0.65em;
            display: inline-block;
        }
    }
    .nav-justified > li:last-child > a{
        &:after {
            display: none;
        }
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;
        align-items: center;

        hr {
            width: 50%;

            @media screen and (max-width: 1200px){
                display: none;
            }
        }
    }
    .tlp-title {
        display: inline-block;
        margin-bottom: 1em;
        position:relative;
    }

    // Pagination Color Override
    .pagination {
        li > a {
            color: $es-blue;
        }
    }
    // Pagination
    ul.pagination {
        border-radius: 0;

        li a, li span {
            border: none;
        }
        li.active a, li.active span{
            background-color: transparent;
            border: none;
            border-bottom: 4px solid #79ac42;
            border-radius: 0px;
            color: #304f7f;
            font-weight: bold;
        }
    }
    // Topic Entry Styling
    .view-id-taxonomy_term {
        .views-row.row {
            margin-left: 0;
            margin-right: 0;
        }
        .views-row {
            align-items: center;
            border: 1px#dadada solid;
            border-radius: 0;
            display: flex;
            flex-wrap: wrap;
            padding: 1em;
            margin-bottom: 2em;
            transition: box-shadow 0.25s ease-out;
            color: #4D565C;

            @media screen and (max-width: 1200px){
                .col-lg-6 {
                    width: 100%;
                }
            }

            &:hover {
                box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.5);
                text-decoration: none;
            }

            h4 {
                font-size: 27px;
                font-family: $font-family-sans-serif;
                font-weight: 600;
            }
            a {
                color: #4D565C;
                &:hover {
                    text-decoration: none;
                }
            }
            .fa {
                font-size: 0.85em;
            }
            .flag-throbber, .fav-add-msg, .fav-remove-msg {
                display: none !important;
            }
            img {
                width: 100%;
            }

            .label.label-default {
                background-color: transparent;
                color: #4D565C;
                padding: 1em 0;
                font-size: 15px;
            }

            .flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1em;

                span:last-child {
                    margin-left: auto;
                }
            }
        }
    }
    // Topical Landing Page CTA
    .tlp-cta {
        text-align:center;
        background-color: #f1f1f1;
        padding: 4em;
        margin-top: 2em;

        h2 {
            font-family: $font-family-sans-serif;
            margin-top: 0;
            margin-bottom: 1em;
        }

        p {
            padding: 0 2em;
            @media screen and (max-width: 1200px){
                padding: 0;
            }
        }
    }
}



// Report Page Related Topics Styles
.related-topics {
    padding: 2em;
    background-color: #f1f1f1;
    margin-bottom: 2em;

    h2.block-title {
        margin-top: 0;
    }

    .nav-justified {
        text-align: left;
        margin-bottom: 0;
    }
    .nav-justified  .item-list ul {
        padding: 0;
        margin-bottom: 0;
    }
    .nav-justified .item-list li {
        background-color: transparent;
        display: inline-block;
        margin-right: 0.65em;
        width: auto;

        &:last-child {
            margin-right: 0;
        }
    }
    .nav-justified .item-list li a{
        background-color: transparent;
        border-radius: 0 !important;
        border: none !important;
        color: $es-blue;
        display: inline-block;
        font-size: 18px;
        line-height: 1.42857;
        padding: 6px 0;
        position: relative;

        &:hover {
            color: #304f7f;
            text-decoration: underline;
        }
        &:after {
            content: '|';
            color: #4D565C;
            margin-left: 0.65em;
            display: inline-block;
        }
    }
    .nav-justified .item-list li:last-child a{
        &:after {
            display: none;
        }
    }
}
