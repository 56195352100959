
//view all page
#block-views-ask-e-overview-block-1 {
  .view-filters {
    background-color: #f1f1f1;
    padding: 1em;
  }
  .table-responsive {
    margin: 2em;
    .views-field-title {
      width: 10%;
    }
    th {
      color: $es-dk-gray;
      a {
        display: block;
        width: 100%;
        content: 'f0dc';
        img {
          float: right;
        }
      }
      &:nth-child(4) {
        width: 15%
      }
      &:nth-child(5), &:nth-child(6), &:nth-child(7){
        width: 10%;
      }
    }
  }
  .view-header {
    background-color: $es-dk-gray;
    color: #fff;
    padding: 1em;
  }
  label {
    margin-bottom: 10px;
  }
  #edit-field-answered-value-wrapper {
    label[for="edit-field-answered-value"]{
      border-bottom: 1px solid #999;
      margin-bottom: .5em;
      text-align: center;
      width: 100%;
    }
  }
  #edit-field-answered-value-min-wrapper {
    float: left;
  }
  #edit-field-answered-value-max-wrapper {
    float: right;
  }
}

.page-questions {
  .fa {
    display: inline;
  }
  .page-header {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .pub-date {
    margin: 0;
  }
  h1 {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 0;
  }
  #aske-recommendations-block {
    h3{
      font-weight: bold;
      text-align: center;
    }
  }

  .status-message {
    font-size: 1.2em;
    padding: .5em;
  }
  .block-es-page-tools {
    margin-bottom: 2em;
  }

  #es-aske-answer-form {
    fieldset.collapsible {
      border: 1px solid $es-lt-gray !important;
      margin: 1em 0 !important;

      .form-item{
        margin-left: 1em;
      }
    }
    .select2-container  {
      width: 100% !important;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered, .select2-container--default .select2-selection--single .select2-selection__rendered{
      min-width: 300px;
    }
    .select2-selection.select2-selection--single {
      position: relative;
    }
    #upload-fieldset-wrapper{
      margin-left: 1em;
      margin-top: 1em;
    }
    .form-managed-file {
      padding: 0;
      width: 100%;

    }
    .form-type-managed-file{
        label {
        width: 100%;
      }
    }
    .filter-help {
      display: none;
    }
  }
}
.pub-date {
  //margin-bottom: 1em;
}

#es-aske-question-form label {
  font-weight: bold;
}

#edit-market-sector-c label {
  font-weight: normal;
}

// AskE Content
#es-aske-question-form {
  #edit-user-info{
    margin-top: 30px;
  }
  p, div {
    font-size: 1.75rem;
  }
}
