#tmi-logo{
  margin-bottom: 0.5em;
  margin-right: 0.3em;
  width: 25%;
}

#block-es-tmi-es-tmi-user-estimate-form, #block-multiblock-4{
  background-color: #fff;
  border: 1px solid #dadada;
  margin-bottom: 1em;
  padding: 1em;
  h2 {
    border-bottom: 1px solid #dadada;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: .5em;
    padding-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
  }
}

#tmi-chart {
  text {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
  }
  .y-axis, .x-axis {
    font-size: 1.75em;
    font-weight: 600;
  }
  .tick {
    text {
      font-size: 12px;
    }
  }
  .overlay {
    fill: none;
    pointer-events: all;
  }
  .focus rect {
    fill: black;
  }
  .focus text {
    font-size: 12px;
    fill: white;
  }
  .tooltip {
    opacity: 0.95;
  }
  .dot-other-data {
    stroke: #545f64;
    fill: #3f464a;
  }
.tick-wrapper {
  .tick:not(:first-child) {
      line {
        stroke: #dbdbdb;
      }
    }
  }
}

.tmi-tabs{
  margin-bottom: 1em;
}

@supports (display: grid) {
  .tmi-grid-wrapper {
    display: grid;
    margin-top: 3em;
    grid-template-areas:
      'chart estimates'
      'chart form';
    grid-template-columns: auto min-content;
    .tmi-chart-wrapper {
      grid-area: chart;
    }
    .tmi-expert-estimates {
      grid-area: estimates;
      width: 300px;
      span {
        font-weight: 550;
      }
    }
    .tmi-user-form {
      grid-area: form;
      width: 300px;
      label{
        font-weight: 550;
      }
    }
  }
  @media only screen and (max-width: 1550px) {
    .tmi-grid-wrapper {
      .tmi-expert-estimates {
        width: 250px;
      }
      .tmi-user-form {
        width: 250px;
        .btn-default {
          display: block;
          margin: 1em auto;
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 1350px) {
    .tmi-grid-wrapper {
      display: grid;
      margin-top: 3em;
      grid-template-areas:
      'chart'
      'estimates'
      'form';
      .tmi-expert-estimates {
        width: auto;
        div {
          padding: 0 0.5em;
        }
      }
      .tmi-user-form {
        width: auto;
        form {
          padding: 0.5em 1.5em;
        }
        .btn-default {
          display: inline;
          margin: 1em;
          width: 20%;
        }
      }
    }
    #tabs-wrapper{
      margin-top: 2em;
      .nav{
        li{
          width: 30%
        }
      }
    }
  }
  @media only screen and (max-width: 650px){
    .tmi-grid-wrapper{
      .tmi-chart-wrapper{
        #other-sources{
          margin: 0;
          position: relative;
        }
      }
      .tmi-user-form{
        .btn-default{
          width: 35%;
        }
      }
    }
    // #tabs-wrapper{
    //   .nav{
    //     li{
    //       display: table-cell;
    //       width: 10%;
    //     }
    //   }
    // }
  }
}

//if Grid is not supported
@supports not (display:grid){
  .tmi-grid-wrapper {
    .tmi-chart-wrapper {
      float: left;
      width: 70%;
    }
    .tmi-user-form {
      float: right;
      width: 280px;
      label {
        font-weight: 550;
      }
    }
    .tmi-expert-estimates {
      float: right;
      width: 280px;
      span{
        font-weight: 550;
      }
    }
  }
  #tabs-wrapper {
    display: inline-block;
  }
  @media only screen and (max-width: 1405px) {
    .tmi-grid-wrapper {
      .tmi-chart-wrapper, .tmi-expert-estimates, .tmi-user-form {
        float: none;
        width: auto;
      }
    }
  }
  @media only screen and (max-width: 1365px) {
    #tabs-wrapper {
      margin-top: 2em;
      .tmi-tabs {
        li{
          width: 30%
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    #tabs-wrapper{
      .tmi-tabs{
        li{
          display: table-cell;
          width: 10%;
        }
      }
    }
    .tmi-grid-wrapper {
      .tmi-chart-wrapper {
        #other-sources {
          margin: 0;
          position: relative;
        }
      }
    }
  }
}

//'Display other' sources switch
.tmi-chart-wrapper {
  #other-sources {
    margin-left: 4em;
    position: absolute;
  }
  .switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }
  .switch [type=checkbox] {
    height: 0;
    opacity: 0;
    pointer-events: none;
    width: 0;
  }
  .slider {
    -webkit-transition: .4s;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  .switch .slider::before,
  .switch .slider::after {
    background: #949494;
    content: '';
    display: block;
    margin: 0 3px;
    transition: all 100ms cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  .switch .slider::before {
    border: 1.2px solid black;
    border-radius: 0.85em;
    height: 1.7em;
    opacity: 0.3;
    width: 3em;
  }

  .switch .slider::after {
    border: 1px solid #7b7979;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0,0,0,0.2), 0 0 2px rgba(0,0,0,0.4);
    height: 1.7em;
    position: absolute;
    top: 50%;
    transform: translate(0, -57%);
    width: 1.7em;
  }

//'Other sources' switch special states
  .switch [type=checkbox]:focus+.slider {
    box-shadow: 0 0 8px #5e9ed6;
    outline: #5d9dd5 solid 1px;
  }

  .switch [type=checkbox]:disabled+.slider {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.6;
  }

  .switch [type=checkbox]:disabled+.slider::after {
    box-shadow: none;
  }

//'Other sources' switched on state
  .switch [type=checkbox]:checked+.slider::before,
  .switch [type=checkbox]:checked+.slider::after{
    background: #71a137;
  }

  .switch [type=checkbox]:checked+.slider::after {
    border: 1px solid #61892d;
    transform: translate(calc(3em - 100%), -57%);
  }
}

//User form % sliders
.tmi-user-form{
  .form-group{
    margin-bottom: 20px;
  }
  .ui-slider-horizontal {
    .ui-slider-handle{
      background: #79ac42;
      border-color: #6c9a3b;
      border-radius: 20px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.4);
      height: 30px;
      width: 30px;
    }
    .ui-slider-handle{
      top: -.57em;
    }
  }
  .btn-default{
    height: 44px;
    margin-right: 1em;
    margin-top: 0.5em;
    width: 100px;
    &#clear{
      display: inline-block;
      padding: 12px 12px;
    }
  }
  #edit-clear{
    display: inline;
  }
  .form-item-beginning-market-share-percent, .form-item-target-market-share-percent, .form-item-peak-market-share-percent {
    margin-bottom: 25px;
    label{
      margin-bottom: 0;
    }
    #edit-beginning-market-share-percent-slider, #edit-target-market-share-percent-slider, #edit-peak-market-share-percent-slider{
      margin-bottom: 0;
    }
  }
}

//Print & PDF style
@media print{
  #other-sources{
    display: none;
  }
  .tmi-grid-wrapper{
    display: inline;
    .tmi-expert-estimates{
      width: 100%;
    }
    .tmi-user-form{
      display: none;
    }
  }
  .tmi-tabs{
    display: none;
  }
}

//Invisible to visual users
.tmi-screen-reader-only{
  height:1px;
  left:-10000px;
  overflow:hidden;
  position:absolute;
  top:auto;
  width:1px;
}
#tmi-hidden-tooltip {
	display: block;
  opacity: 0;
	position: absolute;
	top: 100%;
}
