.node-type-tabbed-page {
  .field-start-date {
    display: inline-block;
    margin-right: 0.5em;
  }
  .carousel-inner {
    .item{
      height: 400px;
      &::before{
      background: inherit;
      background-attachment: fixed;
      content: "";
      -webkit-filter: brightness(0.5);
      filter: brightness(0.5);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      }
      @media (max-width: 530px) {
        height: 400px;
      }
      .carousel-caption {
        padding: 0;
        position: absolute;
        text-align: left;
        top: 10%;
        h1, h3{
          color: #fff;
          text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
        }
        h3 {
          font-size: 25px;
          line-height: 35px;
        }
        @media (max-width: 1535px) {
          width: 60%;
        }
        @media (max-width: 1235px) {
          left: 10%;
          width: 80%;
        }
        @media (max-width: 930px) {
          left: 2%;
          top: 10%;
          .pull-right{
            bottom: 0;
            display: block;
            position: absolute;
          }
          .heading-container{
            h1{
              font-size: 35px;
              margin: 0;
            }
            h3{
              font-size: 20px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  .tab-rows {
    align-items: flex-end;
    background-color: $es-lt-gray;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 90px;
    justify-content: center;
    position: relative;
    padding-top: 10px;
    @media(max-width: 1083px){
      display: block;
      height: auto;
      padding-top: 0px;
    }
    .nav-pills {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      text-align: center;
      @media(max-width: 1083px){
        flex-direction: column;
      }
      li {
        transition: $float;
        margin: 0 4px;
        a {
          border-radius: 0;
          border-bottom: none;
          background-color: rgba(0, 0, 0, 0.4);
          color: #fff;
          font-size: 14px;
          padding: 17px;
          text-align: center;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          -webkit-font-smoothing: inherit;
          width: 100%;
          height: 100%;
          &:hover{
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 0;
            box-shadow: 0px -10px 18px -5px rgba(0,0,0,0.25);
          }
          i{
            display: inline;
            font-size: 20px;
            margin-right: 10px;
          }
        }
        &.active {
            position: relative;
            z-index: 1;
          a {
            border-top: 6px solid $es-blue;
            background-color: #fff !important;
            box-shadow: 0px -2px 14px 0px #333;
            border-bottom: 0px !important;
            color: $es-dk-gray;
            &:hover{
              box-shadow: 0px -10px 18px -5px rgba(0,0,0,0.25) !important;
            }
          }
        }
        @media (max-width: 1083px){
          margin: 0;
        }
      }
    }
  }

    .qt-subnav{
      ul.quicktabs-style-nostyle{
        li:first-child{
          display: none;
        }
      }
      ul.quicktabs-tabs.quicktabs-style-garland{
        background-color: #ddd;
        li{
          &.active a{
            font-weight: bold !important;
            color: #333 !important;
            background: transparent;
            border-radius: 0px;
            text-decoration: underline;
          }
          a{
            font: normal 15px/19px Helvetica !important;
            color: #333 !important;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
        .tab-pane {
          .view-cx-framework-2 {
            .views-row {
              margin: 1em 0;
            }
          }
        }

    }

    .views-field {
      .content-type {
        margin-top: 0;
      }
    }
}

.metadata-display {
  color: #777777;
  font-size: .9em;
  margin: .75em 0;
}
