.node-type-deliverable, .node-type-blog, .access-denied, .page-node-182671 { 
  //new deliverable styles
  article {
    margin-bottom: 2em;
  }
  h1 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    color: $es-dk-gray;
    margin: .5em 0 0 0;
  }
  h2, h3, h4{
    font-family: $font-family-sans-serif;
    font-weight: 600;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 23px;
  }
  .main-content {
    margin-top: -150px;
    background-color: #fff;
    padding-right: 2em;
    padding-left: 2em;
    @media (max-width: 668px) {
      .main-content {
        padding: 0;
      }
    }
  }
  @media (max-width: 668px) {
    .main-content {
      margin: 2em 0;
    }
  }
  &.page-node-133013, &.page-node-133008, &.page-node-133011, &.page-node-82550{
    .main-content {
      margin-top: 2em;
    }
  }
  .subtitle {
    color: $es-dk-gray;
    font-weight: 600;
  }
  .author {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 22px;
    margin: .5em 0;
  }
  .pub-date {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    margin-right: 0.5em;

    &:last-child {
      margin-right: 0;
    }
  }
  #page-header {
    border: 0;
  }
  .es-lead-image.es-lead-image-full {
    height: 400px;
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
  }
  .es-lead-image-partial {
    height: 450px;
    margin: 0 auto;
    width: 1140px;
  }
  @media screen and (max-width: 1024px){
    .es-lead-image.es-lead-image-full, .es-lead-image-partial {
      background-attachment: scroll;
      background-size: auto 100%;
    }
  }
  .region-header {
    .fa:before {
      font-size: 1.5em;
      text-align: center;
    }
  }
  figure {
    padding: 1em;
    margin: 1.5em 0;
    text-align: center;
    border: 0;
    border-radius: 5px;
    background: #f1f1f1;

    h3, figcaption {
      text-align: left;
    }
    h3{
      margin-top: 0;
    }
    figcaption {
      font-size: .85em;
      line-height: 1.5em;
      margin-bottom: 1em;
    }
    &.pull-right, &.pull-left {
      margin: .5em;
    }
    div[class^='wistia_'], div[class*='wistia_'] {
      width: 100% !important;
      margin: 0 auto !important;
    }
  }
  .interrupted-content {
    display: table;
    border-top: 1px solid #9c9c9c;
    border-bottom: 1px solid #9c9c9c;
    margin: 1.5em 0;
    padding: 1em 0;
    div {
      display: table-cell;
      float: none;
      vertical-align: middle;
    }
  }
  //end new deliverable styles

  .ES_Notes_Container {
    font-size: .9em;
  }
}
