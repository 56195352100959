.cover-image {
  min-height: 450px;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-position: top;
  z-index: 0;
  &:before {
    background-color: rgba(0,0,0,.3);
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    display: attr(data-before-display);
  }
  .cover-image-title-container {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    text-align:center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }
  h1 {
    color: #ffffff;
    z-index: 2;
  }
  .subtitle {
    color: #ffffff;
    opacity: 1;
  }
}
