footer.main-footer, .not-logged-in.front footer.main-footer{
  background: #000;
  color: #eee;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.618;
  position: relative;
  bottom:0;
  width:100%;
  z-index: 2;
  padding: 2em;
  margin: 0;
  border-top: 6px solid $es-green;
  .-mt-3 {
    margin-top: -2em;
    @media (max-width: 1200px) {
      margin-top: 0;
    }
  }
  
  .flex-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 900px) {
      flex-direction: column;
    }
    .flex-col {
      max-width: 20%;
      @media (max-width: 900px) {
        max-width: 100%;
      }
    }
  }
  a{
    color: #fff;
  }
  .contact-us {
    display: block;
    margin-top: .05em;
  }
  .footer-social-links {
    margin: 20px auto;
    text-align: center;
  }

  a:hover {
    color: #eee;
    opacity: .8;
  }
  h3{
    font-size: 25px;
    position: relative;
    color: #eee;
    font-weight: 600 !important;
    a {
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    &:after {
      content: '';
      display: block;
      margin-top: 15px;
      width: 85px;
      height: 4px;
      background-color: $es-green;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  p{
    margin: 1em 0;
    a{
      color: #eee;
      display: block;
      line-height: 24px;
    }
  }
  h4{
    color: #777;
    font-size: 20px;
    font-weight: 300;
  }
  .fa {
    margin-right: .5em;
    font-size: 2em;
  }
  svg {
    background: #fff;
    margin-right: .5em;

    fill{
      background: #3B5A9A;
    }
  }
  .footer-links a{
    margin-right: 2em;
    display: inline-block;
  }
  .footer-copyright {
    margin-top: .25em;
  }
  .footer-license {
    margin: 1em 0 .5em 0;
  }
}

// Fixes overlap on E Design Progress Tracker pages.
.page-concepts footer.main-footer {
  position: relative;
}
.main-content, .wrapper > .main-container{
  padding-bottom: 5em;
}

//search
.search-container {
  .card-container {
    padding-bottom: 5em;
  }
}
.not-logged-in.node-type-marketing-pages {
  .main-content, .wrapper > .main-container{
    padding-bottom: 5em;
  }
}
.logged-in.node-type-marketing-pages {
  .main-content, .wrapper > .main-container{
      padding-bottom: 5em;
  }
}
.not-logged-in.front {
  .main-content, .wrapper > .main-container {
    padding-bottom: 0em;
  }
}
//media queries
@media (max-width: 767px) {
  .logged-in {
    .main-wrapper {
      .wrapper{
       + footer.main-footer {
          width: 100%;
          margin-left: 0;
          height: 90em;
        }
      }
    }
  }
}
