//Mixin for stars
@mixin star($color, $non-hover-opacity) {
  bottom: 0;
  color: $color;
  font-size: 137%;
  margin: 0.5em;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 1000;
  opacity: $non-hover-opacity;

  &:hover {
    opacity: 1;
  }
}
//Mixin for star placed under outlined stars
@mixin background-star($color, $opacity) {
  bottom: 0;
  color: $color;
  font-size: 137%;
  margin: 0.5em;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 900;
  opacity: $opacity;
}

.react-app-wrapper {
  /* Unfavorited cards with a dark background */
    /* Outline*/
    .favorite-icon {
      @include star($white, 0.8);
    }
    /* Fill */
    .favorite-icon-background {
      @include background-star($white, 0.25);
    }
  /* Unfavorited cards with a white background */
    /* Outline*/
    .favorite-icon-dark {
      @include star(#545f64, 0.67);
    }
    /* Fill */
    .favorite-icon-filled {
      @include star(#F4D03F, 1);
    }

  /* Unfavorited cards with a white background */
    /* Outline*/
    .favorite-icon-dark-background {
      @include background-star(#545f64, 0.25);
    }
    /* Fill */
    .favorite-icon-filled-background {
      @include background-star(#F4D03F, 0.80);
    }
  /* For blank state display */
    .favorite-icon-display{
      bottom: 0;
      color: #545f64;
      font-size: 100%;
      margin: 0 0.3em;
      z-index: 1000;
    }
}

/* Toast message style customization */
.react-app-toast{
  background: #4a77bb;
}
