// global styles
.slick-slide {
  margin: 0 18px;
  padding: 0;
  overflow: hidden;
}
.slick-list {
  padding: 0 15px;
}
.slick-arrow, .slick-arrow:hover, .slick-arrow:before {
  cursor: pointer;
}
.slick-arrow:before {
  color: $es-dk-gray;
  font-size: 36px;
}
.slick-dots {
  margin-bottom: 2em;
}
//3 up carousel
.carousel-slider {
  h3{
    text-align: center;
  }
  .nav {
    margin-top: 3em;
  }
  .slick-arrow:before {
    color: $es-dk-gray;
    font-size: 36px;
  }
  .slick-prev {
    margin-left: -15px;
  }
  .slick-next{
    margin-right: -15px;
  }
  .carousel-items {
    margin-bottom: 1em;
  }
  .zoom {
    position: relative;
    overflow: hidden;
    h3 {
      color: #fff;
      font-family: "Helvetica";
      bottom: .5em;
    }

    img {
      max-width: 100%;
      -moz-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    &:hover img {
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    &:hover {
        cursor: pointer;
      h3{
        display: none;
      }
    }
    &:after {
      position: absolute;
      background-color: rgba(44,49,52,.6);
      content: 'Read more';
      font-family: $font-family-sans-serif;
      font-weight: 400;
      padding-top: 35%;
      text-align: center;
      font-size: 28px;
      width: 100%;
      height: 100%;
      color: #fff;
      top:  -5px;
      left: 0;
      opacity: 0;
      transition: opacity .7s linear;
      box-sizing: border-box;
    }
    &:hover:after {
        opacity: 1;
    }
  }
  .zoom-text {
    padding: 0 .5em;
    color: #fff;
    font-size: 26px;
    font-family: 'Helvetica';
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}


// homepage featured carousel styles
.featured {
  .video {
    position: absolute;
    width: 50px;
    height: 50px;
    font-size: 50px;
    opacity: 0.8;
    left: 50%;
    top: 50%;
    margin: -45px 0 0 -25px;
    color: $white;
  }
  /* bootstrap: fix content height inside hidden tabs to allow multiple carousels */
  .tab-content > .tab-pane, .pill-content > .pill-pane {
    display: block;
    height: 0;
    overflow: hidden;
  }
  .tab-content > .active, .pill-content > .active {
      height: 100%;
  }
  .slick-prev::before,
  .slick-next::before {
    color: $es-green;
  }
  .rotator-selector {
    margin-bottom: 25px;
    .nav {
      text-align: center;
      li {
        display: inline;
        float: none;
        a {
          display: inline-block;
        }
      }
    }
  }
  .slick-slider, .tab-panel {
    position: relative;
  }
  .slick-prev, .slick-next {
    position: absolute;
    cursor: pointer;
    padding: 10px 0;
  }
  .slick-prev {
    z-index: 5;
  }
  .slick-next {
    text-align: right;
  }
  .slide-title {
    text-align: center;
    color: $es-dk-gray;
    font-weight: bold;
  }
  .slide-container {
    position: relative;
    background-color: #545f64;
    height: 300px;
    overflow: hidden;
    margin-bottom: 1em;
    border: 10px solid #fff;

    .img-responsive {
      padding: 0;
    }
    img {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .slide-description {
      position: absolute;
      background: $es-green;
      opacity: 0;
      padding: 15px;
      width: 100%;
      min-height: 100%;

      h3 {
        color: $white;
        margin-top: 10px;
        display: inline-block;

        &.heading-date {
          margin-left: 5px;
          padding-left: 5px;
          border-left: 2px solid white;
        }
      }
      p.teaser {
        color: $white;
      }
      img {
        margin: 0 auto;
      }
    }
  }
  a.slick-slide:hover {
    .slide-description {
      opacity: .95;
    }
    .video {
      display: none;
    }
  }
}

//SLP App
.slp-slider{
  .browse-grid-container{
    display: inline;
  }
  .slick-track{
    align-content: flex-start;
    display: flex;
    margin-left: 0px;
  }
  .slick-list{
    margin: 0 30px 0 15px;
    overflow: visible;
  }
  .slick-slide{
    margin: 0 6.5px;
    overflow: visible;
  }
  .slick-prev {
    left: -50px;
    z-index: 2;
    &:before{
      background-color: rgba(250, 250, 250, 0.6);
      color: #444d51;
      font-size: 45px;
      line-height: 0;
      opacity: 1;
      padding: 750% 0 850% 100%;
    }
  }
  .slick-next {
    right: 7px;
    &:before {
      background-color: rgba(250, 250, 250, 0.6);
      color: #444d51;
      font-size: 45px;
      line-height: 0;
      opacity: 1;
      padding: 750% 95% 790% 10%;
    }
  }
  .slick-dots {
    padding: 0 0 0 0;
    margin-bottom: 10px;
  }
}

//Authenticated Dashboard Carousel
.react-app-wrapper{
  .browse-grid-container{
    display: inline;
  }
  .slick-track{
    align-content: flex-start;
    display: flex;
    margin-left: 0px;
  }
  .slick-list{
    margin: 0 30px 0 15px;
    overflow: visible;
  }
  .slick-slide{
    margin: 0 6.5px;
    overflow: visible;
  }
  .slick-prev {
    left: -50px;
    z-index: 2;
    &:before{
      background-color: rgba(250, 250, 250, 0.6);
      color: #444d51;
      font-size: 45px;
      line-height: 0;
      opacity: 1;
      padding: 750% 0 850% 100%;
    }
  }
  .slick-next {
    right: 15px;
    &:before {
      background-color: rgba(250, 250, 250, 0.6);
      color: #333;
      font-size: 45px;
      line-height: 0;
      opacity: 1;
      padding: 750% 95% 790% 10%;
    }
  }
  .slick-dots {
    padding: 0 0 0 0;
    margin-bottom: 10px;
  }

//Banner Carousel
.banner-container{
  padding: 2em 0;
  .slick-initialized .slick-slide{
    margin: 0;
  }

  .slick-slide img{
    margin: 0 auto;
  }

  .banner-style{
    .slick-list{
        overflow: hidden;
    }
    .slick-prev, .slick-next {
      &:before{
        background-color: transparent;
        font-size: 30px;
      }
    }
    .slick-dots{
      bottom: -45px;
    }
    .banner-image-responsive{
      max-width: 67%;
      height: auto;
    }
  }
  }
}
figure {
  .es-single-slider{
    h3 {
      text-align: left;
    }
  }
}
.es-single-slider{
  iframe {
    @media (max-width: 400px){
      width: 300px !important;
    }
  }
  .slick-prev::before,
  .slick-next::before {
    color: $es-green;
  }
  .rotator-selector {
    margin-bottom: 25px;
    .nav {
      text-align: center;
      li {
        display: inline;
        float: none;
        a {
          display: inline-block;
        }
      }
    }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .slick-prev, .slick-next {
    position: absolute;
    cursor: pointer;
    padding: 10px;
  }
  .slick-prev {
    z-index: 5;
  }
  .slick-next {
    text-align: right;
  }
  img {
    margin: 0 auto;
  }
  .slick-dots {
    position: absolute;
    bottom: -45px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        border-radius: 100px;
        font-size: 0;
        line-height: 0;
        display: block;
        width: 5px;
        height: 5px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: $es-dk-gray; 
        opacity: .5;
      }
    }
    li.slick-active {
      button{
        opacity: 1;
      }
    }
  }
}
@media (max-width: 768px) {
  .es-slider {
    .slide-container {
      height: 200px;
    }
  }
}

// Sliders
.es-content-slider-home {
  padding: 2em 0;
  margin-bottom: 1em;

  .slick-dots {
    display: none !important;
  }

  a:hover {
    text-decoration: none;
  }
  .slick-list {
    overflow: visible;
  }

  .slick__arrow {
    top: 0;
    height: 100%;
    // gradient fades
    &:before, &:after {
      content: '';
      display: block;
      background: rgb(255,255,255);
      height: 100%;
      position: absolute;
      z-index: 0;
      width: 250px;
      @media (max-width: 768px) {
        width: 60px;
      }
    }
    &:before {
      top: 0;
      left: 0;
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
    &:after {
      top: 0;
      right: 0;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

    // arrows
    .slick-prev, .slick-next {
      z-index: 1;
      &:before {
        font-size: 50px;
        font-family: 'slick';
        @media (max-width: 768px) {
          font-size: 35px;
        }
      }
    }
    .slick-prev {
      left: 22px;
      @media (max-width: 768px) {
        left: 8px;
      }
    }
    .slick-next {
      right: 30px;
      @media (max-width: 768px) {
        right: 8px;
      }
    }
  }
}
// Logo slider
.es-logo-slider, .es-content-slider {
  position: relative;
  // gradient fades
  &:before, &:after {
    content: '';
    display: block;
    background: rgb(255,255,255);
    height: 100%;
    position: absolute;
    z-index: 1;
    width: 250px;
    @media (max-width: 768px) {
      width: 60px;
    }
  }
  &:before {
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
  &:after {
    top: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
  // arrows
  .slick-prev, .slick-next {
    z-index: 2;
    &:before {
      font-size: 50px;
      font-family: 'FontAwesome';
      @media (max-width: 768px) {
        font-size: 35px;
      }
    }
  }
  .slick-prev {
    left: 22px;
    @media (max-width: 768px) {
      left: 8px;
    }
  }
  .slick-next {
    right: 30px;
    @media (max-width: 768px) {
      right: 8px;
    }
  }
  .client-logo {
    width: 100%;
    max-width: 200px;
    text-align: center;
    margin: 0 auto;
    display: block;
    padding: 2em 0;

    img {
      width: 100%;
      max-width: 150px;
      margin: 0 auto;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .lead-image-thumb {
    min-height: 275px;
  }
}

//Authenticated report slick sliders
.es-single-slider .slick-prev::before,
.es-single-slider .slick-next::before{
  color: #f1f1f1;
  background-color: #545764;
  font-size: 23px;
  border-radius: 340px;
}
.es-single-slider .slick-next::before{
  padding: 6px 9px 6px 10px;
}
.es-single-slider .slick-prev::before{
  padding: 6px 11px 6px 8px;
}
