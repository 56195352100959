@-webkit-keyframes fadeInOut {
  from { opacity: 0.0; }
  25% { opacity: 1.0; }
  75% { opacity: 1.0; }
  to { opacity: 0.0; }
}
@keyframes fadeInOut {
  from { opacity: 0.0; }
  25% { opacity: 1.0; }
  75% { opacity: 1.0; }
  to { opacity: 0.0; }
}
.animation-nest-fade{
  animation-name: color_nest_fade ;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@-webkit-keyframes color_nest_fade {
  from { fill: $es-dk-gray; }
  to { fill: #ef2b2b; }
}
@keyframes color_nest_fade  {
  from { fill: $es-dk-gray; }
  to { fill: #ef2b2b; }
}
.animation-pie-fade{
  animation-name: color_pie_fade ;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@-webkit-keyframes color_pie_fade {
  from { fill: $es-dk-gray; }
  to { fill: $es-green; }
}
@keyframes color_pie_fade  {
  from { fill: $es-dk-gray; }
  to { fill: $es-green; }
}
.eye{
   -webkit-transform-origin: 50%;
   -webkit-animation: blink 3s infinite alternate;

}
.eye-ball {
   -webkit-transform-origin: 50%;
   -webkit-animation: coreblink 3s infinite alternate;

}
@keyframes blink {
  0%, 96% {
    -webkit-transform: scale(1,1);
    -webkit-stroke-width: 1.4434px;
  }
  100% {
    -webkit-transform: scale(1,0.05);
    -webkit-stroke-width: 6px;
  }
}
@keyframes coreblink {
  0%, 96% {
    -webkit-transform: scale(1,1);
  }
  100% {
    -webkit-transform: scale(1,0.05);
  }
}
