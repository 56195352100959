

#block-es-iir-es-iir-sticky-nav {
  width: 260px;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
  }
  .field-ivr-feature-name {
    line-height: 1.2em;
    margin-bottom: .5em;
  }
  .well {
    @media (max-width: 1795px){
      width: 90%;
    }
    @media (max-width: 1485px){
      width: 80%;
    }
    @media (max-width: 1315px){
      width: 75%;
    }
  }
  #accordion.panel-group {
     margin: .25em 0;
     font-size: .9em;

    .panel {
      border: 0;
    }
    .panel-body {
      padding: 0 15px 15px 15px;
    }
  }
  a, a:hover {
    text-decoration: none;
  }
  .fa {
    margin-right: .5em;
  }
}
#block-es-iir-es-iir-sticky-nav {
  #accordion.panel-group {
    .panel-body {
      padding-top: 12px;
      a {
        &:last-child {
          .field-ivr-feature-name {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.node-type-iir {
  #quicktabs-iir_quicktabs {
    li.active a, .block {
      background: #f1f1f1;
    }
    .block {
      padding: 1em;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }
  .block-es-page-tools {
    margin-bottom: 1em;
  }
  #block-quicktabs-iir-quicktabs {
    .block-title{
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
  #block-cck-blocks-field-iir-key-findings {
    .row {
      margin-top: .5em;
      margin-bottom: .5em;
    }
    h2{
      color: #333333;
      font-size: 16px;
      font-family: Helvetica, Arial, sans-serif;
      font-weight: normal;
    }
  }

  h1 {
    margin: 1em 0 .25em 0;
  }
  .nav-tabs a {
    //z-index: 2;
  }
  //fix anchor position
  article, #about-the-study, #block-views-iir-sections-block{
    padding-top: 50px;
    margin-top: -50px;
  }
  article {
    padding-top: 100px;
    margin-top: -100px;
  }
  .ribbon {
    width: 85%;
  }
  .field-collection-container  {
    border: 0;
  }
  .field-collection-view {
    border-bottom: 1px #ddd solid;
    padding-bottom: 1em;

    .field-label {
      width: 100%;
    }
  }
  .field-iir-key-findings {
    .field-collection-view {
      margin: 0;
      padding: 0;

      .field-iir-rating-name {
        @extend .text-muted;
        @extend .small;
      }
    }
  }
  #block-cck-blocks-field-iir-key-findings {
    .field-collection-view {
      .field-label {
        @extend .text-muted;
        @extend .small;
        font-weight: normal;
      }
    }
  }
  .es-overall-performance {
    padding: 1em 0;
    width: 100% !important;
  }
  .es-utility-rating {
    margin: 1em 0;
  }
}
@media (max-width: 1200px){
  .node-type-iir{
    #quicktabs-iir_quicktabs {
      .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
        border-radius: 4px;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}
@media (max-width: 1200px) and (min-width: 991px) {
  .node-type-iir{
    #block-es-iir-es-iir-sticky-nav {
      width: 200px;
    }
  }
}
@media (max-width: 990px) {
  .node-type-iir{
    aside.col-sm-3 {
      display: none;
    }
    section.col-sm-9 {
      width: 100%;
    }
  }
}
@media (min-width: 768px) {
  .node-type-iir {
    .ribbon {
      width: 85%;
    }
  }
}
@media (max-width: 767px) {
  .node-type-iir {
    .ribbon {
      width: 100%;
    }
  }
}
@media (max-height: 400px) {
  .node-type-iir {
    #es-sidebar-aside {
      display: none;
    }
  }
  .col-sm-9 {
    width: 90%;
  }
}
@media (max-height: 700px) {
  .node-type-iir {
    #collapse3{
      height: 100px;
      overflow-y: scroll;
    }
  }
}
