.speaking-requests{
  .upcoming{
    margin-bottom: 4em;
    .view-speaking-engagements{
      background: #eee;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      margin: 15px auto;
      padding: 20px;
      p{
        color: #000;
        margin: 0;
      }
      .view-empty{
        width: 40%;
        margin: 0 auto;
        @media(max-width: 768px){
          width: 100%
        }
      }
    }
  }
  #speaking-request-form{
    border-top: 8px solid $es-green;
    margin: 40px auto;
    padding: 30px 60px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    max-width: 700px;
    legend{
      line-height: 1.6;
    }
    fieldset{
      background: transparent;
      .panel-body{
        padding: 0;
      }
    }
  }
}
