.navbar.es-main-nav {
  padding: 10px 0 0 0;
  height: auto;

  a, button, span {
    font-size: 16px;
  }
}

.navbar .form-search.content-search .form-text {
  background-repeat: no-repeat;
  background-color: #f1f1f1;
  box-shadow: none;
  color: black;
  cursor: text;
  height: 36px;
  opacity: .8;
  outline: none;
  padding-left: 15px;
  padding-right: 38px;
  transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s, background 0.6s;
  margin-top: 10px;
  width: 185px;
  background-position: right 10px bottom 10px;;
  border: 1px solid #707070;
  border-radius: 5px;
    &:focus {
      // background-image: none;
      width: 300px;
      font-size: 16px !important;
    }
    @media (max-width: 1300px) {
      width: 100px;
      &:focus {
        // background-image: none;
        width: 100px;
      }
    }
    @media (max-width: 1060px) {
      width: 100%;
      margin-top: 0;
      &:focus {
        // background-image: none;
        width: 100%;
      }
    }
}

// .adminimal-menu.logged-in .navbar {
//   margin-top: 28px;
// }

.navbar .form-search.content-search {
  margin-right: 1em;
  @media (max-width: 1100px) {
    margin: 0;
    padding: 10px 18px;
  }
}

.input-group span button.btn-primary {
  background-image: url(/sites/all/themes/es_bootstrap/img/svg/search-blue.svg);
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  top: -6px;
  position: absolute;
}

.form-search.content-search .input-group-btn {
  position: absolute;
  right: 30px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  button {
    background-color: transparent;
    color: transparent;
    border: none;
    outline: none;
  }
}

img.navbar-logo {
  width: 100%;
  max-width: 185px;
  padding: 10px 20px;
  padding-bottom: 25px;
  height: auto;

  @media (max-width: 1270px) {
    max-width: 150px;
    padding-bottom: 25px;
  }
}


.region.region-navigation {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  height: auto !important;
  @media (max-width: 1060px) {
    display: block;
  }

  &.mobile-nav-collapse {
    border-top: 4px solid transparent;
  }
  &.mobile-nav-collapse.collapse.in {
    border-top: 4px solid $es-green;
    overflow-y: scroll;
    //overflow-x: hidden;
    height: 100vh !important;
  }
}


#navbar {
  .container-fluid {
    padding: 0;
    > .row {
      margin: 0 !important;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.navbar .block-menu {
  & > ul > li {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 4px solid transparent;
    > a:hover {
      background-color: transparent;
    }
    span.dropdown-toggle {
      padding: 10px 15px;
      display: inline-block;
      cursor: pointer;
      pointer-events: none;
      @media (max-width: 1060px) {
        padding: 15px 18px;
        width: 100%;
        pointer-events: initial;
      }
    }
  }
  
}
.navbar #block-system-main-menu {
  margin-right: auto;
  & > ul > li {
    border-bottom: 4px solid transparent;
    &:hover {
      border-bottom: 4px solid $es-green;
      @media (max-width: 1060px) {
        border-bottom: 4px solid transparent;
      }
    }
  }  
  @media (max-width: 1400px) {
    .menu.nav > .leaf.first {
        display: none;
      }
  }
  @media (max-width: 1060px) {
    .menu.nav > .leaf.first {
        display: inline-block;
    }
  }
}

.dropdown-menu {
  padding: 0;
  background-color: #f1f1f1;
  font-size: 18px;
  border-radius: 0;
  top: 103%;
  > li > a {
    padding: 15px 20px;

    &:hover {
      background-color: $es-lt-gray;
    }
  }
}
.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
  background-color: #c6c6c5;
  color: #333;
}
.navbar .form-search.content-search {
  @media (max-width: 1100px) {
    .input-group {
      width: 100%;
    }
  }
  
}

#block-search-form {
  @media (max-width: 1060px) {
    padding-bottom: 5em;
  }
}

#block-system-user-menu {
  position: relative;
  margin: 0 auto;
  margin-right: 20px;
  li {
    a {
      @media (max-width: 1060px) {
        display: inline-block;
        &:not(.btn-navbar) {
          padding-left: 0;
        }
      }
    }
    @media (max-width: 1060px) {
      width: 100%;
      padding: .5em;
      margin-right: .5em;
    }
  }
  @media (max-width: 1060px) {
    width: 100%;
    padding: .5em;
    margin-right: .5em;
    border-top: 1px solid #A8A9AA;
  }

  &:before {
    content: '';
    display: block;
    height: 50px;
    width: 1px;
    background-color: #A8A9AA;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;

    // @media (max-width: 1170px){
    //   left: -4px;
    // }

    @media (max-width: 1060px) {
      display: none;
    }
  }
}

#navbar .btn[data-toggle="collapse"] {
  display: none;
}


section#block-system-main-menu {
  .nav > li > a {
    color: #444a50;
  }
}
@media (max-width: 1060px) {
  #navbar .btn[data-toggle="collapse"] {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  // Restore this after styling mobile nav
  .mobile-nav-collapse:not(.in) {
    display: none;
  }
  .mobile-nav-collapse {
    position: absolute;
    width: 100%;
    top: 80px;
  }
  section#block-system-main-menu {
    display: block;
    width: 100vw;
    float: none;
    // &.pull-right {
    //   float: none !important;
    // }
    .first.leaf a {
      @media (max-width: 1060px) {
        padding-top: 30px;
      }
    }
    .last.leaf a {
      @media (max-width: 1060px) {
        padding-bottom: 30px;
      }
    }
    .nav > li > a:not(.btn-navbar) {
      padding: 0;

      @media (max-width: 1060px) {
        padding: 15px 18px;
      }
    }
    .nav > li > ul > li > a {
      padding: 3px 20px;
      @media (max-width: 1060px) {
        padding: 15px 18px;
        padding-left: 30px;
      }
    }
    ul, li {
      width: 100%;
      padding: .5em;
      margin-right: .5em;
      @media (max-width: 1060px) {
        padding: 0;
      }

      li li.dropdown.expanded {
        padding-left: 0;
        margin-left: 0;
      }
      ul.dropdown-menu {
        position: relative;
        border: none;
        box-shadow: none;
        margin: 0 auto;
        border-radius: 0;
        // margin-left: -20px;
        width: 100%;
      }
    }
  }
}

.btn-navbar {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #406bab;
  white-space: nowrap;
  font-size: 16px;
  padding: 6px 12px !important;
  border-radius: 5px !important;
  color: #fff;
  background-color: $es-blue;

  &:hover {
    background-color: #395f99 !important;
    border-color: #304f7f !important;
  }
}

// nav active item state override
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
  background-color: inherit;
}

.dropdown-menu {
  .active>a {
    &:hover {
      background-color: #c6c6c5;
    }
  }
}