
.modal-content {
  border-radius: 0;
}
.modal-submit-btn{
  margin: 1em 0 0 0;
}
.page-questions {
  .modal {
    hr {
      margin-bottom: 2em;
      margin-top: 2em;
      width: 30%;
    }
    img {
      display: none;
    }
    .modal-body{
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
  }
}

.full-page.modal#pageModal {
  padding: 14px;
  .modal-dialog {
    width: 100%;
    margin: 15px 0;
    .modal-content{
      .modal-header{
        padding-top: 40px;
        .close {
          margin-top: -.5em;
          opacity: 1;
          span {
            font-size: 2em;
            color: $es-dk-gray;
            opacity: 1;
          }
        }
      }
      .modal-body{
        img{
          width: 100%;
        }
      }
    }
  }
}


.block-es-freebies {
  &.well-warn {
    margin-top: 2em;
  }
  .btn {
    margin-right: 1em;
    &:last-child {
      margin-right: 1.5em;
    }
  }
  
  // confirmation modal
  .warn {
    .modal-dialog {
      transform: translate(-50%, -25%);
      top: 25%;
      left: 50%;
      margin: 0;
      .close {
        font-size: 30px;
        line-height: 20px;
      }
      .modal-body {
        border-top: 4px solid $es-orange;
      }
      
      .form-item {
        font-size: 20px;
        width: auto;
        display: inline-block;
      }
      
      .modal-footer {
        text-align: left;
      }
    }
  }
}