.node-type-author-bio {
  h2 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 28px;
  }
  #page-header{
    h1 {
      margin-top: .5em;
      margin-bottom: 0;
    }
    .inline {
      display: inline-block;
    }
    .field-bio-image {
      margin-top: 0;
      overflow: hidden;
      width: 130px;
      margin-top: 0;
      height: 130px;
      border-radius: 200px;
    }
  }
  .row{
    @media (max-width: 768px) {
      margin-left: -7px;
      margin-right: -7px;
    }
  }
  .field-body {
    @media (max-width: 768px) {
      padding: 0 .5em;
    }
  }
  .block-title {
    @media (max-width: 768px) {
      padding-bottom: .5em;
      padding-left: .25em;
    }
  }
  .subtitle {
    font-size: 20px;
  }
  .text-divider {
    border-top: solid $es-gray-07 .2em;
    border-bottom: solid $es-gray-07 .2em;
    background-color: $es-off-white;
    margin: .5em auto;
    padding: .25em;
  }
  .text-divider.info {
    border-top: solid $es-blue .2em;
    border-bottom: solid $es-blue .2em;
    background-color: lighten($es-lt-blue, 35%);
  }
  .es-author-bio-header {
    color: $es-dk-gray;
    margin-top: 1.5em;
    width: 98%;
    .btn{
      text-decoration: none;
    }
    .field-social-links{
      margin-top: 10px;
    }
    .label-default {
      background-color: transparent;
      color: #333;
      font-size: 17px;
      font-weight: 500;
      border-radius: 0;
      padding: .2em .6em;
      &:not(:last-child){
        border-right: solid 1px $es-dk-gray;
      }
    }
    .topical-expertise-group{
      margin-top: 30px;
      .topical-expertise-list{
        margin-left: 5px;
      }
    }
  }
  .view-content-by-this-author {
    margin-top: 60px;
    @media (max-width: 768px) {
      margin-bottom: 1em;
    }
    .views-row {
      margin-bottom: 50px;
      border-bottom: 1px solid #B6BABC;
      padding-bottom: 2em;
    }
    h3{
      font-weight: 600;
      margin: 0;
    }
    .author {
      font-size: 18px;
      margin-top: .25em;
    }
    .pub-date {
      color: #9c9c9c;
      margin-bottom: .5em;
    }
  }
}
  .field-bio-position{
    font-weight: 500;
  }
  .field-bio-image {
    margin-top: 0;
    overflow: hidden;
    width: 100%;
    margin-top: 0;
    height: 100%;
    border-radius: 200px;
    .img-responsive{
      width: 100%;
      height: 100%;
    }
  }
    @media (max-width: 990px) {
      .field-bio-image {
        width: 75px;
        height: 75px;
      }
  }
  .field-bio-teaser, .es-block-transform-teaser {
    color: $es-dk-gray;
  }
  .es-block-transform-title {
    margin-bottom: 0;
    font-size: 24px;
  }
  .es-block-transform-position {
    .author {
      font-size: 18px;
    }
  }
//NEW STYLES

// Author Bios on reports
//
// Markup:
// <section id="block-es-author-es-author-contributing-authors" class="block block-es-author contextual-links-region clearfix">
//  <h2 class="block-title">Contributing Authors</h2>
//  <div class="alert alert-info">
//    <div class="row es-block-transform-trigger" data-target="/authors/travis-provin">
//      <div class="col-sm-12 col-md-2 es-block-transform-img text-center">
//        <div class="field-bio-image">
//          <img typeof="foaf:Image" class="img-responsive" src="https://www.esource.com/sites/default/files/travis_provin_bio.jpg" width="921" height="924">  </div>
//        </div>
//        <div class="col-sm-12 col-md-10">
//          <div class="h3 es-block-transform-title">
//            <a href="/authors/travis-provin">Travis Provin</a>
//        </div>
//        <div>
//          <div class="es-block-transform-position">
//            <strong class="author">Associate Analyst, Marketing &amp; Communications</strong>
//          </div>
//          <hr>
//        </div>
//        <div class="es-block-transform-teaser">
//         <p>Travis Provin conducts research to help utilities communicate with their business and residential customers. Prior to his role as an associate...</p>
//        </div>
//      </div>
//    </div>
//   </div>
// </section>
//
// Style guide: Author-bios.reports

#block-es-author-es-author-contributing-authors {
  margin: 1em 0;

  h2.block-title {
    @extend .ribbon;
    font-size: 19px;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    margin: 0 auto;
    padding: .5em;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 100;
  }
  @media (max-width: 990px) {
    h2.block-title{
      width: 100%;
      &:before, &:after {
        display: none;
      }
    }
  }
  .alert-info {
    background-color: #fff;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    margin-top: -1em;
    opacity: .9;
    .h3{
      font-family: $font-family-sans-serif;
      font-weight: 400;
    }
    hr {
      display: none;
    }
    .es-block-transform-trigger {
      margin-top: 1em;
    }
    .es-block-transform-position{
      margin: .5em 0;
    }
    p{
      color: $es-dk-gray;
    }
  }
}
