// logged out splash page
.logged-out-event-info{
	h1{
		margin-bottom: 0;
	}
	.event-date{
		display: inline-block;
		margin: 1em 0;
		padding: 1em 0;
		width: 100%;
		font-size: 26px;
		font-weight: 300;
		border-top: 1px solid #545f64;
		border-bottom: 1px solid #545f64;
		&:before{
			padding-right: 1em;
			content: "\f073";
			font-family: FontAwesome;
		}
	}
	.event-teaser{
		display: inline-block;
		margin-bottom: 1em;
	}
}

#ui-datepicker-div{
	background-color: #fff;
	font-family: Helvetica, Arial, sans-serif;
	border: 1px solid #dedede;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	a:hover{
		cursor: pointer;
	}
	.ui-widget-header{
		background: none;
	}
	.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year{
		font-family: Helvetica, Arial, sans-serif !important;
	}
	tbody{
		.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
			background: none;
		}
	}
	tr td {
		&:hover{
			cursor: pointer !important;
		}
		a{
			text-align: center !important;
			&:hover{
				background: $es-blue !important;
				font-weight: bold !important;
				color: #fff !important;
			}
		}
	}
	.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight{
		background-color: $es-blue !important;
		border: none;
		color: #fff;
		font-weight: bold;
	}
}

// This is the main block section of the events as rendered into the quicktabs
// on the /events page.
#block-quicktabs-events {
	#quicktabs-events {
		background-color: #fff;
		// NAV TABS
		.nav-pills {
			background-color: #f1f1f1;
			color: #888;
			display: flex;
			height: 70px;
			line-height: .5em;
			-webkit-font-smoothing: antialiased;
			margin: 0;
			padding: 0;
			width: 100%;
			justify-content: center;
			li {
				background-color: transparent;
				line-height: 50px;
				overflow: hidden;
				padding: 1em 0 0 0;
				width: 200px;
				a {
					background-color: rgba(0, 0, 0, 0.4);
			    	color: #fff;
					font-size: 18px;
					height: 100%;
			    	margin: 0 5px;
					line-height: 50px; /* vertical spacing fix */
					&:hover{
						background-color: rgba(0, 0, 0, 0.5);
						box-shadow: 0px -10px 18px -5px rgba(0,0,0,0.25);
					}
				}
				&:hover {
					  box-shadow: none;
				}
			}
			li:nth-child(2){
				a:before{
					padding-right: 1em;
				}
			}
			li:nth-child(3){
				a:before{
					padding-right: 1em;
				}
			}
			li.active {
			height: 70px;
				a {
					background-color: #ffffff;
					border-top: 6px solid $es-blue;
					color: #444A50;

					&:hover{
						box-shadow: 0px -10px 18px -5px rgba(0,0,0,0.25);
					}
				}
			}

			a {
				border-bottom: 1px solid #fff;
				border-radius: 0px;
				color: #545f64;
				display: block;
				letter-spacing: 0;
				outline: none;
				padding: 0 20px;
				text-decoration: none;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
		}
		.view-events {
			padding: 0 80px;
			@media(max-width: 1025px){
				padding: 0px;
			}
			@media(max-width: 768px){
				padding: 0 30px;
			}
			.view-filters{
				clear: both;
				margin: 2em 0 0 0;

				// SEARCH BAR
				#edit-title-wrapper{
					padding: 0;
					position:relative;
					// width: 100%;
					width: 60%;
					margin: 0 1em 2em 0;
					.search-bar-container{
						border-radius: 0;
						.form-control{
			        box-shadow: none;
			        -webkit-box-shadow:  none;
			      }
					input{
						font-size: 18px;
						padding: 10px 10px 10px 5px;
						display: block;
						width: 100%;
						border: none;
						border-bottom: 1px solid $es-gray-06;
						border-radius: 0;
					}
					input:focus {
						outline: none;
					}
					label {
						color: #999;
						font-size: 25px;
						font-weight: normal;
						position: absolute;
						pointer-events: none;
						left: 5px;
						top: 15px;
						transition: 0.25s ease all;
					}
				 .bar:before, .bar:after   {
			        content: '';
			        height: 2px;
			        width: 0;
			        bottom: 1px;
			        position: absolute;
			        background: $es-dk-gray;
			        transition: 0.25s ease all;
			      }
			      .bar:before {
			        left: 50%;
			      }
			      .bar:after {
			        right: 50%;
			      }
				  input:focus ~ .bar:before, input:focus ~ .bar:after {
					width:50%;
				  }
				  input:focus ~ label, input:not(:placeholder-shown) ~ label    {
			        top: -15px;
			        font-size: 20px;
			        color: $es-dk-gray;
			      }
				 }
				}
				@media (max-width: 1024px){
					#edit-title-wrapper{
						width: 100%;
					}
				}
				// DatePicker
				#edit-field-start-date-value-wrapper{
					margin: .5em 0 0 0;
				}
				.fa-calendar{
					font-size: 2em;
					cursor: pointer;
					padding-top: 5px;
					&:hover{
						color: #79ac42;
					}
				}
				.views-exposed-widget,.views-reset-button{
					margin: 0;
					padding: 0;
					#edit-reset{
						float: right;
						margin: 0;
						margin-top: .75em;
					}
				}
			}

			// Indidvidual Events Cards
			.views-row {
				height: 100px;
				background-color: #fff;
				list-style-type: none;
				transition: all .225s ease-in-out;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
				position: relative;
				margin: 20px auto;
				border: 1px solid #dedede;
				a {
					display: inline-block;
					width: 100%;
				}

        .locked-event {
          .event-title {
            &:before {
              content: '';
              display: inline-block;
              background-image: url("../img/locked-events.png");
              background-size: 23px;
              width: 23px;
              height: 23px;
              position: relative;
              top: 4px;
              margin-right: 4px;
            }
          }
        }

				.date-box {
					vertical-align: top;
					display: inline-block;
					margin: 0;
					width: 100px;
					height: 100%;
					background-color: #4a77bb;
					transition: all .225s ease-in-out;
					@media (max-width: 550px){
						display: none;
					}
					.date-display-single {
						color: #FFFFFF;
						font-weight: 200;
						font-size: 44px;
						text-align: center;
						vertical-align: middle;
						line-height: 100px;
						display: block;
					}
					.date-display-range {
						color: #FFFFFF;
						font-weight: 200;
						font-size: 24px;
						text-align: center;
						vertical-align: middle;
						line-height: 100px;
						display: block;
					}
				}

				.event-info {
					position: absolute;
					display: inline-block;
					margin-left: 25px;
					height: 100%;
					@media (max-width: 550px){
						position: inherit;
						margin-left: 0px;
					}
					.event-title {
						margin: 1em 0 0 0;
						padding-right: 30px;
						font-size: 20px;
						line-height: 23px;
	          overflow: hidden;
	          display: -webkit-box;
						-webkit-line-clamp: 2;
	          -webkit-box-orient: vertical;
	          text-overflow: ellipsis;
						font-family: $font-family-sans-serif;
					}
					h4 {
						display: inline-block;
						font-size: 16px;
						margin: 10px 5px 10px 0;
						span{
							display: inline;
						}
						.date-display-single, .date-display-range {
							display: inline;
							font-size: 16px;
						}
					}
					.content-type{
						margin: 0px;
					}
				}
				@media (max-width: 768px){
					.event-info{
						.event-title, h4{
							font-size: 17px;
							margin-left: 10px;
							-webkit-line-clamp: 2;
						}
						h4{
							margin-left: 10px;
							font-size: 13px;
							.date-display-single, .date-display-range{
								font-size: 13px;
							}
						}
					}
				}
				@media (max-width: 375px){
					.date-box {
						width: 60px;
						height: 100%;
						.date-display-single {
							font-size: 28px;
						}
						.date-display-range {
							font-size: 14px;
						}
					}
					.event-info {
						.event-title {
							margin: 1em 5px 0 5px;
							font-size: 14px;
							-webkit-line-clamp: 2;
						}
						h4 {
							margin: 1em 0 0 5px;
							padding-right: 40px;
							font-size: 12px;
							.date-display-single, .date-display-range {
								font-size: 12px;
							}
						}
					}
				}
				.chip{
					display: inline;
				  font-weight: 400;
				  font-family: Helvetica;
				  height: 33px;
				  padding: 1px 15px;
					margin: 0;
				  float: right;
				  text-align: center;
				  color: #fff;
				  position: absolute;
				  bottom: 0;
				  right: 0;
					box-shadow: none;
          background: #545f64;
          font-size: 15px;
					i{
						margin: 0;
						padding: 0;
					}
				}
				@media (max-width: 375px){
					.chip{
						font-size: 12px;
					}
				}
				&:hover {
					.date-box{
						// background-color: #6c9cb2;
					}
					cursor: pointer;
					box-shadow: 0px 15px 62px -7px rgba(0,0,0,0.44);
					-moz-box-shadow: 0px 15px 62px -7px rgba(0,0,0,0.44);
					-webkit-box-shadow: 0px 15px 62px -7px rgba(0,0,0,0.44);

				}
			}
		}
	}
	// This is applicable when there are no styles
	.view-empty p {
		margin: 10px 0 10px 0;
	}
}



// Event Page (clicked through from events homepage)
.event-page{
	// image
	.field-banner-image{
		margin-top: 1em;
	}
	// title
	.event-title{
		margin-top: 1em;
	}
	#page-header{
		border-bottom: none;
	}
	// date
	.pub-date {
		width: auto;
		margin-right: 0.5em;
	}
	#block-views-event-date-time-block {
		padding: 1em 0;
		span {
			font-size: 26px;
			font-weight: 300;
		}
		.date-display-range, .date-display-single{
			// font-size: 26px;
			// font-weight: 300;
			&:before{
				padding-right: 1em;
				content: "\f073";
				font-family: FontAwesome;
			}

			.date-display-range, .date-display-single {
				&:before {
					display: none;
				}
			}
		}
		border-top: 1px solid #79ac42;
    border-bottom: 1px solid #79ac42;
	}
	#quicktabs-event {
		background-color: #fff;
		.nav-pills {
			background-color: #f1f1f1;
			width: 100%;
			margin: 0 auto 1.5em auto;
			line-height: .5em;
			color: #888;
			-webkit-font-smoothing: antialiased;
			display: table;
			text-align: center;
			li{
				display: inline-block;
				background-color: #f1f1f1;
				line-height: 38px;
				overflow: hidden;
				padding: 1em 0 0 0;
				margin: 0 auto;
				min-width: 60px;
				width: 200px;
				a{
					border: none;
				}
			}
			li.active a {
				background-color: #ffffff;
			}
			a {
				color: #545f64;
				display: block;
				letter-spacing: 0;
				outline: none;
				padding: 0 20px;
				text-decoration: none;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
		}
	}
}

// Speaking Requests Page
.speaking-requests{
	// Makes horizontal card background transparent because default is white
	#slick-views-testimonials-1-slider > div > div > div.col-md-12.carousel-items.slick-slide > div > span > div {
		background-color: transparent !important;
	}
	.speaking-request-testimonials{
		font-size: 1.15em;
		font-weight: 300;
	}
	.view-display-id-block_3 {
		div > span {
			a{
				h3{
					margin-top: 0;
					color: $es-blue;
				}
				color: #545f64;
				text-decoration: none;
				.speaking-engagement-wrapper{
					@extend .horizontal-card;
					font-size: 24px;
					// importants to overule "@extend .horiztonal-card"
					display: block !important;
					padding: 1em !important;
					min-height: 100% !important;
					margin: 1em 0 0 0 !important;
					width: 75% !important;
					margin-left: auto !important;
					margin-right: auto !important;
				}
			}
		}
	}
	@media (max-width: 667px){
		.view-display-id-block_3{
			div > span {
				a{
					h3{
						font-size: .9em;
					}
					div > strong,span {
						font-size: .75em;
					}
					.speaking-engagement-wrapper{
						width: 100% !important;
					}
				}
			}
		}
	}
	// date range picker styling
	.start-date-wrapper, .end-date-wrapper{
		display: inline-block;
		width: auto;
		.date-popup-icon{
			margin-top:25px;
		}
	}
	.end-date-wrapper{
		.date-no-float > .control-label{
			float: left;
			margin: .5em 2em 0 0;
		}
	}
}
