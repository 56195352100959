//Icons in page headers
.green-icon-btn {
  position: relative;
  a {
    background-color: #f1f1f1;
    border-radius: 80px;
    display: inline-block;
    padding: 0 .5em .25em 0;
    margin-bottom: .5em;
    width: 100%;
    height: 45px;

    &:hover {
      background-color: #dadada;
      text-decoration: none;
    }
  }
  .fa, svg{
    background-color: #79ac42;
    color: #fff;
    font-size: .75em;
    height: 40px;
    line-height: 40px;
    width: 40px;
    margin-top: .25em;
    margin-right: .5em;
    border-radius: 100px;
    text-align: center;

    &:before {
      font-size: 20px;
      padding-top: 1em;
    }
  }
  svg {
    padding: .5em;
  }
  span {
    position:absolute;
    margin-top: .5em;
  }
}
.page-tools {
  .fa{
    margin-top: .25em;
    margin-right: .25em;
    background: #f1f1f1;
    border-radius: 100px;
    color: $es-blue;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 80px;

    &:before {
      font-size: 40px;
      padding-top: 1em;
    }
  }
}

.page-tools {
  margin-top: 1.5em;

  .fa{
    margin-top: .25em;
    margin-right: .25em;
    background: #fff;
    border-radius: 100px;
    color: $es-blue;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 80px;

    &:before {
      font-size: 20px;
      padding-top: 1em;
    }
  }
  .print_pdf, .print_html, .print_mail {
    margin-bottom: .5em;
    margin-right: .5em;
    margin-left: 0;
  }
  i.fa {
    color: $es-dk-gray;
    display: inline-block;
    font-size: .7em;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }
  a {
    background-color: transparent;
    border-radius: 8px;
    display: inline-block;
    padding: 0 .5em .25em 0;
  }
  a.fa {
    text-decoration: none;
    color: #fff;
    background: $es-green;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100px;
    text-align: center;

    &:before {
      font-size: 22px;
    }
    &:hover {
      background-color: #52752d;
    }
  }
  a.unflag-action i.fa {
    font-size: 1em;
  }
  .flag-wrapper {
    .tooltip {
      display: none;
    }
  }
  .flag {
    font-size: 85%;
    padding: 0 .75em .25em 0;

    i.fa {
      color: $es-dk-gray;

      &:before {
        font-size: 20px;
      }
      &:hover {

      }
    }
  }
}
.full-width-page-tools {
  margin-top: 1em;
}
.page-questions {
  #block-es-page-tools-es-page-tools {
    padding-top: 1em;
    a.fa {
      display: inline-block;
    }
  }
}
