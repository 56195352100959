.logged-in.front .main-content {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.align-center-to-col, .reverse-row-to-col {
  display: flex;
  align-items: center;
  margin: 40px 0;
  @media(max-width: 990px){
    display: block;
  }
}
.reverse-row-to-col{
  flex-direction: row-reverse;
}
.archived{
  article.node-unpublished{
    background-color: #f1f1f1;
    background-size: 9px 9px;
    background-image: repeating-linear-gradient(45deg, #d7d7d7 0, #d7d7d7 0.9px, #f1f1f1 0, #f1f1f1 50%);
    h2, h3, p, ul, li{
      text-shadow: 2px 1px 1px #fff;
    }
  }
}
