#user-register-form{
	padding: 1em;
	#edit-submit{
		float: right;
		margin-right: 15px;
		margin-bottom: 1em;
	}
}
#node-210465{
	p:first-child{
		padding: 1em;
		margin-bottom: 0;
	}
	a:first-child{
		float: right;
		margin-right: 1em;
		margin-bottom: 1em;
	}
}
#user-pass-reset{
	padding: 1em;
	#edit-submit{
		float: right;
		margin-bottom: 1em;
	}
}
#es-user-register-page1-form{
	padding: 1em;
	#edit-submit{
		float: right;
		margin-right: 15px;
		margin-bottom: 1em;
	}
}
#es-alerts-subscribe{
	#edit-es-alerts-subscription-submit{
		float: right;
		margin: 0 -15px 1em 0;
	}
}
#es-newsletters-form{
	#edit-submit{
		float: right;
		margin-bottom: 1em;
	}
}
#es-user-edit-user-form{
	legend{
		span{
			padding-top: 1em;
		}
	}
	#edit-submit{
		margin-right: 15px;
		margin-bottom: 1em;
	}
}
