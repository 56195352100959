
.node-type-service-landing-pages {
  .activeTab{
    .content-loader {
      height: 0px;
      padding-top: 25%;
      position: relative;
      width: 100%;
      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .es-lead-image {
    background-position: center center;
    height: 315px;
    @media (max-width: 430px){
      height: 375px;
      position: relative;
    }
    h1 {
      @media only screen and (max-width: 845px){
        font-size: 30px;
      }
      font-size: 35px;
      margin: 0;
      position: relative;
      top: 35%;
    }
    h2 {
      @media only screen and (max-width: 845px){
        font-size: 23px;
      }
      color: $white;
      font-size: 28px;
      font-weight: 300;
      margin: 0;
      opacity: .7;
      padding: .5em 0 0 .5em;
      position: relative;
      top: 35%;
    }
    .btn {
      margin: 1em .5em 0 0;
      @media (min-width: 900px){
        width: 300px;
      }
    }
    .btn-container {
       @media (max-width: 430px){
         left: 20%;
         bottom: 0;
         position: absolute;
       }
    }
  }
  h1 {
    color: #fff;
  }
  .tabs--primary.nav{
    position: absolute;
    top: 80px;
  }
  .react-app-wrapper {
    .header {
      background-image: none;
      margin-top: -55px;
      min-height: 0;
      position: absolute;
    }
    _:-ms-lang(x), .header {
      margin-top: -100px;
    }
    .header {
      margin-top: -100px\9;
    }
  }
  .contact-button{
    margin-bottom: 2.25em;
  }
  .pull-right{
    &.btn-container{
      position: absolute;
      right: 0;
      padding-right: 5px;
    }
  }
}
