.region-sidebar-second .block.block-twitter-block {
  padding-top: 0;
  h2.block-title {
    font-family: $font-family-sans-serif;
    &:before {
      font-family: FontAwesome;
      content: "\f099";
      background-color: #6c9cb2;
      color: #fff;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      font-size: 1.3em;
      display: block;
      margin: 0 auto 20px;
      text-align: center;
      line-height: 43px;
    }
  }
}

// Global pager styles
.pagination {
  > .active > span {
    background-color: transparent;
    border-bottom: 4px solid $es-green;
    border-top: none;
    border-right: none;
    border-left: none;
    color: $es-blue;
    font-weight: bold;
  }
  > .disabled > span {
    display: none;
  }
  a {
    border: none !important;
    font-size: 18px;
    font-weight: 400;
  }
  > li:first-child > span {
    border-radius: 0;
  }
}

.page-blog, .page-news{
  margin-top: 3em;
  h1 {
    font-weight: 300;
    color: $es-dk-gray;
  }
  h2{
    font-family: "Helvetica", Arial, sans-serif;
    font-weight: 600;
    margin-top: .5em;
    line-height: 1.3;
  }
  h3{
    font-weight: 600;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: 23px;
    line-height: 1.3;
    a {
      font-family: "Helvetica", Arial, sans-serif;
      font-weight: 500;
    }
  }
  .field-abstract {
    color: $es-dk-gray;
  }
  .field-lead-image-image {
    padding: 0;
  }
  .blog-subheader-span {
    color: #777777;
    font-size: .9em;
    margin: .75em 0;
  }
  .view-blog-view {
    h3.title {
      margin-bottom: 0px;
      font-family: $font-family-sans-serif;
    }
    .views-field-comment-count {
      color: $es-gray-06;
      padding-bottom: 10px;
      margin-bottom: 15px;
      font-size: $font-size-small;
      border-bottom: 1px solid $es-off-white;

      .badge {
        background-color: $es-off-white;
      }
    }
    .views-field-field-esource-preview {
      i {
        vertical-align: middle;
        font-size: $font-size-h3;
      }
    }
    .views-field-field-topics-by-category {
      .field-content {
        display: inline;
      }
    }
    .pagination {
      padding-top: 30px;
      > .active > span {
        background-color: transparent;
        border-bottom: 4px solid $es-green;
        border-top: none;
        border-right: none;
        border-left: none;
        color: $es-blue;
        font-weight: bold;
      }
      > .disabled > span {
        display: none;
      }
      a {
        border: none;
        font-size: 18px;
        font-weight: 400;
      }
      > li:first-child > span {
        border-radius: 0;
      }
    }
  }
}

// Individual Blog Posts
.node-type-blog {
  .region-sidebar-second {
    h2.block-title {
      font-family: Helvetica, Arial, sans-serif;
    }
  }
  #page-header {
    h1 {
      margin-bottom: 0;
      padding: 0;
    }
  }
}
#block-views-blog-view-block-2 {
  .views-row {
    border-bottom: 0;
    padding: 8px 0;
  }
}
.comment {
  .permalink {
    display: none;
  }
}
.block-views-blog-view-block-2 {
  .views-row {
    border-bottom: 0;
    padding: 8px 0;
  }
}
