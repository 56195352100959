/* Copyright 2013-2015 etc Chris Tabor. See https://github.com/christabor/css-progress-wizard/blob/master/LICENSE for details. */

// colors

$incomplete: #bbb !default;
$complete: #79ac42 !default;
$active: #79ac42 !default;
$hover: #79ac42 !default;
$step-danger: #d3140f !default;
$step-warning: #edb10a !default;
$step-info: #5b32d6 !default;

// sizing

$bubble-size: 20px !default;
$bubble-line-thickness: 3px !default;
$border-thickness: 1px !default;
$darken-amount: 30% !default;

// misc
$mobile-size: 400px !default;

@mixin bubble-style($color) {
  background-color: $color;
  color: $color;
  border-color: darken($color, $darken-amount);
  &:before,
  &:after {
    background-color: $color;
    border-color: darken($color, $darken-amount);
  }
}
.flexer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.no-flexer {
  display: block;
}
.no-flexer-element {
  -ms-flex: 0;
  -webkit-flex: 0;
  -moz-flex: 0;
  flex: 0;
}
.flexer-element {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1;
}
.progress-indicator {
  @extend .flexer;
  margin: 0;
  padding: 0;
  font-size: 80%;
  margin-bottom: 1em;
  > li {
    @extend .flexer-element;
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: $incomplete;
    display: block;

    // Commented out below part to eliminate hoverable state on acct creation revamp

    // &:hover {
    //   color: darken($incomplete, $darken-amount);
    // }

  }
  > li .bubble {
    border-radius: 1000px;
    width: $bubble-size;
    height: $bubble-size;
    background-color: $incomplete;
    display: block;
    margin: 0 auto 0.5em auto;
    border-bottom: $border-thickness solid darken($incomplete, 20%);
  }

  // line connectors

  > li .bubble:before,
  > li .bubble:after {
    display: block;
    position: absolute;
    top: $bubble-size / 2 - $border-thickness;
    width: 100%;
    height: $bubble-line-thickness;
    content: '';
    background-color: $incomplete;
  }
  > li .bubble:before {
    left: 0;
  }
  > li .bubble:after {
    right: 0;
  }

  > li:first-child .bubble:before,
  > li:first-child .bubble:after {
    width: 50%;
    margin-left: 50%;
  }

  > li:last-child .bubble:before,
  > li:last-child .bubble:after {
    width: 50%;
    margin-right: 50%;
  }


  // completed state

  > li.completed {
    color: $complete;
    .bubble {
      @include bubble-style($complete);
    }
  }

  // current state

  > li.active {
    color: $active;
    .bubble {
      @include bubble-style($active);
    }
  }

  // button states

  > li a:hover .bubble {
    @include bubble-style($hover);
  }

  // override states

  > li.danger .bubble {
    @include bubble-style($step-danger);
  }
  > li.warning .bubble {
    @include bubble-style($step-warning);
  }
  > li.info .bubble {
    @include bubble-style($step-info);
  }

  // stacked version

  &.stacked {
    @extend .no-flexer;
    > li {
      text-indent: -10px;
      text-align: center;
      display: block;
    }
    > li .bubble:before,
    > li .bubble:after {
      left: 50%;
      margin-left: -$bubble-line-thickness / 2;
      width: $bubble-line-thickness;
      height: 100%;
    }
    .stacked-text {
      position: relative;
      z-index: 10;
      top: 0;
      margin-left: 60% !important;
      width: 45% !important;
      display: inline-block;
      text-align: left;
      line-height: 1.2em;
    }
    > li a {
      border: none;
    }
  }
  &.stacked.nocenter {
    > li .bubble {
      margin-left: 0;
      margin-right: 0
    }
    > li .bubble:before,
    > li .bubble:after {
      left: $bubble-size / 2;
    }
    .stacked-text {
      width: auto !important;
      display: block;
      margin-left: $bubble-size * 2 !important;
    }
  }
}

@media handheld, screen and (max-width: $mobile-size) {
  .progress-indicator {
    font-size: 60%;
  }
}
// EDesign 2020 Prog Tracker inline-nav and SelectDropdown
.inline-nav {
  background-color: #F1F1F1;
  padding: 1em 0;
  .btn{
    border-radius: 30px;
  }
}
@media screen and (max-width: 667px){
  #block-es-progress-tracker-es-progress-tracker-toolbar > div{
    width: 65%;
    margin: .5em auto;
  }
}
#block-es-progress-tracker-es-progress-tracker-toolbar > div{
  height: 33px;
}
// Dropdown
.sort-by {
  .dropdown {
    font-family: Helvetica;
    width: 200px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1210px) {
    .dropdown {
      width: 150px;
    }
  }
  .dropdown > a {
    font-family: Helvetica;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    border: 1px solid #dadada;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
  }
  .dropdown > a::after {
    font-family: FontAwesome;
    content: "\f078";
    float: right;
    margin-right: 15px;
  }
  .dropdown:hover > a::after{
    font-family: FontAwesome;
    content: "\f077";
    float: right;
    margin-right: 15px;
  }
  // .dropdown ul
  .dropdown:focus ul, .dropdown:hover ul {
    z-index: 100;
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    border: 1px solid #dadada;
    overflow: hidden;
  }
  .dropdown ul{
    z-index: 100;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: absolute;
  }
  .dropdown a {
    display: block;
    padding: 0 0 0 10px;
    text-decoration: none;
    line-height: 40px;
    font-size: 13px;
    font-weight: bold;
    color: #79ac42;
    background-color: #FFF;
  }
  .dropdown li {
    height: 0;
    overflow: hidden;
    transition: all 500ms;
    cursor: pointer;
  }
  .dropdown:hover li {
    border-bottom: 1px solid #F1F1F1;
  }
  .dropdown.hover li {
    // transition-delay: 300ms;
  }
  .dropdown li:first-child a {
    border-radius: 3px 3px 0 0;
  }
  .dropdown li:last-child a {
    border-radius: 0 0 3px 3px;
  }
  .dropdown li:first-child a::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFF;
    margin: -10px 0 0 30px;
  }
  .dropdown a:hover {
    background-color: #EEE;
    color: #666;
  }
  .dropdown > a:hover::after{
    border-top-color: #AAA;
  }
  .dropdown li:first-child a:hover::before {
    border-bottom-color: #EEE;
  }
  .dropdown.hover:hover li {
    height: 40px;
  }
  .dropdown.hover li:first-child{
    // height: auto;
    // display: none;/
  }
  .dropdown.hover:hover li:first-child{
    display: inherit;
    padding-top: 15px;
    height: auto;
  }
}

//New Vertical Progress Bar 10/2/2017
.progress-tracker-hero{
  h1{
    margin-bottom: .5em;
    padding-top: 100px;
    color: #ffffff;
  }
  h2{
    color: #ffffff;
    border-top: 1px solid #fff;
    padding-top: 1em;
  }
  background:linear-gradient(
                  rgba(0, 0, 0, 0.45),
                  rgba(0, 0, 0, 0.45)
  ),url('../img/Progress-Tracker-Hero.jpg');
  background-attachment:fixed;
  background-size:cover;
  height:400px;
}
#concept-detail-wrapper > .vertical-progress-container > .progress-tracker-meter > .progress-point.done p{
  top: 5px;
}
.eDesign-progress-tracker-container{
  margin: 0 5em 45em 5em;
  padding: 0;
  display: inline-block;
  @media (max-width: 768px) {
    margin: 0 5em 70em 5em;
  }
  .form-required {
    color: #ff0000;
  }
  .date-padding {
    float: none;
  }
  #edit-concept-pencil{
    cursor: pointer;
  }
  h1{
    font-family: $font-family-sans-serif;
    color: #545f64;
    margin: 1em 0;
  }
  h3{
    margin-top: 0;
  }
  .card{
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    border: 1px solid #dadada;
    padding: 1em;
    &.col-sm-6 {
      width: 50%;
    }
  }

  .vertical-progress-container {
    // box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    border: 1px solid #dadada;
    padding: 1em .25em 1em 1em;
    margin-bottom: 3em;

    .progress-tracker-header{
      h3 > a{
        cursor: pointer;
        font-family: Helvetica;
      }
      p{
        padding: 0 .25em;
        font-size: 16px;
        color: #4a77bb;
        font-weight: 500;
      }
      .contact{
        padding: 0 1em;
        font-weight: 400;
        font-size: 14px;
        // float: right;
        span{
        }
      }
    }
    .progress-tracker-meter {
      margin-top: 1em;
      padding: 0;
      .progress-point{
        position: relative;
        font-size: 16px;
        margin-left: 10px;
        padding-bottom: 50px;
        text-indent: 25px;
        min-height: 100%;
        border-left-width: 4px;
        border-left: 4px solid;

        // margin-bottom: 1em;
        span{
          display: inline-block;
          margin: 0 0 0 5px;
          padding: 0;
          padding-right: 10px;
          text-indent: -17px;

          // padding-right: 5em;
          // word-wrap: break-word;
        }
        a{
          position: absolute;
          top: 0;
          left: 30px;
          // padding-bottom: 2em;
          cursor: pointer;
        }
        i{
          position: relative;
          top: 0;
          right: 0;
          font-size: 24px;
          // float: right;
          padding-left: 1em;
        }
        .tooltip.top > .tooltip-inner {
          margin-left: -15px;
          text-indent: 0px;
          width: 75px;
        }
        .tooltip.top > .tooltip-arrow{
          // margin-left: -15px;
        }
        p{
          position: relative;
          top: 10px;
          padding-left: 40px;
          padding-right: 25px;
          text-indent: 0;
          font-size: 14px;
          color: #545f64;
        }
        @media only screen and (max-width: 1200px) {
          p{
            margin-top: 25px;
          }
        }
        .user-comments{
          margin-top: 1em;
          font-weight: 500;
          span{
            font-weight: 400;
            margin-left: 1.5em;
            text-indent: 0;
            padding: 0;
            // left: 0;
            width: auto;
          }
        }
        .attachments{
          margin-top: 1em;
          font-weight: 500;
          a{
            position: relative;
            margin: 0;
            padding: 0;
            left: 0;
            width: auto;
            i{
              position: relative;
              padding: 0 .25em;
              clear: both;
              font-size: 18px;
            }
          }
          .tooltip > .tooltip-inner{
            width: auto !important;
          }
        }
        .date{
          position: relative;
          color: #545f64;
          font-size: 11px;
          float: right;
          margin-top: 10px;
          padding-bottom: 10px;
          padding-right: 5px;
          margin-left: 25px;
          text-indent: -17px;
          // margin-bottom: 3em;
        }
        .date.completed:before{
          content: "Completed on: ";
          font-weight: 400;
        }
        .date:before{
          content: "Suggested completion: ";
          font-weight: 400;
        }
      }
    }
    .progress-tracker-meter {
      padding-bottom: 10px;
      list-style-type: none;
    }
    .progress-tracker-meter .progress-point:before {
      position: relative;
      float: left;
      text-indent: -13px;
    }
    .progress-tracker-meter .progress-point.done:before {
      content: "\2713";
      height: 20px;
      width: 20px;
      margin-left: -12px;
      border: none;
      border-radius: 20px;
      text-indent: 4px;
      line-height: 20px;
    }
    .progress-tracker-meter .progress-point.todo {
      font-size: 15px;
      .date{
        font-style: italic;
      }
    }
    .progress-tracker-meter .progress-point.todo > p {
      color: #c6c6c5;
    }
    .progress-tracker-meter .progress-point.todo::before{
      content: '';
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-left: -12px;
      border: none;
      border-radius: 20px;
      text-indent: 4px;
      line-height: 20px;
      background-color: #c6c6c5;
    }
    .progress-tracker-meter .progress-point.done {
      border-left-color: #79ac42;
      .date{
        margin-top: .5em;
      }
      .attachments{
        font-size: 15px;
      }
      .milestone-comments > span{
        margin: 0;
        padding: 0;
        display: inline;
        font-size: 15px;
        font-weight: 500;
      }
    }
    .progress-tracker-meter .progress-point.done > p{
      color: #c6c6c6;
      top: 20px;
    }
    .progress-tracker-meter .progress-point.done > .milestone-comments, .progress-tracker-meter .progress-point.done > .attachments{
      top: 5;
      color: #545f64;
    }
    .progress-tracker-meter .progress-point.finished{
      // height: 25px;
      padding-bottom: 0;
      span{
        font-size: 24px;
        margin-top: 1px;
        position: absolute;
      }
      span:hover{
        cursor: default;
        color: #c6c6c5 !important;
      }
    }
    .progress-tracker-meter .progress-point.finished:after{
      // height: 25px;
      padding-bottom: 0;
      span{
        font-size: 24px;
        margin-top: 1px;
      }
      span:hover{
        cursor: default;
        color: #4a77bb !important;
      }
    }
    .progress-tracker-meter .progress-point.done:before {
      color: #ffffff;
      background-color: #79ac42;
    }
    .progress-tracker-meter .progress-point.todo {
      color: #c6c6c5;
      border-bottom-color: #c6c6c5;
    }
    .progress-tracker-meter .progress-point.todo:before {
      color: #c6c6c5;
    }
    .progress-tracker-meter .progress-point.todo> span > a{
      color: #c6c6c5 !important;
    }
    .progress-tracker-meter .progress-point.todo> span > a:hover{
      color: #79ac42 !important;
    }

    // Compare Concept View Compared Company Prog points and lines
    .progress-tracker-meter .progress-point.done.blue:before{
      color: #ffffff;
      background-color: #4a77bb;
    }
    .progress-tracker-meter .progress-point.done.blue {
      border-left-color: #4a77bb;
    }
  }
  .progress-tracker-collection{
    h3{
      margin: 0;
      height: 47px;
    }
    h4{
      margin: 1.5em 0 .5em 0;
    }
    h5{
      margin-bottom: 1em;
      padding: 0 1em;
      font-weight: 400;
    }
    a{
      cursor: pointer;
    }
    a:hover{
      cursor: pointer;
    }
    .company-concept-container{
      margin-bottom: 1em;
      h3{
        margin: 0 0 1em 0;
        height: auto;
        a{
          font-family: sans-serif;
        }
      }
      hr{
        width: 75%;
        color: #f1f1f1;
        margin: 2em auto;
      }
      .company-concept-progress{
        padding: 0 1em;
        margin-bottom: 1.5em;
        h3{
          font-size: 18px;
        }
        .contact{
          font-size: 14px;
        }
      }
    }


    // Color Options for grouping the Overview view companys
    //Blue Bubbles
    .progress-indicator.blue > li.active{
      color: rgb(74, 119, 187);
    }
    .progress-indicator.blue > li.active > span{
      background-color: rgb(74, 119, 187);
      color: rgb(74, 119, 187);
    }
    .progress-indicator.blue > li.active > .bubble:after{
      background-color: rgb(74, 119, 187);
    }
    //Orange Bubbles
    .progress-indicator.orange > li.active{
      color: rgb(247, 147, 30);
    }
    .progress-indicator.orange > li.active > span{
      background-color: rgb(247, 147, 30);
      color: rgb(247, 147, 30);
    }
    .progress-indicator.orange > li.active > .bubble:after{
      background-color: rgb(247, 147, 30);
    }
    //LightBlue Bubbles
    .progress-indicator.lightblue > li.active{
      color: rgb(109, 156, 179);
    }
    .progress-indicator.lightblue > li.active > span{
      background-color: rgb(109, 156, 179);
      color: rgb(109, 156, 179);
    }
    .progress-indicator.lightblue > li.active > .bubble:after{
      background-color: rgb(109, 156, 179);
    }
    //LightGreen Bubbles
    .progress-indicator.lightgreen > li.active{
      color: rgb(211, 223, 189);
    }
    .progress-indicator.lightgreen > li.active > span{
      background-color: rgb(211, 223, 189);
      color: rgb(211, 223, 189);
    }
    .progress-indicator.lightgreen > li.active > .bubble:after{
      background-color: rgb(211, 223, 189);
    }
    //Red Bubbles
    .progress-indicator.red > li.active{
      color: rgb(220, 87, 87);
    }
    .progress-indicator.red > li.active > span{
      background-color: rgb(220, 87, 87);
      color: rgb(220, 87, 87);
    }
    .progress-indicator.red > li.active > .bubble:after{
      background-color: rgb(220, 87, 87);
    }
    .steps-completed-counter{
      float: right;
      color: #4a77bb;
      margin-right: .5em;
      font-weight: 500;
    }
    .progress-indicator{
      margin: 0 0 .5em 0;
    }
  }

  // Compare Concept View
  .compare-concepts-container{
    #concept-detail-wrapper{
      margin-bottom: 3em;
      .vertical-progress-container{
        margin-bottom: 0;
      }
    }
    .fa{
      margin: 0;
    }
    .dropdown-container-left{
      // margin-bottom: 1em;
      margin-bottom: 5px;
      .sort-by{
        display: inline-block;
        margin-left: -2em;
      }
    }
    .dropdown-container-right{
      // margin-bottom: 1em;
      margin-bottom: 5px;
      .sort-by{
        float: right;
        margin-right: -1.5em;
      }
    }
    .my-utility{
      margin-left: -1em;
    }
    .their-utility{
      margin-left: 1em;
    }
    .vertical-progress-container{
      box-shadow: none;
      border: none;
    }
  }
  // Detail View
  .concept-detail-view{
    padding: 2em;
    .col-sm-9{
      border-right: 1px solid #dadada;
    }
    .col-sm-3{
      .progress-tracker-meter{
        li{
          position: relative;
          font-size: 16px;
          // margin-left: 10px;
          margin-top: 2em;
          padding-bottom: 50px;
          text-indent: 25px;
          min-height: 100%;
          i{
            color: #79ac42;
          }
        }
      }
    }
  }
  // Progress Tracker Forms
  #es-progress-tracker-add-milestone-form{
    h2{
      margin: 1em 0 10px 0;
    }
  }
  #es-progress-tracker-edit-concept-form{
    .cancel-button{
      float: right;
    }
  }
  #es-progress-tracker-create-concept-form{
    button{
      margin-bottom: 3em;
    }
  }
  #es-progress-tracker-add-milestone-form > div > div.container-inline-date{
    margin-bottom: 15px;
  }
  // Mobile Wide
  @media only screen and (max-width: 667px) {
    #es-progress-tracker-add-milestone-form{
      padding-bottom: 3em;
      margin: 1em 1em 3em 1em;
    }
  }
  // Mobile Portrait
  @media only screen and (max-width: 375px) {
    #es-progress-tracker-add-milestone-form{
      margin: 0 1em;
      padding-bottom: 3em;
    }
  }
}
@media only screen and (max-width: 1175px) {
  .progress-tracker-hero{
    // height: auto;
    padding-bottom: 25px;
    width: 100%;
    padding-top: 25px;
  }
  body > div.main-wrapper > div > div.container-fluid.intro.progress-tracker-hero > div > div{
    width: 100% !important;
  }
}
@media only screen and (max-width: 1024px){
  .progress-tracker-hero{
    height: 400px;
    background-position: top center;
    background-attachment: scroll;
    // background-size: 100%;
  }
}
@media only screen and (max-width: 667px){
  .progress-tracker-hero{
    h1{
      margin-top: 0;
    }
    height: 400px;
    background-position: top center;
    background-attachment: scroll;
    background-size: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .progress-tracker-hero{
    h1{
      margin-top: 0;
      margin-bottom: .5em;
      color: #ffffff;
    }
    h2{
      color: #ffffff;
      border-top: 1px solid #fff;
      padding-top: 1em;
    }
    background:linear-gradient(
                    rgba(0, 0, 0, 0.45),
                    rgba(0, 0, 0, 0.45)
    ),url('../img/Progress-Tracker-Hero.jpg');
    height:350px;
    width: 375px;

    background-attachment: scroll;
    background-size: auto 100%;
  }
  body > div.main-wrapper > div > div.container-fluid.intro.progress-tracker-hero > div > div{
    width: 100% !important;
  }
  .eDesign-progress-tracker-container{
    margin: .5em .5em 100% .5em;
    padding: 0;
  }
}
