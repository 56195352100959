.node-type-marketing-pages {
  font-family: Helvetica, Arial, sans-serif;
  color: $es-dk-gray;
  color: #545f64;
  font-size: 1.75em;
  line-height: 1.6667em;
  h2 {
    margin-bottom: 30px;
  }
  .es-lead-image.es-lead-image-full {
    height: 650px;
    display: flex;
    position: relative;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    &:before{
      background: #000;
      opacity: 0.5;
      content: "";
      height: 100%;
      left: auto;
      right: auto;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .row {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 110%;
      background-color: rgba(0, 0, 0, 0.5);
      @supports(filter:brightness(0.5)){
        background-color: transparent;
        width: 100%
      }
    }
    @media screen and (max-width: 1024px) {
      background-attachment: scroll;
      background-size: auto 100%;
    }
    @media (max-width: 440px) {
      height: 80vh;
    }
    h1 {
      color: #fff;
      line-height: 1.4; 
      margin-bottom: .5em;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      @media (max-width: 980px) {
        font-size: 45px;
      }
      @media (max-width: 500px) {
        font-size: 37px;
      }
    }
    h2 {
      border-top: #fff 1px solid;
      color: #fff;
      font-weight: 300;
      line-height: 1.618;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      opacity: .9;
      padding-top: .5em;
      width: calc(100% - 25%);
      @media (max-width: 980px) {
        font-size: 30px;
      }
      @media (max-width: 500px) {
        font-size: 25px;
        width: calc(100% - 15%);
        line-height: 1.4;
      }
    }
  }
  .btn {
    border-radius: 5px;
  }
  .intro {
    padding-top: 100px;
    padding-bottom: 20px;
    h1, h2 {
      color: #fff;
    }
    .subtitle {
      font-size: 28px;
       border-top: 1px solid #fff;
       padding-top: 1em;
    }
  }
 .bg-blue {
   .btn {
     color: #4a77bb;
     background-color: #ffffff;
     border: 3px #4a77bb solid;
     &:hover {
       color: #ffffff;
       background-color: #4a77bb;
       border: 3px #ffffff solid;
     }
   }
 }
 .bg-lt-gray {
   background-color: #f1f1f1;
   padding-bottom: 1em;
   padding-top: 1em;
 }
 .fluid-header-image {
   overflow: hidden;
   max-height: 300px;
   .for-utitlies{
     background-image: url('/sites/default/files/for-utilites-header.jpg')
   }
   img {
     padding: 0;
   }
 }
 .tab-content.mainContent  .tab-pane,
 .pill-content  .pill-pane,
 .servicePanels .tab-pane {
   display: block;
   height: 0;
   overflow: hidden;
 }
 .tab-content.mainContent .active,
 .pill-content .active,
 .servicePanels .active {
     height: auto;
 }
 .sidebar{
   .btn-block{
     white-space: normal;
   }
 }
 iframe {
   margin: 15px auto 30px;
   display: block;
 }

 #page-header {
   padding-top: 20px;
   padding-bottom: 30px;
   background-color: $es-lt-blue;
   box-shadow: 8px 0px 11px $es-gray-06 inset;
   z-index: 0;
   margin-left: -30px;
   h2 {
     color: $white;
   }
 }
 .region-content {
   article {
     header {
       display: none;
     }
   }
 }
 #block-views-bios-block-1 {
   .block-title {
     text-align: center;
   }
 }
 .intro {
   margin-top: 15px;
 }
 .intro2 p {
   margin-top: 25%;
 }
 .graphics {
   margin-bottom: 30px;
 }
 .alignment-b {
   background-color: #DADADA;
 }
 .row-flex {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
 }
 .zoom {
   img {
    min-height: 240px;
   }
 }
 .selectors {
   .nav {
     margin: 30px 0 0;
     text-align: center;
     @media (max-width: $screen-sm-min) {
       text-align: left;
     }
     li {
       display: inline;
       float: none;
       @media (max-width: $screen-sm-min) {
         display: block;
       }
       a {
         display: inline-block;
       }
     }
   }
   .nav-pills a{
     color: $es-lt-blue;
     font-size: $font-size-h4;
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0;
     width: 20%;
     @media (max-width: $screen-md-min) {
       width: 24%;
     }
     @media (max-width: $screen-sm-min) {
       width: 50%;
       border-bottom-left-radius: 4px;
       border-bottom-right-radius: 4px;
     }
   }
   .nav-pills a:hover {
     background: transparent none;
   }
   > .nav-pills > li.active > a,
   > .nav-pills > li.active > a:hover,
   > .nav-pills > li.active > a:focus {
     background-color: $white;
     color: $es-dk-gray;
     position: relative;
     box-shadow: 0px -1px 1px -1px $es-gray-03;
   }
   .round-pills {
     margin: 15px 0;
     li {
       float: left;
       display: block;
     }
   }
   .round-pills a{
     color: $es-green;
     font-size: 16px;
     border-bottom-left-radius: 5px;
     border-bottom-right-radius: 5px;
     width: inherit;
   }
   .round-pills a:hover {
     background: transparent none;
   }
   > .round-pills > li.active > a,
   > .round-pills > li.active > a:hover,
   > .round-pills > li.active > a:focus {
     background-color: $es-dk-gray;
     color: #fff;
     position: relative;
     box-shadow: 0px -1px 1px -1px $es-gray-03;
   }
 }
 .flat-tabs {
   .selectors {
     .nav-pills {
       a:hover {
         color: #587f91;
       }
     }
   }
 }
 .tab-rows {
   background-color: $es-off-white;
   box-shadow: -1px 1px 6px $es-gray-03 inset;
 }
 #working-boulder {
   position: relative;
 }
 .img-overlay-container{
   position: relative;
   img{
     transition: all 0.3s;
     -moz-transition: all 0.3s;
     -webkit-transition: all 0.3s;
     top: 0;
     left: 0;
     width: 100%;
     height: auto;
     max-width: none;
   }
   .img-mask {
      height: 225px;
      overflow: hidden;
      a:hover {
        .zoom-text {
          display: none;
        }
      }
   }
   .zoom-text {
     bottom: 5%;
   }

   img:hover{
     transform: scale(1.1);
     -moz-transform: scale(1.1);
     -webkit-transform: scale(1.1);
     filter: blur(5px);
     -moz-filter: blur(5px);
     -webkit-filter: blur(5px);
   }
   .col-sm-6{
     margin: 1em 0 0 0;
   }
 }
 .img-overlay-description {
   position: absolute;
   top: 1em;
   right: 1em;
   left: 1em;
   height: 200px;
   text-align: center;
   color: #545f64;
   visibility: hidden;
   opacity: 0;
   transition: opacity .2s, visibility .2s;
   line-height: 1.75em;
   margin: 0 auto;
   padding: 1em;
   font-size: 14px;
   font-weight: 400;
   background: rgba(255, 255, 255, 0.85);
   border-radius: 3px;
   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
 }
 .img-overlay:hover img{
   transform: scale(1.1);
   -moz-transform: scale(1.1);
   -webkit-transform: scale(1.1);
   filter: blur(5px);
   -moz-filter: blur(5px);
   -webkit-filter: blur(5px);
 }
 .gradient-container {
   bottom: 0;
   height: 400px;
   position: absolute;
   width: 100%;
   background-color: rgba(0, 0, 0, 0.9);
   background: -moz-linear-gradient(8deg, rgba(0, 0, 0, 0.9), transparent, transparent) !important;
   background: linear-gradient(8deg, rgba(0, 0, 0, 0.9), transparent, transparent) !important;
 }
 .img-overlay:hover .img-overlay-description {
   visibility: visible;
   opacity: 1;
 }
 .img-overlay {
   position: relative;
   max-width: 375px;
   max-height: 325px;
   margin: 0 auto;
   overflow: hidden;
 }
 .gray-img-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(199, 198, 197, 0.85);
   color: #fff;
   padding: 35% 41%;
 }
 .gray-img-overlay > div {
   transition: transform .8s ease-in-out;
   -webkit-transition: -webkit-transform .8s ease-in-out;
   -ms-transition: -ms-transform .8s ease-in-out;
 }
 .gray-img-overlay:hover > div{
   transform: scale(1.5);
   transform: rotate(360deg);
 }
 .img-overlay-coming-soon, .img-overlay-see-more{
   position: absolute;
   top: 85%;
   right: 5%;
   color: #fff;
   visibility: hidden;
   opacity: 0;
   transition: opacity .2s, visibility .2s;
   margin: 0 auto;
   padding: 0;
   font-size: 18px;
   font-weight: 200;
 }
 .gray-img-overlay:hover .img-overlay-coming-soon, .img-overlay:hover .img-overlay-see-more{
   visibility: visible;
   opacity: 1;
 }
 .gray-img-overlay:hover{
   visibility: visible;
   opacity: 1;
   cursor: default;
 }
 .img-overlay-see-more:hover{
   text-decoration: underline;
 }
 .img-overlay-label {
   max-width: 375px;
   text-align: center;
   margin: 0 auto;
   h3{
     margin: .5em auto 1.5em auto;
   }
 }
 .img-overlay-icon {
   max-width: 300px;
   max-height: 200px;
   height: 200px;
   overflow: hidden;
   padding: 0;
   text-align: center;
   margin-bottom: 1em;
   background-color: #f1f1f1;
   //border: 3px solid #fff;
   a {
     display: block;
     position: relative;
     &:hover {
       text-decoration: none;
     }
   }
   .overlay{
     position:absolute;
     top:0px;
     left:0px;
     bottom:0px;
     right:0px;
     background-color:rgba(0, 0, 0, 0.15);
     height: 100%;
     width: 100%;
     .fa {
       display: none;
     }
     h3{
       padding-top: 25%;
       padding-bottom: 25%;
       a{
         color: #fff;
       }
     }
     &:hover{
       .fa {
         display: block;
         padding: 25%;
         color: #ffffff;
       }
     }
   }
   img {
     width: 100%;
     height: 100%;
     transition: all 0.3s;
   }
   &:hover {
     //border: 3px solid #f1f1f1;
     img {
       -moz-transform: scale(1.1);
       -webkit-transform: scale(1.1);
       transform: scale(1.1);
       filter: blur(5px);
     }
   }
 }

 .employee-img-container {
  .img-mask {
   position: relative;
   overflow: hidden;
   height: 100%;
   width: 100%;
   min-height: 400px;
   margin-bottom: 2em;
   @media (max-width: 570px) {
     min-height: 250px;
     margin-bottom: 1em;
   }
   img {
     width: auto;
     height: auto;
     max-width: 100%;
     min-height: 100%;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translateX(-50%) translateY(-50%);
     z-index: 0;
     transition: all 0.5s ease-out;
   }
   @supports ( object-fit: cover ) {
     img {
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
       object-fit: cover;
       transform: none;
     }
   }
  }
}
}
