.testimonial-photo {
  margin: 1em auto;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border-radius: 200px;
}
.testimonial-quote:before{
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.15em;
  vertical-align: -0.4em;
}
.testimonial-quote:after{
  color: #ccc;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.15em;
  vertical-align: -0.4em;
}
.is-table-row {
  display: table;
  [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
  }
}
