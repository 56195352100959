/* Images */

// Image zoom hovers
//
// Markup:
//<div class="row">
//  <div class="col-md-6">
//    <a href="#" target="_blank">
//      <div class="zoom">
//        <img src="/sites/all/themes/es_bootstrap/img/house-with-solar.jpg">
//        <div class="gradient-container">
//          <h3 class="zoom-text">Energy-Efficiency Programs Positively Affect Customer Satisfaction and Brand Perception</h3>
//        </div>
//      </div>
//    </a>
// </div>
// <div class="col-md-6">
//   <a href="#" target="_blank">
//     <div class="zoom">
//       <img src="/sites/all/themes/es_bootstrap/img/house-with-solar.jpg">
//       <div class="gradient-container">
//         <h3 class="zoom-text">Energy-Efficiency Programs Positively Affect Customer Satisfaction and Brand Perception</h3>
//       </div>
//      </div>
//    </a>
//  </div>
//<div>
//
// Style guide: Images.zoom_hovers

//zoom images not in a carousel
.zoom {
  position: relative;
  overflow: hidden;
  margin-bottom: 1em;

  img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  &:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    }
  &:hover {
      cursor: pointer;
    h3, h2{
      display: none;
    }
  }
  .gradient-container, .gradient-container-readmore {
    bottom: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    background: -moz-linear-gradient(8deg, rgba(0, 0, 0, 0.9), transparent, transparent) !important;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)) 0% 0% !important;
  }
  &:after {
    position: absolute;
    background-color: rgba(44,49,52,.6);
    content: 'Download';
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-top: 30%;
    text-align: center;
    font-size: 28px;
    width: 100%;
    height: 100%;
    color: #fff;
    top:  0;
    left: 0;
    opacity: 0;
    transition: opacity .7s linear;
    box-sizing: border-box;
  }
  &:hover:after {
      opacity: 1;
  }
  .util-logo {
    background-color: #fff;
    width: 120px;
    left: 40%;
    position: absolute;
    top: 0;
  }
}
.zoom.readmore {
  &:after {
    content: 'Read more';
  }
}
.not-logged-in.front .reg-height {
  .zoom-text {
    &.zoom-text{
      @media (max-width: 1024px) {
        font-size: 110%;
        line-height: 120%
      }
    }
  }
}
.zoom-text {
  bottom: 0;
  color: #fff;
  font-size: 26px;
  font-family: $font-family-sans-serif;
  padding: 0 .5em;
  position: absolute;
  text-shadow: 0 0 120px rgba(0,0,0,.75);
  width: 100%;
  &.zoom-text{
    color: $white;
    @media (max-width: 1200px) {
      font-size: 100%;
    }
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
}
.zoom.readmore {
  &:after {
    content: 'Read more';
  }
}

.lg-img-mask {
  border: 5px solid #fff;
  border-radius: 150px;
  height: 140px;
  margin-top: 0;
  margin: 0 auto;
  overflow: hidden;
  width: 140px;
}
.circle-lt-gray-bg, .circle-dk-gray-bg {
  border-radius: 120px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
}
.circle-lt-gray-bg {
  background-color: #f1f1f1;
  color: $es-dk-gray;
}
.circle-dk-gray-bg {
  background-color: $es-dk-gray;
  color: $white;
  font-size: 30px;
}
#es-timeline-img{
  margin-top: 30px;
  margin-bottom: 30px;
  @media(max-width: 990px){
    display: none;
  }
}
