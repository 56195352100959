// Homepage styles
@-webkit-keyframes fade_move_down {
  0% {
   -webkit-transform:translate(0, -10px);
   opacity: 0;
  }
  50% {
     opacity: 1;
  }
 100% {
    -webkit-transform:translate(0, 10px);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
   0% {
    -moz-transform:translate(0, -10px);
    opacity: 0;
  }
 50% {
   opacity: 1;
 }
 100% {
   -moz-transform:translate(0, 10px);
   opacity: 0;
 }
}
@keyframes fade_move_down {
 0% {
   transform:translate(0, -10px);
   opacity: 0;
}
 50% {
   opacity: 1;
}
 100% {
  transform:translate(0, 10px);
  opacity: 0;
  }
}
.not-logged-in.front {
  h1, h2, h3, h4, h5 {
    font-family: $font-family-sans-serif;
    // color: $es-dk-gray;
    font-weight: 500;
  }
  // .container-fluid .container {
  //   padding-left: inherit;
  //   padding-right: inherit;
  // }
  .greeting {
    // background-image: url('/sites/all/themes/es_bootstrap/img/power-lines.jpg');
    box-shadow: 0 0 11px $es-dk-gray inset;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
    
    .home-hero {
      width: auto;
      height: auto;
      max-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 0;
      transition: all 0.5s ease-out;
      
      @supports ( object-fit: cover ) {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transform: none;
      }
    }
    
    @media screen and (max-width: 1024px) {
      background-attachment: scroll;
    }
    @media(max-width : 1024px){
  		height: 750px;
      min-height: 750px;
    }
    @media (max-width : 768px){
    	height: 800px;
      min-height: 800px;
    }
    @media (max-width: 414px){
        height: 950px;
        min-height: 950px;
    }
    @media (max-width: 320px){
        height: 700px;
        min-height: 700px;
    }
    .vertical-center {
      @media (max-width: 1350px) {
        top: 45%;
      }
      @media (max-width: 1024px) {
        top: auto;
      }
      @media (max-width: 768px){
        padding-top: 15%;
      }
      @media (max-width: 414px){
        padding-top: 20%;
      }
    }
    .title-logo-container{
      width: 85%;
      margin: 0 auto;
      @media (max-width: 992px) {
        margin: 0em auto 0 auto;
      }
      @media (max-width: 767px) {
        display: none;
      }
      svg {
        width: 50%;
        @media (max-width: $screen-sm) {
          margin: 0em auto 0 auto;
          width: 70%;
        }
        @media (max-width: $screen-xs) {
          margin: 0em auto 0 auto;
          width: 60%;
          display: none;
        }
      }
    }
    #title-logo {
      font-size: 48px;
      font-weight:100;
      margin:1em 0 1em .5em;
      display:inline;
      vertical-align:middle;
      color:white;
    }
    #tagline{
      font-size: 50px;
      color:white;
      font-weight:300;
    }
    .homepage-headline{
      font-size: 5vh
    }
    #user-login {
      height: 65%;
      width: 65%;
      margin: 0 auto;
      background: rgba(255,255,255,0.68);

      label{
        font-weight: 300;
      }
      .btn {
        @extend .btn-lg
      }
    }
    @media (max-width: $screen-md) {
      #user-login {
        height: 75%;
        width: 75%;
      }
    }
    #user-login-header {
      margin:.5em 0;
      font-size:19px;
      color: $es-dk-gray;
      font-weight:300;
    }
    #edit-actions {
      text-align: center;
    }
    #edit-submit--2 {
      @extend .btn-success;
      width: 75%;
      margin: 0 auto;
    }

    .user-login-cta {
      color: black;
      font-size: 13px;
      line-height: 1em;
    }

// company announcement hero changes start -JD

    // black to transparent gradient overlay
    &:before {
      content: '';
      background: linear-gradient(rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.26) 100%);
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    // hero content module
    .hero-content {
      z-index: 1;
      transform: none;
      top: auto;
      position: relative;
      margin-top: 25vh;

      .title-logo-container, .tag-line {
        text-align: left;
      }
      .title-logo-container {
        margin: 0;
      }
      .tag-line {
        opacity: 1;
      }

      @media (max-width: 1024px) {
        padding: 0;
      }
      @media (max-width : 768px) {
        padding: 0 1em;
        margin-top: 20vh;
      }
    }
    // announcement callout section
    .hero-callout-wrapper {
      margin-top: auto;
    }
    .hero-callout {
      z-index: 1;
      padding: 0;
      padding-left: 1.75em;
      position: absolute;
      bottom: 0;
      right: 0;

      &__inner {
        color: $es-dk-gray;
        text-align: left;
        background-color: #fff;
        padding: 2em;

        h2, h3, h4 {
          color: $es-dk-gray;
          position: relative;
          padding-bottom: 0.5em;
          margin-bottom: 1em;
          margin-top: 0;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 85px;
            height: 4px;
            background-color: $es-green;
          }
        }
      }
      @media (max-width: 1024px){
        padding-left: 0;
        position: relative;
      }
      // @media (max-height: 670px){
      //   left: 0;
      //   padding-left: 0;
      //   padding-right: 10px;
      // }
    }
    // height override
    &.full-viewport-height {
      margin: 0;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      @media (max-width: 1024px){
        height: 100vh;
      }
    }
  }
  // "How we deliver" section updates
  .service-offerings {
    .row {
      display: flex;
      align-items: stretch;

      @media (max-width: 992px){
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
       }
    }
    .col-md-4 {
      margin: 2em 0;
      border-right: 1px solid #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;

      a.btn {
        margin-top: auto;
      }
      &:last-child {
        border-right: none;
      }

      @media (max-width: 992px){
       border-right: none;
      }
    }
  }
  // company announcement hero changes end -JD
  .well {
    opacity: .9;
    background-color: #ffffff;
  }
  .subscribe-container{
    padding: 0;
    background-color: #F2F2F2;
    vertical-align: middle;
    font-weight: 300;
    background-image: url('/sites/all/themes/es_bootstrap/img/woman-smartphone_shadow.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 -15px;

    @media screen and (max-width: 767px) {
      background-attachment: scroll;
    }

    .col-sm-12{
      margin: 1em 0;
      padding: 1em 0;
      vertical-align: middle;
    }
    h1, h2, h3, h4, h5, h6{
      font-weight: 300;
    }
    @media screen and (max-width: 667px){
      height: 100%;
    }
    input {
      font-size: 18px;
      border-radius: 3px;
      box-shadow: 0 2px 2px #545f64;
    }
    .subscribe-wrapper {
      margin: 1em auto;
      vertical-align: middle;
      form{
        display: inline-block;
      }
      form input,
      form input:focus {
        padding: 10px 13px;
        border: 0;
        outline: 0;
      }
      form input[type="text"] {
        width: 325px;
        color: #a09f9f;
        margin: .5em 0 .75em 0;
        -webkit-border-radius: 10em;
        -moz-border-radius: 10em;
        border-radius: 10em;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
      }
      @media screen and (max-width: 667px){
        form input[type="text"] {
          width: 200px;
          color: #a09f9f;
          margin-bottom: .5em;
          -webkit-border-radius: 10em;
          -moz-border-radius: 10em;
          border-radius: 10em;
          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          transition: all .5s;
        }
      }
      form input[type="text"]:hover,
      form input[type="text"]:focus{
        width: 450px;
      }
      @media screen and (max-width: 667px){
        form input[type="email"]:hover,
        form input[type="email"]:focus{
          width: 275px;
        }
      }
      button[type="submit"] {
        padding: 10px 15px;
        height: 45px;
        width: 125px;
        transition: all .3s ease-in-out;
      }
      button[type="submit"]:hover,
      button[type="submit"]:focus {
        color: white;
        transform: scale(1.1);
      }
    }
  }
  .reg-height {
    margin: 0 -15px;
    padding: 0 15px;
  }
  .home-content-blocks {
    min-height: 600px;
    position: relative;
    @media (max-width: 1024px) {
      background-attachment: scroll;
      background-size: auto 100%;
    }

    @media (max-width: 992px) {
      min-height: 400px;
    }
  }
  .full-viewport-height, .subscribe-container, footer, .reg-height{
    h4{
      color: #fff;
    }
    h2{
      padding: .25em 0;
      margin: .5em;
      color: #fff;
      margin: 0 auto;
      font-weight: 300;
      font-size: 40px;
      margin-bottom: 0;
    }
    h3{
      color: #fff;
      font-size: 30px;
      // font-weight: 100;
      line-height: 40px;
    }
    h1.tag-line {
      color: #fff;
      font-size: 50px;
      font-weight: 500;
      line-height: 1.2;
      color: #fff;
      margin-bottom: 1em;
      margin-top: 0;
      @media (max-width : 768px) {
        font-size: 35px;
        line-height: 1.4;
        text-shadow: 0px 0px 5px #000;
      }
    }
  }
  .search-bar {
    margin-top: 0;
  }

  .what-we-do {
    background-image:url('/sites/default/files/people-meeting-at-window-new.jpg');
    background-attachment: fixed;
    background-size: cover;
    transition: background-image 1s ease-in-out;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;;
      width: 100%;
      height: 100%;
      min-height: 600px;
      background-color: rgba(0,0,0,0.5);
      z-index: 0;
    }

    @media screen and (max-width: 1024px) {
      background-attachment: scroll;
      background-size: auto 100%;
    }
    .icon-container{
      margin-top: 1em;
    }
  }
  .what-working-on {
    .block-title {
      margin-bottom: 1em;
      text-align: center;
    }
    @media (min-width: 1024px){
      #slick-views-anonymous-homepage-content-slider-1 .zoom h3.zoom-text{
        font-size: 1.5vw;
        line-height: 2vw;
      }
    }
  }
  footer.main-footer {
    margin: 0;
    width: auto;
  }
}
