.btn-box{
  color: $es-dk-gray;
  cursor: pointer;
  float: left;
  height: 100%;
  min-height: inherit;
  width: 100%;

  .btn {
    bottom: 1em;
    position: inherit;
    text-shadow: none;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    p {
      line-height: 16px;
    }
  }
  .circle-img-wrapper {
    float: left;
    width: 40%;
  }
  .circle-img-mask, .text-group {
    float: left;
    &:after {
      clear: both;
      content: ".";
      height: 0;
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }
  .circle-img-mask {
    border: 8px solid #dadada;
    border-radius: 200px;
    height: 110px;
    margin: 1.5em auto;
    overflow: hidden;
    width: 110px;
    img {
      margin: -5px;
      width: 110%;
    }
  }
  .text-group {
    width: 60%;
  }
  .name {
    font-size: 1.2em;
    margin-top: 1em;
  }
}
.carrot-container{
  height: 70px;
  position: relative;
  @media (max-height: 630px){
    display: none;
  }
}
.down-carrot-position{
  bottom: 0;
  position: absolute;
  width: 100%;
  @media (max-height: 630px){
    display: none;
  }
}
#down-carrot-link {
  margin: 0 auto;
  position: relative;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
}
#down-carrot-link-icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  background-color: $es-green;
  display: block;
  border-radius: 200px;
  opacity: .8;
  margin: .25em auto;
  color: white;
  -webkit-animation: fade_move_down 2s ease-in-out 20;
  -moz-animation: fade_move_down 2s ease-in-out 20;
  animation: fade_move_down 2s ease-in-out 20;
}
.animation-fade {
  -webkit-animation: fadeInOut 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: fadeInOut 3s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: fadeInOut 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0
}
@supports (display: grid) {
  .btn-box{
    .text-group {
      float: none;
      width: inherit;
    }
    .circle-img-wrapper {
      float: none;
      width: inherit;
    }
    .circle-img-mask{
      float: none;
    }
  }
}

// font-size override
.btn, input.btn[type="button"], #block-feedback-form span.feedback-link {
  font-size: 16px;
}

// button class override
.btn {
  cursor: pointer;
  font-size: 16px;
  padding: 6px 12px !important;
  border-radius: 5px !important;
}

.btn:focus, input.btn:focus[type="button"], #block-feedback-form span.feedback-link:focus, .btn.focus, input.focus.btn[type="button"], #block-feedback-form span.focus.feedback-link, .btn:active:focus, #block-feedback-form span.feedback-link:active:focus, .btn:active.focus, #block-feedback-form span.feedback-link:active.focus, .btn.active:focus, #block-feedback-form span.active.feedback-link:focus, .btn.active.focus, #block-feedback-form span.active.focus.feedback-link, .btn-success:focus, .not-logged-in.front .greeting #edit-submit--2:focus, .btn-success.focus, .not-logged-in.front .greeting .focus#edit-submit--2 {
  background-color: $es-blue;
  outline: 1px solid $es-blue;
  outline-offset: 2px;
}
.btn.btn-info:focus {
  background-color: $es-dk-gray;
}

// Override cursor for homepage contact CTA box
.contact-group .btn-box {
  cursor: auto;
}
