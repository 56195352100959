// Dashboard mixins: for smooth animation transitions on Dashboard Cards
@mixin smooth-transition($seconds){
  transition: all $seconds ease;
  -moz-transition: all $seconds ease;
  -ms-transition: all $seconds ease;
  -o-transition: all $seconds ease;
  -webkit-transition: all $seconds ease;
}
// Dashboard mixins: for blurring background text or material on hover
@mixin blur-content($pixels){
  filter: blur($pixels);
  -moz-filter: blur($pixels);
  -ms-filter: blur($pixels);
  -o-filter: blur($pixels);
  -webkit-filter: blur($pixels);
}
.flex-container {
  display: flex;
  @media(max-width: 1200px) {
    display: block;
    margin: 0 auto;
    width: calc(100% - 2em);
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  .row {
    margin: 0;
  }
}

// Horizontal cards

// Horizontal cards
//
// Markup:
//  <div class="horizontal-card">Here is a card</div>
//
// Style guide: Cards.horizontal

.horizontal-card{
  font-family: Helvetica, Arial, sans-serif;
  padding: 1em;
  cursor: pointer;
  min-height: 250px;
  height: 100%;
  width: 100%;
  margin: 0 0 2em 0;
  background: #ffffff;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  border: 1px solid #dadada;
  overflow: hidden;
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
  position: relative;
  &:hover {
    box-shadow: 0px 15px 62px -7px rgba(0,0,0,0.44);
    -moz-box-shadow: 0px 15px 62px -7px rgba(0,0,0,0.44);
    -webkit-box-shadow: 0px 15px 62px -7px rgba(0,0,0,0.44);
  }
  .img-mask{
    height: 270px;
    display: block;
    position: relative;
    overflow: hidden;

    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      max-width: 100%;
    }
    @media (max-width: 1200px) {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  @media (max-width: 991px) {
    .img-mask {
      height: 170px;
    }
  }
  .title{
    // Truncation in Chrome, esClamp.js for FF
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .field-abstract{
    // Truncation in Chrome, esClamp.js for FF
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  a {
    text-decoration: none;
      &:hover {
          text-decoration: none;
      }
  }
  .body-text{
    margin: 0 1em 0 1em;
  }
  .content-type {
    margin-top: 0;
  }
  &.card-border {
    border-right: 10px solid $es-green;
    &-orange {
      border-right: 10px solid $es-orange;
    }
    &-blue {
      border-right: 10px solid $es-lt-blue;
    }
  }
  a {
    color: initial;
  }
}

//for SLP
//Vertical cards
//
//Markup:
//<div class="card-container">
//  <div class="row">
//    <div class="col-lg-6">
//      <div class="card">
//        <a href="https://iris3-local.esource.com/forum2018">
//          <div class="img-mask">
//            <img class="photo img-responsive" src="https://iris3-local.esource.com/sites/default/files/styles/search_thumbnail/public/people-meeting-at-window.jpg">
//          </div>
//          <div class="title">
//            <h4>E Source Forum 2018</h4>
//          </div>
//          <div class="description">
//            <p>The E Source Forum keeps you current on today’s utility trends, best practices, and critical issues. We blend content, networking, and fun to create a unique atmosphere of collaboration among more than 600 utility professionals…</p>
//          </div>
//          <div class="meta">
//            <span class="content-type">In-person event</span>
//            <span class="timestamp">8 months ago</span>
//          </div>
//        </a>
//      </div>
//    </div>
//  </div>
// </div>
//
// Style guide: Cards.vertical

// new content-type styles
.content-type {
  border-left: 4px solid #545f64;
  font-size: 14px;
  color: #545f64;
  display: block;
  padding: 0 4.5px;
  margin: 1em 1em 1em 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  font-family: Helvetica;
  text-transform: none !important;
  &.new {
    border-color: $es-green;
  }
  &.updated {
    border-color: $es-orange;
  }
}

.card {
  font-family: Helvetica;
  a {
    text-decoration: none;
    width: 100%;
  }
  position:relative;
  min-height: 440px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0 0 2em 0;
  background: #ffffff;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
  border: 1px solid #dadada;
  overflow:hidden;
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;

  .photo {
    width: 100%;
    display: block;
  }
  .img-mask {
    height: 175px;
    overflow: hidden;
    margin-bottom: .75em;
  }
  i.fa-lock{
    line-height: 50px;
    border-radius: 40px;
    background-color: $es-orange;
    top: 175px;
    position: absolute;
    right: 20px;
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
    opacity: .90;
    font-size: 2.5em;
    width: 50px;
    height: 50px;
  }
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(198, 198, 197, 0.25);
    z-index: 10;
  }
  @media only screen and (min-width: 1700px){
    .img-mask{
      height: 185px;
    }
    i.fa-lock{
      top: 185px;
    }
  }
  @media only screen and (max-width: 1367px) {
    .img-mask{
      height: 150px;
    }
    i.fa-lock{
      top: 150px;
    }
  }
  @media only screen and (max-width: 668px) {
    .img-mask{
      height: 180px;
    }
    i.fa-lock{
      top: 180px;
    }
  }
  @media only screen and (max-width: 420px) {
    .img-mask{
      height: 150px;
    }
    i.fa-lock{
      top: 150px;
    }
  }
  .subtitle, .description, .author {
    padding: 0 1.25em;
  }
  .title {
    color: #545f64;
    margin: .5em 0 0 0;
    padding: 0 1em;
    max-width: 100%;
    h4{
      margin: 1em 0 0 0;
      font-size: 15px;
      font-weight: 600;
      width: 100%;
      // Truncation in Chrome, esClamp.js for FF
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
  .subtitle{
    font-size: 12px;
    font-weight: 400;
    margin: .5em 0 0 0;
    opacity: .5;
    color: #545f64;
    max-width: 100%;
    h5 {
      margin: 0;
      font-family: Helvetica;
      // Truncation in Chrome, esClamp.js for FF
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
  .author{
    font-size: 12px;
    margin: 0;
    max-width: 100%;
    p{
      line-height: normal;
      font-family: Helvetica, Arial, sans-serif;
      margin: 0;
      padding: .5em 0 0 0;
      color: #545f64;
      // Truncation in Chrome, esClamp.js for FF
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
  .description {
    font-size: 12px;
    margin: .5em 0;
    color: #888;
    max-width: 100%;
    p {
      font-family: Helvetica;
      line-height: 1.5;
      margin-top: 0;
      // Truncation in Chrome, esClamp.js for FF
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  .meta {
    position: absolute;
    bottom: 0;
    left: 0;

    .content-type{
      font-size: 12px;
      line-height: 18px;
      padding: 0px 4.5px;
    }
    .timestamp {
      font-size: 10px;
      color: #888;
      line-height: 18px;
      display: block;
      margin: 1em;
      padding: 2px 0;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.card:hover{
  box-shadow: 0px 35px 62px -7px rgba(0,0,0,0.44);
  -moz-box-shadow: 0px 35px 62px -7px rgba(0,0,0,0.44);
  -webkit-box-shadow: 0px 35px 62px -7px rgba(0,0,0,0.44);
}
.vertical-cards{
  background-color: #ffffff;
  border-right: 12px solid #545f64;
  height: 485px;
  margin: 1em .5em 2em .5em;
  position: relative;
  width: calc(33.1% - 1em);

  @media (max-width: 992px) {
    width: calc(100% - 1em);
    height: 360px;
  }
  @media (max-width: 768px) {
    width: calc(100% - 1em);
    border-right: 0;
  }
  .field-logo {
    width: 90%;
    margin: 0 auto;
  }

  .vertical-cards-photo {
    border-radius: 200px;
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
    width: 200px;
  }
  .btn {
    bottom: 1em;
    left: 35%;
    position: absolute;
  }
  .well {
    height: 500px;

    @media (max-width: 992px) {
      height: 400px;
    }
    @media (max-width: 992px) {
      height: 345px;
    }
  }
  .vertical-cards-quote{
    font-size: 22px;
    line-height: 35px;
    &:before{
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.15em;
      vertical-align: -0.4em;
    }
    &:after{
      color: #ccc;
      content: close-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-left: 0.15em;
      vertical-align: -0.4em;
    }
  }
  .field-logo{
    height: 100px;
  }
  h3{
    margin-bottom: 0;
    opacity: .8;
    font-size: 18px;
    text-align: center;
    font-family: $font-family-sans-serif;
  }
  .testimonial-name {
    position: absolute;
    bottom: 1em;
    width: 95%;
    opacity: .7;
    letter-spacing: 1;
    hr{
      width: 95%;
    }
  }
  .vertical-cards-org{
    color: $es-dk-gray;
    opacity: .7;
    margin: 0 0 1em 0;
    font-weight: 300;
    text-align: center;
  }
}
/* Mockup for Card Styles */
.loader-style-dashboard{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loader-style-pane{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.page-questions {
  .card{
    height: 310px;
    min-height: 310px;
    h4{
      line-height: 1.5em;
      margin-top: 0;
      padding-top: 0;
      &:hover {
        text-decoration: none;
      }
    }
    .img-mask {
      height: 140px;
    }
    .title {
      padding-top: 0;
      margin-top: 0;
    }
  }
  #recommendations {
    margin: 0;
    a:hover {
      text-decoration: none;
    }
  }
}
//FROM DASHBOARD
.react-app-wrapper{
  //for static card rows
  .vert-card-wrapper{
    float: left;
    @media screen and (max-width: 1470px) {
      grid-template-columns: 19% 19% 19% 19% 19%;
      margin-right: 15px;
    }
    @media screen and (max-width: 1324px) {
      grid-template-columns: 24% 24% 24% 24%;
          margin-right: 0;
    }
    @media screen and (max-width: 960px) {
      grid-template-columns: 33.33% 33.33% 33.33%;
          margin-right: 0;
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: 50% 50%;
          margin-right: 0;
    }
    @media screen and (max-width: 470px) {
      grid-template-columns: 100%;
          margin-right: 0;
    }
    &:after{
      clear: both;
    }
  }
  .card {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    min-height: 335px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    height: 80%;
    margin-bottom: 0;
    .img-mask {
      height: 140px;
    }
    h4{
      font-size: 18px;
      line-height: 1.3em;
    }
    &:hover{
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 16px 4px rgba(0, 0, 0, 0.22);
      transform: translate(0,-6px);
      &:before{
        content: '';
        position: absolute;
        top: 100%;
        bottom: 100%;
        width: 100%;
        height: 20px;
      }
    }
    .overlay-image {
     display: flex;
     font-size: 1em;
     text-align: center;
     bottom: 0;
     height: 100%;
     left: 0;
     opacity: 0;
     position: absolute;
     right: 0;
     top: 0;
   }
   .description {
     margin-bottom: 1.8em;
     margin-left: 1.25em;
     padding-left: 0;
   }
   .overlay-text{
     align-self: center;
     color: #fff;
     font-size: 14px;
     line-height: 1.618;
     margin: 0 1em;
     text-align: left;
   }
   .meta{
     padding-left: 1em;
     position: absolute;
     bottom: 0;
     left: 0;
     .content-type{
       color: #545f64;
       background-color: transparent;
       border-left: 4px solid #545f64;
       &.new {
         border-left: 4px solid $es-green;
       }
       &.updated {
         border-left: 4px solid #f7931d;
       }
     }
   }
   .img-responsive {
     @include smooth-transition(.8s);
   }
  }
}

//DO WE NEED THIS?
.react-app-wrapper .description{
  line-height: 120%;
}
/* Favorites Cards*/
.favorites-card {
  a {
    margin-bottom: 40%;
  }
}
/* Subscriptions Cards*/
.full-image-expiration-card {
  position: relative;
  a {
    display: table;
    &:hover{
      .card{

      }
      .overlay-image{
        opacity: 1;
      }
      .title, .content-type{
        @include blur-content(20px);
        @include smooth-transition(.8s);
      }
    }
  }
  h4 {
    line-height: 1.4;
    padding-bottom: 1em;
    text-align: left;
  }
  hr{
    width: 50%;
  }
  .card {
    display: table;
    background-position: center;
    background-size: cover;
    padding: 0;
    position: relative;
    z-index: 2;
    margin-bottom: 10%;
    @supports (display: grid) {
      align-self: center;
      display: grid;
    }
    &:after {
      content: "";
      height: auto;
      position: absolute;
      z-index: -1;
      right: 0;
      left: 0;
      bottom: 0;
      background:rgba(0, 0, 0, 0.5);
    }
    a {
      display: table-cell;
      height: 310px;
      margin: 0 auto;
      vertical-align: middle;
      @supports (display: grid) {
        display: flex;
        justify-content: center;
      }
    }
    .meta > .content-type{
      color: #eee;
      border-left: 4px solid #eee;
    }
  }
  .img-responsive{
    display: block;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 400px;
    z-index: -1;
  }
  .description-date {
    border-top: 1px solid $white;
    color: $white;
    font-size: 80%;
    padding: 1em 0;
    text-align: left;
  }
  .title {
    text-align: center;
    margin: 0 auto;
    @supports (display: grid) {
      align-self: center;
    }
    h4 {
      color: $white;
      padding-bottom: 1em;
    }
  }
  //Hover Effects
  .overlay-image {
    background: rgba(0, 0, 0, 0.80);
    transition: .4s ease;
    width: 100%;
    @include smooth-transition(.8s);
  }
}

// Events Cards
.round-img-card  {
  a:hover{
    .overlay-image {
      opacity: 1;
    }
    .title, .description, .meta{
      @include blur-content(20px);
    }
  }
  .card {
    float: left;
    padding: 0;
    margin: 0 0 10% 0;
    position: relative;
    // width: 16%;
    @supports (display: grid) {
      display: flex;
      float: none;
      width: inherit;
    }
    &:hover{
      .favorite-icon-dark{
        color: $white;
        opacity: 0.8;
        &:hover{
          color: $es-dk-gray;
        }
      }
    }
    .description {
      font-size: 80%;
      margin: 0 7%;
      padding: 0;
    }
    .photo {
      object-fit: cover;
      width: 150%;
      height: 100%;
      @include smooth-transition(.8s);
    }
    .lg-img-mask{
      @include smooth-transition(.8s);
    }
  }
  .title {
    align-self: center;
    display: grid;
    height: 100px;
    margin: 0;
    h4 {
      align-self: center;
      color: $es-dk-gray;
      margin: 1em 0 .5em 0;
    }
  }
  .date {
    border-top: 1px solid #fff;
    z-index: 100;
  }
  //Hover effects
  .overlay-image{
    background: rgba(0, 0, 0, 0.75);
    @include smooth-transition(.8s);
    width: 100%;
  }
}

/* Tools Cards */
.dark-header-image-card {
  .card {
    background: linear-gradient(43deg, #3a3e41 40%, #5e6b70 100%);
    background-color: $es-dk-gray;
    float: left;
    margin: 0 10px 1em 0;
    padding: 0;
    position: relative;
    width: 20%;
    .meta > .content-type{
      color: #eee;
      border-left: 4px solid #eee;
    }
    @supports (display: grid) {
      width: auto;
    }
  }
  a{
    .img-responsive{
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      position: inherit;
      width: 100%;
    }
    &:hover{
      .overlay-image {
        opacity: 1;
      }
      .title h4 {
        color: transparent;
      }
      .meta{
        @include blur-content(20px);
      }
    }
  }
  .title{
    h4{
      color: $white;
      margin-top: 0;
    }
  }
  .description {
    padding: 0 1em 0 1.5em;
    font-size: 70%;
  }
  //Hover Effects
  .overlay-image{
    background: rgba(0, 0, 0, 0.90);
    transition: .8s ease;
    width: 100%;
  }
}

/* Ask E Cards */
.status-icon-card {
  margin-bottom: 35px;
  svg {
    font-size: 140%;
    margin-bottom: 3%;
  }
  a {
    margin-bottom: 15%;
    //Hover Effects
    &:hover{

      .overlay-image{
        opacity: 1;
        @include smooth-transition(.8s);
      }
      .circle-icon{
        background-color: transparent;
        transition: all .5s ease;
      }
      .title, .description, .content-type{
        @include blur-content(20px);
        @include smooth-transition(.8s);
      }
      svg {
        color: transparent;
        @include smooth-transition(.8s);
      }
    }
  }
  .card{
    float: left;
    position: relative;
    padding: 3em 1em 1em 1em;
    @supports (display: grid) {
      float: none;
      width: inherit;
    }
    .meta > .content-type{
      color: #eee;
      border-left: 4px solid #eee;
    }
  }
  .description {
    color: $white;
    font-size: 1em;
    margin-bottom: 15%;
    padding-top: 1em;
    text-align: center;
  }
  .background-style-blue{
    background-image:
      linear-gradient(
        rgba(84, 95, 100, 0.75),
        rgba(84, 95, 100, 0.75)
      ),
      url('/sites/all/themes/es_bootstrap/img/tunnel.jpg');
  }
  .background-style-orange{
    background-image:
      linear-gradient(
        rgba(84, 95, 100, 0.75),
        rgba(84, 95, 100, 0.75)
      ),
      url('/sites/all/themes/es_bootstrap/img/tunnel.jpg');

  }
  .background-style-green{
    background-image:
      linear-gradient(
        rgba(84, 95, 100, 0.75),
        rgba(84, 95, 100, 0.75)
      ),
      url('/sites/all/themes/es_bootstrap/img/tunnel.jpg');
  }
  .background-style-blue, .background-style-orange, .background-style-green {
    background-size: cover;
    background-position: right;
  }
  .title {
    h4{
      color: $white;
      overflow-wrap: break-word;
      text-align: center;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }
  .status-icon{
    margin-bottom: 15%;
  }
  .circle-icon{
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 18px;
    margin: auto;
  }
  //Hover Effects
  .overlay-image {
    background: rgba(0, 0, 0, 0.70);
    transition: .4s ease;
    width: 100%;
  }
  .overlay-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 100%;
  }
}
 .status-icon-card {
  height: 330px;
}
/* Reports and Studies Cards */
.header-image-card{
  h5 {
    position: absolute;
    bottom: 0;
    left: 6%;
    font-size: 60%;
  }
  .card {
    align-items: flex-start;
    padding: 0 0 0 0;
    position: relative;
    margin-bottom: 10%;
    img {
      object-fit: cover;
      height: 100%;
    }
    .img-mask{
      height: 135px;
    }
    &:hover {
      .favorite-icon-dark{
        color: $white;
        opacity: 0.8;
        &:hover{
          color: $es-dk-gray;
        }
      }
    }
  }
  .title{
    padding: 0 1em 0.25em 1em;
    h4 {
      margin-top: 0;
    }
  }
  .description{
    font-size: 80%;
    padding: 0 1em 0 1.5em;
  }
  .authors{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .date{
    padding: 0 1em 0 1em;
  }
  .date-description{
    margin-top: .5em;
    line-height: 110%;
  }
  //Hover Effects
  .overlay-image{
    background: rgba(0, 0, 0, 0.90);
    @include smooth-transition(.8s);
  }
  a:hover{
    color: #fff;
    .overlay-image {
      opacity: 1;
    }
    //Do we need this? > yes, to make the layer of text underneath the black overlay less prominant
    .title h4 {
      color: transparent;
    }
    .authors, .meta{
      @include blur-content(20px);
    }
  }
}

/* Bio Cards */
.header-bio-card {
  h5 {
    position: absolute;
    bottom: 0;
    left: 6%;
    font-size: 60%;
  }
  .card {
    align-items: flex-start;
    padding: 0 0 0 0;
    position: relative;
    margin-bottom: 10%;
    .img-mask {
      background-color: $es-dk-gray;
      padding-top: .5em;
    }
    img {
      border-radius: 125px;
      object-fit: cover;
      height: 125px;
      width: 125px;
    }
    &:hover {
      .favorite-icon-dark{
        color: $white;
        opacity: 0.8;
        &:hover{
          color: $es-dk-gray;
        }
      }
    }
  }
  .title {
    padding: 0 1em 0.25em 1em;
    h4 {
      margin-top: 0;
    }
  }
   .description{
     font-size: 80%;
     padding: 0 1em 0 1.5em;
      .date-description{
        margin-top: .5em;
        line-height: 110%;
      }
   }
   .date{
      padding: 0 1em 0 1em;
    }
  //Hover Effects
  .overlay-image{
    background: rgba(0, 0, 0, 0.90);
    @include smooth-transition(.8s);
  }
  a:hover{
    color: #fff;
    .overlay-image {
      opacity: 1;
    }
    //Do we need this? > yes, to make the layer of text underneath the black overlay less prominant
    .title h4 {
      color: transparent;
    }
    .authors, .meta{
      @include blur-content(20px);
    }
  }
}
/* Biography cards */
.header-bio-card {
  h5 {
    position: absolute;
    bottom: 0;
    left: 6%;
    font-size: 60%;
  }
  .card {
    align-items: flex-start;
    padding: 0 0 0 0;
    position: relative;
    margin-bottom: 10%;
    .img-mask {
      background-color: $es-dk-gray;
      padding-top: .5em;
    }
    img {
      border-radius: 125px;
      object-fit: cover;
      height: 125px;
      width: 125px;
    }
    &:hover {
      .favorite-icon-dark{
        color: $white;
        opacity: 0.8;
        &:hover{
          color: $es-dk-gray;
        }
      }
    }
  }
  .title {
    padding: 0 1em 0.25em 1em;
    h4 {
      margin-top: 0;
    }
  }
  .authors {
    font-size: 80%;
  }
  .description {
    padding: 0 1em 0 1.5em;
  }
  .date {
    padding: 0 1em 0 1em;
  }
  .date-description{
    margin-top: .5em;
    line-height: 110%;
  }
  //Hover Effects
  .overlay-image{
    background: rgba(0, 0, 0, 0.90);
    @include smooth-transition(.8s);
  }
  a:hover{
    color: #fff;
    .overlay-image {
      opacity: 1;
    }
    //Do we need this? > yes, to make the layer of text underneath the black overlay less prominant
    .title h4 {
      color: transparent;
    }
    .authors, .meta{
      @include blur-content(20px);
    }
  }
}

/* Biography cards */
.header-bio-card {
  h5 {
    position: absolute;
    bottom: 0;
    left: 6%;
    font-size: 60%;
  }
  .card {
    align-items: flex-start;
    padding: 0 0 0 0;
    position: relative;
    margin-bottom: 10%;
    .img-mask {
      background-color: $es-dk-gray;
      padding-top: .5em;
    }
    img {
      border-radius: 125px;
      object-fit: cover;
      height: 125px;
      width: 125px;
    }
    &:hover {
      .favorite-icon-dark{
        color: $white;
        opacity: 0.8;
        &:hover{
          color: $es-dk-gray;
        }
      }
    }
  }
  .title {
    padding: 0 1em 0.25em 1em;
    h4 {
      margin-top: 0;
    }
  }
  .authors {
    font-size: 80%;
  }
  .description {
    padding: 0 1em 0 1.5em;
  }
  .date {
    padding: 0 1em 0 1em;
  }
  .date-description{
    margin-top: .5em;
    line-height: 110%;
  }
  //Hover Effects
  .overlay-image{
    background: rgba(0, 0, 0, 0.90);
    @include smooth-transition(.8s);
  }
  a:hover{
    color: #fff;
    .overlay-image {
      opacity: 1;
    }
    //Do we need this? > yes, to make the layer of text underneath the black overlay less prominant
    .title h4 {
      color: transparent;
    }
    .authors, .meta{
      @include blur-content(20px);
    }
  }
}

/* Horizontal Bio Cards */
.horz-bio-card{
  background-color: #f2f6f8;
  border-color: #dae9ec;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: .5em;
  h3{
    font-weight: 600;
  }
  p{
    margin: 1em 0 1em 0;
    color: #545f64;
    font-family: sans-serif;
  }
  img{
    object-fit: cover;
    width: 150%;
    height: 100%;
  }
  .full-margin-bottom{
    margin: 1em 0 1em 0;
  }
  .fa{
    margin-right: .5em;
  }
  .circle-img-mask{
    border-radius: 200px;
    height: 110px;
    margin: 1em 0 0 3.5em;
    overflow: hidden;
    width: 110px;
  }
  .btn-info{
    margin: 0 2em 1em 0;
  }
}
//remove width for cards when they're in a slick slider
.slick-slider {
  .dark-header-image-card, .status-icon-card, .header-bio-card, .header-bio-card, .round-img-card {
    .card {
      float: none;
      width: auto;
    }
  }
}
// END Dashboard


//used on the consulting page --- RENAME
.horizontal-cards {
  background-color: #fff;
  padding: 1em;
  margin: 0;
  .horizontal-cards-photo {
    border-radius: 100px;
    height: 100px;
    margin: 0 auto;
    overflow: hidden;
    width: 100px;
  }
  .horizontal-cards-img-left {
    padding: 0 1em 0 0;
  }
  .horizontal-cards-img-right {
    padding: 0 0 0 1em;
  }
  .testimonial-quote {
    font-size: 25px;
    line-height: 38px;
    margin-top: 1em;
    padding-left: 1.5em;
    text-indent: -1.9em;
  }
  hr {
    width: 60%;
  }

}
svg.gray-circle-icon {
  width: 100px;
  height: 100px;
}
.horizontal-cards-img-left, .horizontal-cards-img-right {
  overflow: hidden;
  img{
    display: block;
    width: 70vw;
    height: 70vh;
    max-height: 500px;
    object-fit: cover;
  }
}
.header-content-list-card{
  padding: 0;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(50,50,50,0.1);
  border: 1px solid $es-lt-gray;
  margin: .75em;
  position: relative;
  width: calc(100% - 2.5em);
  @media(max-width: 1200px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .card-header{
    margin-bottom: 15px;
    h4{
      font-weight: 500;
      margin: 0 auto;
      padding: 25px;
      font-size: 23px;
      line-height: 1.3;
      min-height: 130px;
      display: flex;
      align-items: flex-end;
    }
  }
  .card-body{
    padding: 10px;
    ul.green-square-bullets{
      margin-left: 10px;
      li{
        color: #545f64;
        &:before{
          width: .7em;
          font-size: 17px;
        }
      }
    }
  }
}
.gray-border-card {
  background-color: #f1f1f1;
  border: 1px solid $es-lt-gray;
  display: flex;
  margin: .75em;
  padding: .5em;
  position: relative;
  width: calc(100% - 2.5em);
  @media(max-width: 1200px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  hr {
    margin: .5em 0;
  }

  .btn {
    position: absolute;
    bottom: 1em;
    left: 40%;
  }
  a {
    display: block;
    color: #545f64;
    height: 450px;
    left: 0;
    padding: .5em;
    position: absolute;
    width: 100%;

    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: $es-green;
      color: #fff;
      text-decoration: none;

      h3, h4, p{
        color: #fff;
      }
      .btn-success {
        background-color: #fff;
        color: $es-green;
      }
    }
  }
  .expertise {
    font-size: 14px;
    line-height: 1.4em;

    .img-mask{
      height: 270px;
      display: block;
      position: relative;
      overflow: hidden;

      img{
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%;
        max-width: none;
      }
      @media (max-width: 992px) {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    @media (max-width: 991px) {
      .img-mask {
        height: 170px;
      }
    }
    .title{
      // Truncation in Chrome, esClamp.js for FF
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .field-abstract{
      // Truncation in Chrome, esClamp.js for FF
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}
.bio-cards {
  position: relative;
  margin: .5em;
  background-color: #f1f1f1;
  border: 1px solid #c6c6c5;
  width: calc(33.3% - 1em);
  height: 450px;
  a {
    display: block;
    color: #545f64;
    height: 450px;
    left: 0;
    padding: 1em;
    position: absolute;
    width: 100%;
    &:hover {
      background-color: #79ac42;
      color: #fff;
      text-decoration: none;
      h3 {
        color: #fff;
      }
      .btn {
        background-color: #fff;
        color: $es-blue;
      }
    }
  }
}
@media (max-width: $screen-sm) {
  .bio-cards {
    width: calc(33.3% - 1em);
  }
}
@media (max-width: $screen-md) {
  .bio-cards {
    width: calc(100% - 1em);
    height: 375px;
  }
}
@media (max-width: 1024px) {
  .bio-cards {
    height: 450px;
  }
}
// Expand / collapse bio cards
.card-wrapper-bios{
  display: flex;
  flex-wrap: wrap;
  .bio-card-container{
    margin: 30px auto;
    max-width: 400px;
    padding: 0 10px;
    &.green-border{
      img{
        border: 1px solid $es-green;
      }
      .bio-card > .content{
        border-top: 6px solid $es-green;
      }
      .bio-card > .content.bg-white{
        h2, h3, p{
          color: #545f64;
        }
        a{
          color: $es-blue;
        }
      }
    }
    &.teal-border{
      .bio-card > .content{
      border-top: 6px solid $es-lt-blue;
      }
      img{
        border: 1px solid $es-lt-blue;
      }
      .name:after{
        background-color: $es-lt-blue;
      }
    }
    .bio-card{
      .img-frame{
        left: 50%;
        margin-top: 120px;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%);
        img{
          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
          height: 225px;
          width: 225px;
          max-width: inherit;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 1;
          @supports(object-fit: cover){
            display: inline;
            object-fit: cover;
            object-position: 50% 20%;
          }
          @media(max-width: 767px){
            height: 200px;
            width: 200px;
          }
        }
      }
      .content{
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        margin-top: 170px;
        padding: 75px 20px 0 20px;
        height: 320px;
        a{
          cursor: pointer;
        }
        .name{
          &:after{
            margin: 20px auto 0 auto;
            width: 40px;
            height: 2px;
          }
        }
        .title{
          font-size: 1.7rem;
          line-height: 1.4;
        }
        .bio-description{
          opacity: 0;
          p{
            font-size: 16px;
            line-height: 1.4;
          }
        }
        .close-bio, .linked-in-profile{
          bottom: 0;
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
        .close-bio, .open-bio{
          font-size: 17px;
        }
      }
      &.expand-description{
        .img-frame, .content{
          transition: all .8s ease;
        }
      }
    }
    &.expand{
      .img-frame, .name, .title, a.open-bio{
        bottom: 0;
        position: absolute;
        opacity: 0;
        z-index: -1;
      }
      .content{
        height: 490px;
        margin: 0;
        padding-top: 20px;
        .bio-description, .close-bio, .linked-in-profile{
          opacity: 1;
          z-index: 1;
        }
        .close-bio{
          bottom: 0;
          position: absolute;
          left: 0;
          margin-bottom: 10px;
          margin-left: 30px;
        }
        .linked-in-profile{
          bottom: 0;
          font-size: 30px;
          right: 0;
          margin-right: 30px;
          margin-bottom: 10px;
          position: absolute;
        }
      }
    }
  }
}

#our-experts{
  .green-border > .bio-card{
    @media(max-width: 767px){
      .img-frame{
        margin-top: -35px;
      }
    }
  }
}
// Flip Card
.flipped {
  -webkit-transform: rotateY(-180deg);
}
.flip {
  width: 100%;
  height: 475px;
  -webkit-perspective: 800;

  margin-bottom: 1.5em;

  .flipped {
    -webkit-transform: rotateY(-180deg);
  }
  .flip-card{
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
    -webkit-transition: 0.5s;
    .face{
      width: 100%;
      height: 100%;
      position: absolute;
      -webkit-backface-visibility: hidden;
      z-index: 2;
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
      -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
      border: 1px solid #dadada;
      position: relative;
      margin: .5em;
      background-color: #f1f1f1;
      width: 100%;
      height: 100%;
    }
    .face-white{
      background-color: #fff;
    }
    .flip-card-front {
      position: absolute;
      z-index: 1;
      cursor: pointer;
      h3{
        font-size: 2em;
        margin-top: 1em;
        margin-bottom: 5px;
      }
      .title{
        // font-size: 1em;
        font-size: .9em;
        padding: 0 1em;
        line-height: 1.5;
      }
      .title-advisory{
        font-size: .75em;
        padding: 0 1em;
        line-height: 1.5;
      }
      .photo-blue, .photo-green, .photo-lightblue{
        padding: 1em;
        background-color: #4a77bb;
        height: 335px;

        display: block;
        position: relative;
        img{
          margin: 2em auto 0 auto;
          height: 315px;
          max-width: 250px;
          width: 100%;

          // width: auto;
          height: 100%;

          overflow: hidden;
          border-radius: 2px;
          box-sizing: border-box;
          box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
          -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
          -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
        }
      }
      .photo-green{
        background-color: #79ac42;
      }
      .photo-lightblue{
        background-color: #6c9cb2;
      }
    }
    .flip-card-front:hover {
      transition: all .4s ease-out;
      -webkit-transition: all .4s ease-out;
      -moz-transition: all .4s ease-out;
      -o-transition: all .4s ease-out;
      -ms-transition: all .4s ease-out;
    }
    .flip-card-back{
      cursor: default;
      -webkit-transform: rotateY(-180deg);
      padding: 0 1em;
      margin-left: -8px;
      background-color: #f1f1f1;
      a:hover{
        text-decoration: none;
      }
      h3{
        color: #4a77bb;
        &:hover{
          cursor:pointer;
        }
      }
      .back-bio{
        line-height: 1.3;
        padding: 1em 0;
        text-align: center;
        font-size: 16px;
        margin-bottom: 0;
        position: relative;
      	top: 50%;
      	transform: translateY(-50%);
      }
      .external-icons{
        a{
          color: #4a77bb;
          position: absolute;
          right: 0;
          bottom: 0;
          &:hover{
            filter: opacity(.7);
          }
        }
      }
    }
    .flip-card-back-white{
      @extend .flip-card-back;
      background-color: #fff;
    }
  }
}


@media (max-width: 667px){
  .flip > .flip-card{
    .flip-card-back{
      .back-bio{
        line-height: 2;
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 375px){
  .flip > .flip-card {
    .flip-card-front{
      .photo-blue,.photo-green,.photo-lightblue {
        img{
          margin: 1.5em auto 0 auto;
          height: 315px;
          max-width: 250px;
          width: 100%;
          overflow: hidden;
        }
      }
      h3{
        font-size: 24px;
        padding-top: .5em;

        padding-top: 1em;
      }
      // .title{
      //   font-size: .9em;
      // }
    }
    .flip-card-back{
      padding: 0 .5em;
      .back-bio{
        line-height: 1.5;
        font-size: 15px;
      }
    }
  }
}


// Card Chips (used on horizontal cards; /news page)
.card-chip{
  bottom: 1.5em;
  color: #545f64;
  border-left: 4px solid #545f64;
  font-size: 15px;
  margin: 0 1.5em 0 0;
  padding: 1px 4.5px;

  @media (max-width: 768px) {
    position: relative;
    margin: 1em 1em 0 0;
    float: right;
    bottom: auto;
  }
}
