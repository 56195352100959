// NEW SEARCH - Web Revamp 2017
.search-container{
  margin: 1em 3em;
  &.container-fluid {
    margin: 0 -15px;
  }
  
  //Search Bar
  .search-bar-container{
    margin:0;
    .search-bar-container{
      margin: 45px 0 0 0;
      padding: 2em;
      .search-bar{
        position: relative;
        margin: 10px 0 0 0;
      }
      input{
        font-size: 18px;
        padding: 20px 10px 10px 5px;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid $es-gray-06;
        border-radius: 0;
      }
      .form-checkbox {
        width: inherit;
      }
      .form-control{
        box-shadow: none;
        -webkit-box-shadow:  none;
      }
      input:focus {
        outline: none;
      }
      label {
        font-family: $font-family-sans-serif;
        color: $es-gray-06;
        font-size: 25px;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.25s ease all;
        font-weight: 400;
      }
      /* active state */
      input:focus ~ label, input:not(:placeholder-shown) ~ label    {
        top: -20px;
        color: $es-dk-gray;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-weight: bold;
      }
      .bar {
        position:relative;
        display:block;
        width:100%;
      }
      .bar:before, .bar:after   {
        content:'';
        height:1px;
        width:0;
        bottom:1px;
        position:absolute;
        background:#545f64;
        transition:0.2s ease all;
        -moz-transition:0.2s ease all;
        -webkit-transition:0.2s ease all;
      }
      .bar:before {
        left:50%;
      }
      .bar:after {
        right:50%;
      }
      /* active state */
      input:focus ~ .bar:before, input:focus ~ .bar:after {
        width:50%;
      }
      .highlight {
        position:absolute;
        height:60%;
        width:100%;
        top:25%;
        left:0;
        pointer-events:none;
        opacity:0.5;
      }
      /* active state */
      input:focus ~ .highlight {
        -webkit-animation:inputHighlighter 0.5s ease;
        -moz-animation:inputHighlighter 0.5s ease;
        animation:inputHighlighter 0.5s ease;
      }
    }
    .search-checkbox{
      margin: 0 0 0 0;
      padding: 10px 0;
      float: left;
      input{
        margin: 10px 0 0 0;
        padding: 0;
      }
      span{
        margin: 0 0 0 1.5em;
        padding: 0;
        font-size: 16px;
      }
      @media only screen and (max-width: 420px) {
        span{
          font-size: 11px;
        }
      }
    }
    @media only screen and (max-width: 750px) {
      div:first-of-type{
        margin-bottom: 1em;
      }
      .search-checkbox > span{
        font-size: 11px;
      }
    }

    //Tabs
    .desktop-tab-wrapper{

      .desktop-tab-container {
        background-color: $es-lt-gray;
        width: 100%;
        margin: 2em auto 0 auto;
        padding: 2em 0 0 0;
        line-height: .5em;
        -webkit-font-smoothing: antialiased;
        .tabs {
          display: table;
          margin: 0;
          padding: 0;
          width: 100%;
          li {
            display: inline-block;
            line-height: 45px;
            padding: 0;
            margin: 0 5px;
            min-width: 60px;
          }
          a {
            background-color: rgba(0, 0, 0, 0.4);
            color: #fff;
            display: block;
            width: 200px;
            letter-spacing: 0;
            outline: none;
            padding: 0 20px;
            text-decoration: none;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            @media (max-width: 640px){
              width: 130px;
            }
          }
        }
        ul > li {
          transition: $float;
          &:hover {
            box-shadow: 0px -10px 18px -5px rgba(0,0,0,0.25);
          }
          
        }
        ul > li > a.active-tab {
          background-color: #fff;
          border-top: 6px solid $es-blue;
          color: $es-dk-gray;
        }
        ul > li > a:hover{
          background-color: rgba(0, 0, 0, 0.7);
        }
        ul > li > a.active:focus {
          // Default
          outline: thin;
          // WebKit
          outline: 1px solid $es-blue;
          outline-offset: 2px;
        }
        ul > li > a.active-tab:hover {
          background-color: #fff;
          color: $es-dk-gray;
        }
      }
    }
    // displaying none on desktop tabs for mobile
    @media only screen and (max-width: 420px) {
      .desktop-tab-wrapper{
        display: none;
      }
    }
    // MOBILE Tabs
    @media only screen and (max-width: 420px) {
      .mobile-tab-container{
      	margin: 4em 0 0 0;
      	height: auto;
      	width: 100%;
      	padding: 0;
        box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
        .hide {
          display: none;
        }
        .mobile-tab-content {
          padding: 25px;
        }
        #mobile-tabs {
          position: relative;
          display: block;
          padding: 0;
          border-bottom: 1px solid #dadada;
          clear: both;
        }
        #mobile-tabs > a {
          position: relative;
          // width: 50px;
          display: inline-block;
          margin: 0 5px;
          text-decoration: none;
          padding: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #545f64;
          text-align: center;
        }
        #mobile-tabs > a.active-tab {
          font-weight: 700;
          color: #4a77bb;
        }
        #mobile-tabs > a:hover{
          color: #4a77bb;
        }
        .highlight-bar {
          position: absolute;
          z-index: 10;
          bottom: 0;
          height: 3px;
          background: #4a77bb;
          display: block;
          left: 0;
          transition: left .2s ease;
          -webkit-transition: left .2s ease;
        }
        .tabs:nth-of-type(1).active-tab ~ span.highlight-bar {
          left: 0;
          width: 70px;
        }
        .tabs:nth-of-type(2).active-tab ~ span.highlight-bar {
          left: 80px;
          width: 80px;
        }
        .tabs:nth-of-type(3).active-tab ~ span.highlight-bar {
          left: 178px;
          width: 70px;
        }
      }
    }
    @media only screen and (min-width: 421px) {
      .mobile-tab-container{
        display: none;
      }
    }
  }

  //Filters / Side Nav
  .filters-container{
    margin: 1em 0 5em 0;
    padding: 0;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    border: 1px solid #dadada;

    // Filter CATEGORIES
    div > ul > a {
      text-decoration: none;
    }
    div > ul > ul > a {
      text-decoration: none;
    }
    div > ul > ul > ul > a{
      text-decoration: none;
    }
    ul{
      margin-left: 1em;
      margin-right: 1em;
      padding: 0 .5em 0 0;
    }
    li {
      font-size: 14px;
      font-weight: 400;
      list-style-type: none !important;
    }
    li:hover {
      background-color: #c6c6c5;
      width: auto;
      cursor: pointer;
      text-decoration: none;
    }
    .filter-headings{
      border-top: 1px solid #888;
      color: #545f64;
      background-color: #f1f1f1;
      margin:0;
      padding:0;
      padding-left: .75em;
      font-weight: 300;
      font-weight: 400;
      line-height: 55px;
      cursor: pointer;
      transition: all 400ms ease-in-out 0s;
        -moz-transition: all 400ms ease-in-out 0s;
        -webkit-transition: all 400ms ease-in-out 0s;
        -o-transition: all 400ms ease-in-out 0s;
      i{
        float: right;
        line-height: 55px;
        margin-right: 1em;
      }
    }
    .filter-headings:hover{
      background-color: darken(#f1f1f1, 10%);
    }
    .selected-topics-filter-item::before{
      font-family: FontAwesome;
      content: "\f053";
      margin-right: 5px;
    }
    .top-level-filters{
      padding: 5px 0 5px .5em;
      .service-topic-filter-item, h5{
        font-size: 16px;
        color: $es-blue;
        margin: 15px;
      }
      .service-topic-filter-item:hover{
        color: $es-dk-blue;
      }
      li{
        margin-left: .5em;
        display: inline;
        margin: 0;
        padding: 0;
        height: 0;
      }
    }
    .parent-filters{
      .selected-topics-filter-item, h5{
        font-size: 14px;
        font-weight: 400;
      }
      .selected-topics-filter-item:hover{
        color: $es-dk-blue;
      }
      .topics-filter-item:hover{
        color: $es-dk-blue;
      }
      li{
        margin-left: .5em;
        display: inline;
        margin: 0;
        padding: 0;
        height: 0;
      }
    }
    .child-filters{
      .topics-filter-item, h5 {
        font-size: 13px;
        font-weight: 400;
        margin-left: 1em;
      }
      .topics-filter-item:hover{
        color: #52752d;
      }
      li{
        text-decoration: none;
        margin-left: .5em;
        display: inline;
        margin: 0;
        padding: 0;
        height: 0;
      }
    }
    // Filters AUTHORS
    .author-filters{
      padding: 5px 0 5px .5em;
      .author-filter-item{
        font-size: 16px;
        color: $es-blue;
        margin: 15px;
      }
      .author-filter-item:hover{
        color: $es-dk-blue;
      }
      li{
        text-decoration: none;
        margin-left: .5em;
        display: inline;
        margin: 0;
        padding: 0;
        height: 0;
      }
    }
    // Filters DATES
    .date-filters{
      padding: 5px 0 5px .5em;
      .date-filter-item{
        font-size: 16px;
        color: $es-blue;
        margin: 15px;
      }
      .date-filter-item:hover{
        color: $es-dk-blue;
      }
      li{
        text-decoration: none;
        margin-left: .5em;
        display: inline;
        margin: 0;
        padding: 0;
        height: 0;
      }
    }
  }
  @media only screen and (max-width: 750px) {
    .filters-container{
      margin-bottom: 1em;
    }
  }

  //Card Container, results counter, breadcrumbs, sort, cards, list view, pagination, feedback form
  .list{
    border-bottom: solid 1px #eee;
    padding: 10px 30px;
    margin-bottom: 10px;
    i.tooltip-wrapper{
      color: #fff;
      background-color: $es-orange;
      border-radius: 40px;
      opacity: .90;
      font-size: 1.25em;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      margin-top: 10px;
      &:hover > .tooltip-content.left{
        visibility: visible;
      }
      .tooltip-content.left{
        background-color: #000;
        border-radius: 6px;
        color: #fff;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 15px;
        left: -170px;
        line-height: 20px;
        max-width: 200px;
        padding: 10px;
        position: absolute;
        text-align: center;
        top: -15px;
        visibility: hidden;
        z-index: 1;
        &::after{
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent #555;
          content: "";
          left: 100%;
          margin-top: -5px;
          position: absolute;
          top: 50%;
        }
      }
      @media screen and (max-width: 768px){
        .tooltip-content.left{
          left: 80px;
          &::after{
            right: 100%;
            border-color: transparent #555 transparent transparent;
            left: auto;
          }
        }
      }
    }
    .title{
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      h4 {
        margin-bottom: 0;
        width: 100%;
        a {
          font-family: $font-family-sans-serif;
          font-size: 23px;
          font-weight: 600;
          line-height: 1.4;
        }
      }
    }
    .subtitle{
      font-size: 17px;
    }
    .description{
      font-size: 16px;
    }
    .search-snippet{
      color: #777;
      font-size: 15px;
      margin-bottom: 0px;
      strong{
        background: #fff2cc;
      }
    }
    .meta{
      text-align: right;
      .content-type{
        display: block;
        text-align: left;
        color: $es-dk-gray;
      }
    }
  }
  .card-container{
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0;
    margin-top: 1em;

    //Footer height adjustment margin
    // margin-bottom: 700px;

    //Results
    .results-counter{
      font-weight: 400;
      margin: 0;
      padding: 0;
      font-size: 15px;
      &+ hr{
        border-top: 1px solid #79ac42;
      }
      .first-crumb, .middle-crumb{
        color: $es-blue;
      }
      .first-crumb:hover, .middle-crumb:hover{
        color: $es-dk-blue;
      }
      //Breadcrumbs
      a:hover{
        text-decoration: none;
      }
      .first-crumb, .middle-crumb, .last-crumb{
        font-weight: 400;
        font-family: Helvetica;
      }
      .single-crumb {
        color: #79ac42;
        // font-weight: 600;
        // font-size: 16px;
      }
      .first-crumb {
        // font-weight: 600;
        // font-size: 16px;
      }
      .middle-crumb {
        // font-weight: 400;
        // font-size: 14px;
      }
      .last-crumb {
        // font-weight: 400;
        // font-size: 14px;
      }
      .middle-crumb::before, .last-crumb::before{
        font-family: FontAwesome;
        content: "\f054";
        margin: 0 5px;
        color: #545f64;
      }
      .keyword{
        font-family: Helvetica;
        font-weight: bold;
        color: $es-blue !important;
      }
      .keyword:hover{
        color: $es-dk-blue !important;
        text-decoration: none;
      }
    }
    .chips{
      li{
        font-size: 15px;
      }
      i{
        margin-left: .25em;
        margin-top: 2px;
        font-size: 16px;
      }
    }
    //Show more results
    .show-more-results{
      margin: 0;
      padding: 0;
      float: right;
      line-height: 30px;
      font-size: 15px;
      a{
        padding:0 .25em;
      }
      a:hover{
        cursor:pointer;
      }
      .result-per-page-toggle.active{
        border-bottom: 2px solid #79ac42;
        color: #4a77bb;
        font-weight: bold;
      }
    }
    //Sort buttons
    .settings-container{
      margin-bottom: 15px;
      .sort-container{
        line-height: 30px;
        font-size: 15px;
        div{
          margin-right: .5em;
          a{
            border-radius: 0 !important;
          }
          a:first-child{
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
          }
          a:last-child{
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
          }
          .btn.btn-secondary{
            background-color: #f1f1f1;
            color: #545f64;
            border-color: #dadada;
          }
          .btn-secondary:hover{
            background-color: darken(#f1f1f1, 10%);
            border-color: #dadada;
          }
        }
      }
    }
    // Card
    .card {
      font-family: Helvetica;
      a {
        text-decoration: none;
      }
      position:relative;
      min-height: 440px;
      height: 100%;
      width: 100%;
      cursor: pointer;
      margin: 0 0 2em 0;
      background: #ffffff;
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
        -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
      border: 1px solid #dadada;
      overflow:hidden;
      transition: all .4s ease-out;
      -webkit-transition: all .4s ease-out; /* Safari and Chrome */
      -moz-transition: all .4s ease-out; /* Firefox */
      -o-transition: all .4s ease-out; /* IE 9 */
      -ms-transition: all .4s ease-out; /* Opera */

      .photo {
        width: 100%;
        display: block;
      }
      .img-mask {
        height: 175px;
        overflow: hidden;
        margin-bottom: .75em;

      }

      i.fa-lock.tooltip-wrapper{
        border-radius: 40px;
        background-color: $es-orange;
        color: #fff;
        font-size: 2.5em;
        height: 50px;
        line-height: 50px;
        margin-right: 0;
        opacity: .90;
        position: absolute;
        right: 20px;
        top: 175px;
        transform: translateY(-50%);
        text-align: center;
        width: 50px;
        &:hover > .tooltip-content.top{
          visibility: visible;
        }
        .tooltip-content.top{
          background-color: #000;
          border-radius: 6px;
          color: #fff;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 15px;
          right: 60px;
          line-height: 20px;
          width: 200px;
          padding: 10px;
          position: absolute;
          text-align: center;
          top: -15px;
          visibility: hidden;
          z-index: 1;
          &::after{
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent #555;
            content: "";
            left: 100%;
            margin-top: -5px;
            position: absolute;
            top: 50%;
          }
        }
      }
      .overlay{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(198, 198, 197, 0.25);
        z-index: 10;
      }
      @media only screen and (min-width: 1700px){
        .img-mask{
          height: 185px;
        }
        i.fa-lock{
          top: 185px;
        }
      }
      @media only screen and (max-width: 1367px) {
        .img-mask{
          height: 150px;
        }
        i.fa-lock{
          top: 150px;
        }
      }
      @media only screen and (max-width: 668px) {
        .img-mask{
          height: 180px;
        }
        i.fa-lock{
          top: 180px;
        }
      }
      @media only screen and (max-width: 420px) {
        .img-mask{
          height: 150px;
        }
        i.fa-lock{
          top: 150px;
        }
      }


      .subtitle, .description, .author {
        padding: 0 1.25em;
      }
      .title {
        color: #545f64;
        margin: .5em 0 0 0;
        padding: 0 1em;
        max-width: 100%;
        h4{
          margin: 1em 0 0 0;
          font-size: 16px;
          font-family: $font-family-sans-serif;
          font-weight: 600;
          line-height: 1.3;
          width: 100%;
          // Truncation in Chrome, esClamp.js for FF
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
      .subtitle{
        font-size: 12px;
        font-weight: 400;
        margin: .5em 0 0 0;
        opacity: .5;
        color: #545f64;
        max-width: 100%;
        h5 {
          margin: 0;
          font-family: Helvetica;
          line-height: 1.4;
          // Truncation in Chrome, esClamp.js for FF
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
      .author{
        font-size: 12px;
        margin: 0;
        max-width: 100%;
        p{
          line-height: normal;
          font-family: Helvetica, Arial, sans-serif;
          margin: 0;
          padding: .5em 0 0 0;
          color: #545f64;
          // Truncation in Chrome, esClamp.js for FF
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
      .description {
        font-size: 12px;
        margin: .5em 0;
        color: #888;
        max-width: 100%;
        p {
          font-family: Helvetica;
          line-height: 1.5;
          margin-top: 0;
          // Truncation in Chrome, esClamp.js for FF
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
        p:last-of-type {
          margin-bottom: 0;
        }
      }
      .meta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-left: 0.75em;
        .timestamp {
          font-size: 10px;
          color: #888;
          line-height: 18px;
          display: block;
          margin: 1em;
          padding: 2px 0;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
    .card:hover{
      box-shadow: 0px 35px 62px -7px rgba(0,0,0,0.44);
        -moz-box-shadow: 0px 35px 62px -7px rgba(0,0,0,0.44);
        -webkit-box-shadow: 0px 35px 62px -7px rgba(0,0,0,0.44);
    }
    // Search Feedback
    .search-feedback{
      font-weight: 400;
      padding-bottom: 2em;

      .grippie{
        display: none;
      }
      h3{
        font-weight: 400;
      }
      hr{
        width: 50%;
      }
      a{
        cursor: pointer;
      }
      .raised-btn{
        text-align: center;
        margin: .5em 0 1em 0;
        width: 90px;
        height: 35px;
        font-size: 14px;
        background-color: $es-blue;
        color: #ffffff;
        transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0.2, 1);
      }
      .raised-btn:hover {
        background-color: $es-dk-blue;
      }
      .feedback-form-actions-wrapper{
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        .control-label{
          float: left;
        }
        #edit-feedback-submit{
          float: left;
          margin-bottom: 1em;
        }
        #edit-feedback-cancel{
          float: right;
          margin-bottom: 1em
        }
        #edit-feedback-comments{
          margin-left: auto;
          margin-right: auto;
          border: 1px solid #c6c6c6;
        }
      }
      p{
        clear: both;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    .show-more-results{
      display: none;
    }
  }
}
