.grid-col {
  position: relative;
  p:last-child{
    margin-bottom: 2em;
  }
  .btn {
    position: absolute;
    bottom: .75em;
    right: .75em;
  }
}
.grid-3-wrapper {
  .grid-3 {
    float: left;
    height: 350px;
    margin: 0 .5em;
    width: calc(33.3% - 1em);
    @media screen and (max-width: 960px) {
      margin: .5em 0;
    }
  }
}
@supports(display: grid ) {
  .grid-3-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr;
    }
    .grid-3 {
      float: none;
      height: auto;
      width: inherit;
      @media screen and (max-width: 960px) {
        margin-bottom: 1em;

      }
    }
  }
}
